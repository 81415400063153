import { Box } from "@mui/material";
import { useAgency } from "entities/admin";
import { useTheme } from "@mui/material/styles";
import { useLocale } from "entities/i18n";
import { useEffect, useMemo, useState } from "react";
import { AutocompleteSelectInput, DateInput, TextInput } from "shared/ui/form";
import { Picker } from "shared/ui/components";
import { ADS_SYSTEMS_READABLE, outlinedInputSx } from "shared/constants";
import { IconSearch } from "@tabler/icons-react";
import { useParams, useSearchParams } from "react-router-dom";
/* eslint-disable indent */

import {
  usePlanFactMediaplanAdsFilterQuery,
  usePlanFactMediaplanCampaignsFilterQuery,
} from "entities/mediaplan";
import { formOptionsList } from "./libs";
import { useFilterURLState } from "./libs";

export type FiltersState = {
  cabinet: string;
  mediaplan: string;
  campaign: string;
  brand: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  lastDay: boolean;
};

export type CampFiltersState = {
  adGroup: string;
  ad: string;
  startDate: Date | null;
  endDate: Date | null;
  lastDay: boolean;
  geography: string;
  demographic: string;
};

export const initialFiltersState: FiltersState = {
  cabinet: "",
  mediaplan: "",
  campaign: "",
  brand: "",
  status: "",
  startDate: (() => {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  })(),
  endDate: new Date(),
  lastDay: false,
};

export const initialCampFiltersState: CampFiltersState = {
  adGroup: "",
  ad: "",
  startDate: (() => {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  })(),
  endDate: new Date(),
  lastDay: false,
  geography: "",
  demographic: "",
};

const adsSystemsOptions = ["Все", ...Object.values(ADS_SYSTEMS_READABLE)];

export const DashboardFilters = ({
  onChange,
  userRole,
}: {
  onChange?: (value: FiltersState) => void;
  userRole: "admin" | "director";
}) => {
  const { shadows, palette } = useTheme();
  const { dashboard: i18n } = useLocale();
  const params = useParams();
  const agency = useAgency();
  const clientId = params["client"];

  // const [filters, setFilters] = useState<FiltersState>(initialFiltersState);
  const [filters, setFilters] = useFilterURLState();
  const handleAllFiltersChange = (value: FiltersState) => setFilters(value);

  const handleFilterChange = (
    key: keyof FiltersState,
    value: FiltersState[typeof key],
  ) => setFilters((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    onChange && onChange(filters);
    console.log(
      ["Все", ...Object.values(ADS_SYSTEMS_READABLE)].indexOf(filters.cabinet),
    );
  }, [filters, onChange]);

  const filterRes = usePlanFactMediaplanCampaignsFilterQuery(
    {
      agency_id: userRole === "admin"
        ? Number(params["agency"])
        : agency.agencyId || -1,
      client_id: Number(clientId),
    },
    { skip: userRole !== "admin" && !agency.agencyId },
  );

  const filterOptions = useMemo(() => {
    if (!filterRes.data || !filterRes.data?.result) {
      return {
        brand: [],
        status: [],
        ad_cabinet: [],
        mediaplan: [],
        campaign: [],
      };
    }

    return { ...filterRes.data.result };
  }, [filterRes.data]);

  return (
    <Box
      className="w-full rounded-lg h-24 bg-white mt-2 mb-6 pt-4 px-4"
      boxShadow={shadows["3"]}
    >
      <div className="flex flex-row justify-center gap-4 items-stretch">
        {/* Cabinet */}
        <div className="flex flex-col justify-start items-start gap-3">
          <div className="text-sm font-semibold text-black">{i18n.cabinet}</div>
          <Picker
            options={adsSystemsOptions}
            defaultOption={adsSystemsOptions.indexOf(filters.cabinet) === -1
              ? 0
              : adsSystemsOptions.indexOf(filters.cabinet)}
            handleChange={(value) => handleFilterChange("cabinet", value)}
            colors={{
              selected: palette.grey[500],
              options: palette.text.primary,
            }}
          />
        </div>
        {/* Mediaplan (search field) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">
            {i18n.mediaplan}
          </div>
          <TextInput
            id="search"
            placeholder={i18n.mediaplan}
            icon={<IconSearch size={16} color={palette.grey[400]} />}
            className="w-[9rem]"
            sx={outlinedInputSx(palette)}
            onChange={(e) => handleFilterChange("mediaplan", e.target.value)}
            value={filters.mediaplan}
          />
        </div>

        {/* Campaign (search field) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">
            {i18n.campaign}
          </div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.campaign)}
            placeholder={i18n.campaign}
            onChange={(value) => handleFilterChange("campaign", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
            value={filters.campaign}
          />
        </div>

        {/* Brand (multi-select) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">{i18n.brand}</div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.brand)}
            placeholder={i18n.brand}
            onChange={(value) => handleFilterChange("brand", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
            value={filters.brand}
          />
        </div>

        {/* Status (multi-select) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">{i18n.status}</div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.status)}
            placeholder={i18n.status}
            onChange={(value) => handleFilterChange("status", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
            value={filters.status}
          />
        </div>

        {/* Start date (calendar picker) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">
            {i18n.startDate}
          </div>
          <DateInput
            onChange={(date) => handleFilterChange("startDate", date)}
            defaultDate={filters.startDate}
          />
        </div>

        {/* End date (calendar picker) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">{i18n.endDate}</div>
          <DateInput
            onChange={(date) => handleFilterChange("endDate", date)}
            defaultDate={filters.endDate}
          />
        </div>

        {/* Only include last day (checkbox) */}
        <div className="flex flex-row justify-start items-center gap-2">
          <input
            type="checkbox"
            onChange={(e) => handleFilterChange("lastDay", e.target.checked)}
            checked={filters.lastDay}
          />
          <div className="text-black text-sm leading-[120%]">
            {i18n.lastDay}
          </div>
        </div>
      </div>
    </Box>
  );
};

export const CampDashboardFilters = ({
  onChange,
  userRole,
}: {
  onChange?: (value: CampFiltersState) => void;
  userRole: "admin" | "director";
}) => {
  const { shadows, palette } = useTheme();
  const { dashboard: i18n } = useLocale();
  const params = useParams();
  const agency = useAgency();
  const clientId = params["client"];

  const [filters, setFilters] = useState<CampFiltersState>(
    initialCampFiltersState,
  );

  const handleAllFiltersChange = (value: CampFiltersState) => setFilters(value);

  const handleFilterChange = (
    key: keyof CampFiltersState,
    value: CampFiltersState[typeof key],
  ) => setFilters((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    onChange && onChange(filters);
  }, [filters, onChange]);

  const filterRes = usePlanFactMediaplanAdsFilterQuery(
    {
      agency_id: userRole === "admin"
        ? Number(params["agency"])
        : agency.agencyId!,
      client_id: Number(clientId),
      insight_campaign_id: params["campaign"]!,
    },
    {
      skip: (userRole !== "admin" && agency.agencyId === null) ||
        !params["campaign"],
    },
  );

  useEffect(() => {
    console.log("filterRes", filterRes);
  }, [filterRes]);

  const filterOptions = useMemo(() => {
    if (!filterRes.data || !filterRes.data?.result) {
      return {
        ad_groups: [],
        ads: [],
        geo: [],
        social_demography: [],
        date_to: [],
        date_from: [],
      };
    }

    return { ...filterRes.data.result };
  }, [filterRes.data]);

  return (
    <Box
      className="w-full rounded-lg h-24 bg-white mt-2 mb-6 pt-4 px-4"
      boxShadow={shadows["3"]}
    >
      <div className="flex flex-row justify-evenly gap-4 items-stretch">
        {/* Mediaplan (search field) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">
            {i18n.adGroup}
          </div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.ad_groups)}
            placeholder={i18n.chooseAdGroup}
            onChange={(value) => handleFilterChange("adGroup", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
          />
        </div>

        {/* Campaign (search field) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">
            {i18n.ad}
          </div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.ads)}
            placeholder={i18n.chooseAd}
            onChange={(value) => handleFilterChange("ad", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
          />
        </div>

        {/* Start date (calendar picker) */}
        <div className="flex flex-col justify-start items-start gap-1 max-w-[12rem]">
          <div className="text-sm font-semibold text-black">
            {i18n.startDate}
          </div>
          <DateInput
            onChange={(date) => handleFilterChange("startDate", date)}
            defaultDate={filters.startDate}
          />
        </div>

        {/* End date (calendar picker) */}
        <div className="flex flex-col justify-start items-start gap-1 max-w-[12rem]">
          <div className="text-sm font-semibold text-black">{i18n.endDate}</div>
          <DateInput
            onChange={(date) => handleFilterChange("endDate", date)}
            defaultDate={filters.endDate}
          />
        </div>

        {/* Only include last day (checkbox) */}
        <div className="flex flex-row justify-start items-center gap-2 max-w-[9rem]">
          <input
            type="checkbox"
            onChange={(e) => handleFilterChange("lastDay", e.target.checked)}
          />
          <div className="text-black text-sm leading-[120%]">
            {i18n.lastDay}
          </div>
        </div>
        {/* Geography (multi-select) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">{i18n.geo}</div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.geo)}
            placeholder={i18n.chooseGeo}
            onChange={(value) => handleFilterChange("geography", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
          />
        </div>
        {/* Demographic (multi-select) */}
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-sm font-semibold text-black">{i18n.socDem}</div>
          <AutocompleteSelectInput
            options={formOptionsList("Все", filterOptions.social_demography)}
            placeholder={i18n.chooseSocDem}
            onChange={(value) => handleFilterChange("demographic", value)}
            sx={{
              ...outlinedInputSx(palette),
              "& input": { pl: ".75rem !important" },
            }}
            className="w-[9rem]"
          />
        </div>
      </div>
    </Box>
  );
};
