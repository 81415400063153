import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  // GlobalState,
  RequestAdminRegister,
  RequestDeleteUser,
  RequestEditUser,
  ResponseEditUser,
} from "shared/types";
import {
  // PUBLIC_API_URL,
  QUERY_CREATE_SYSTEM_ADMIN,
  QUERY_READ_SYSTEM_ADMIN,
  QUERY_UPDATE_SYSTEM_ADMIN,
  QUERY_DELETE_SYSTEM_ADMIN,
  QUERY_UPDATE_USER,
} from "shared/constants";

// const SuperAdminApi = createApi({
const SuperAdminApi = baseApi.injectEndpoints({
  // reducerPath: "superAdminApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders(headers, { getState }) {
  //     const state = getState() as GlobalState;
  //     headers.append("Authorization", `Bearer ${state.auth.accessToken}`)
  //     return headers;
  //   },
  // }),
  // refetchOnFocus: true,
  endpoints: (build) => ({
    createSystemAdmin: build.query<ResponseCommon, RequestAdminRegister>({
      query: (data: RequestAdminRegister) => ({
        url: QUERY_CREATE_SYSTEM_ADMIN,
        method: "post",
        body: { ...data },
      }),
    }),
    readSystemAdmins: build.query<
      ResponseReadSystemAdmins,
      RequestReadSystemAdmins
    >({
      query: (data: RequestReadSystemAdmins) => ({
        url: QUERY_READ_SYSTEM_ADMIN,
        method: "get",
        params: { ...data },
      }),
    }),
    updateSystemAdmin: build.query<string, RequestAdminStatus>({
      query: (data: RequestAdminStatus) => ({
        url: QUERY_UPDATE_SYSTEM_ADMIN,
        method: "put",
        body: { ...data },
      }),
    }),
    deleteSystemAdmin: build.query<ResponseCommon, RequestDeleteUser>({
      query: (data: RequestDeleteUser) => ({
        url: QUERY_DELETE_SYSTEM_ADMIN,
        method: "delete",
        params: { ...data },
      }),
    }),
    editUser: build.query<ResponseEditUser, RequestEditUser>({
      query: (data: RequestEditUser) => ({
        url: QUERY_UPDATE_USER,
        method: "put",
        body: { ...data, user_id: null },
        params: { user_id: data.user_id },
      }),
    }),
  }),
});

export const {
  useCreateSystemAdminQuery,
  useLazyCreateSystemAdminQuery,
  useReadSystemAdminsQuery,
  useLazyReadSystemAdminsQuery,
  useUpdateSystemAdminQuery,
  useLazyUpdateSystemAdminQuery,
  useDeleteSystemAdminQuery,
  useLazyDeleteSystemAdminQuery,
  useEditUserQuery,
  useLazyEditUserQuery,
} = SuperAdminApi;

export default SuperAdminApi;
