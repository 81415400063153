import { Box, Button, IconButton } from "@mui/material";
import {
  IconApi,
  IconArrowNarrowLeft,
  IconBriefcase,
} from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { TabBox } from "shared/ui/tabs";
import { CommonLayout } from "shared/ui/layout";
import { ClientsList } from "widgets/clientsList";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ClientsApis } from "widgets/clientsApis";
// import { clients } from "./mock";
import { useReadClientsByAgencyQuery } from "entities/clients";
import { ROUTES } from "shared/constants";

const iconProps = {
  size: 14,
  strokeWidth: 1.4,
  color: "black",
};

export const AdminAgencyPage = () => {
  const i18n = useLocale().admin;
  const agencyId = useParams().id as string;
  const [clients, setClients] = useState<PaginatedResult<Client>>();
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  const handleAddApi = () => navigate(`${ROUTES.createApi}/${agencyId}`);

  //TODO: get agencyName
  const agencyName = "ADVTech";

  const title = useMemo(
    () => (
      <Box className="flex gap-5 items-center">
        <IconButton onClick={handleGoBack} className="h-auto ">
          <IconArrowNarrowLeft />
        </IconButton>
        <h1>{agencyName}</h1>
      </Box>
    ),
    [agencyName]
  );

  const { data, isLoading, refetch, isUninitialized } =
    useReadClientsByAgencyQuery(
      {
        agency_id: agencyId as unknown as number,
        page,
        size: 10
      },
      { skip: !agencyId }
    );

  useEffect(() => {
    if (isLoading) return;

    if (data?.message === "Success") {
      setClients(data.result);
    }
  }, [isLoading, data]);

  const contentForTabs: ContentTab[] = [
    {
      icon: <IconApi {...iconProps} />,
      title: i18n.clients.api,
      content: <ClientsApis agencyId={parseInt(agencyId)} handleAdd={() => {}}/>,
    },
    {
      icon: <IconBriefcase {...iconProps} />,
      title: i18n.clients.title,
      content: (
        clients && <ClientsList
          clientsResult={clients}
          agencyId={Number(agencyId)}
          refetch={refetch}
          isUninitialized={isUninitialized}
          handlePageChange={(page) => setPage(page)}
          setTab={(tab: string) => {}}
        />
      ),
    },
  ];

  return (
    <CommonLayout
      title={title}
      btns={
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="bg-primary text-white normal-case"
          onClick={handleAddApi}
        >
          {i18n.add} API
        </Button>
      }
    >
      <TabBox contentTabs={contentForTabs} ariaLabel="Управление страницей" />
    </CommonLayout>
  );
};
