import { useState } from "react";

export const ExchangeRateInput = ({
  value,
  onChange,
  formatter,
}: {
    value: number;
    onChange: (value: number) => void;
    formatter?: (value: number) => string;
  }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(Number(e.target.value));
  };
  
  const handleBlur = () => {
    setEditing(false);
    onChange(inputValue);
  };
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      handleBlur();
    }
  };
  
  const handleFocus = () => {
    setEditing(true);
  };
  
  return (
    <div className="flex flex-row flex-auto flex-start h-[1.5rem] w-[4rem]">
      {editing ? (
        <input
          autoFocus
          className="bg-grey-100 border border-grey-100 border-solid outline-none shadow-none w-full"
          type="number"
          value={inputValue}
          onChange={handleInput}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      ) : (
      //eslint-disable-next-line
          <span
          className="w-full leading-[1.5rem]"
          role="cell"
          onFocus={handleFocus}
          onClick={handleFocus}
        >
          {formatter ? formatter(inputValue) : inputValue}
        </span>
      )}
    </div>
  );
};