import { Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const i18n = {
  goHome: "На главную",
}

interface SuccessSendEmailProps extends React.SuspenseProps {
  email: string
}

export default ({ email }:SuccessSendEmailProps) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        color="dark"
        component="span"
        justifyContent="center"
        alignItems="center"
        className="font-inter"
        sx={{ mt: 10, mb: 10, font: "Open Sans", fontSize: "20px", lineHeight: "31px" }}
      >
        На электронную почту <strong>{email}</strong> отправлена ссылка и инструкция для восстановления пароля
      </Typography>
      
      <Grid item xs={12} sx={{ mt: -1 }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} className="font-inter">
          <Link component={RouterLink} to="/auth" color="text.primary" className="no-underline">
            {i18n.goHome}
          </Link>
        </Stack>
      </Grid>
    </>
  )
}