import { useAgency } from "./useAgency";
import { ComponentType } from "react";

type Props = {
  agency: ComponentType;
  agencyProps?: Record<string, unknown>;
  noAgency: ComponentType;
  noAgencyProps?: Record<string, unknown>;
};

export const AgencyRouter = ({
  agency: Agency,
  agencyProps,
  noAgency: NoAgency,
  noAgencyProps,
}: Props) => {
  const { agencyId } = useAgency();

  // useEffect(() => {
  //   console.log("AgencyRouter", { agencyId });
  // }, [agencyId]);

  return agencyId ? (
    <Agency {...agencyProps} />
  ) : (
    <NoAgency {...noAgencyProps} />
  );
};
