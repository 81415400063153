import { CabinetBadge } from "shared/ui/icons";
import { ComplexList, ComplexListProps } from "shared/ui/list";
import { ADS_SYSTEMS_READABLE, adsSystems } from "shared/constants";
import { IconButtonStack } from "shared/ui/components";
import {
  IconEdit,
  IconCirclePlus,
  IconTrash,
  IconCheck,
  IconX,
} from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { useTheme } from "@mui/material/styles";
import { useAgency } from "entities/admin";
import {
  useReadExchangeRatesQuery,
  useCreateExchangeRateMutation,
  useDeleteExchangeRateMutation,
  useUpdateExchangeRateMutation,
  ExchangeRate,
} from "entities/exchange";
import { useEffect, useMemo, useState } from "react";
import {
  IconButton,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { actionIconButtons, iconProps } from "./utils";
import { AdsSystemSelect } from "./AdsSystemSelect";
import { ExchangeRateInput } from "./ExchangeRateInput";

/**
 * The order in which the cabinets are displayed
 */
const ADS_SYSTEMS_ORDER = [
  // adsSystems.fb,
  "FACEBOOK",
  adsSystems.vk,
  adsSystems.yandex,
  adsSystems.tiktok,
  adsSystems.google,
];

export const CurrencyList = () => {
  const { director: i18n } = useLocale();
  const { palette } = useTheme();
  const agency = useAgency();

  const cols = useMemo<ComplexListProps["cols"]>(
    () => [
      { name: "cabinet", title: i18n.adCabinet },
      { name: "usd", title: "USD" },
      { name: "rur", title: "RUR" },
      { name: "actions", title: "" },
    ],
    [i18n],
  );

  const [currentlyEditing, setCurrentlyEditing] = useState(-1);
  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [newApiType, setNewApiType] = useState<adsSystems>(adsSystems.fb);
  const [newValueUsd, setNewValueUsd] = useState<number>(0);
  const [newValueRub, setNewValueRub] = useState<number>(0);

  const {
    data: rates,
    isLoading,
    isUninitialized,
    refetch: rtkRefetch,
  } = useReadExchangeRatesQuery(
    { agency_id: agency.agencyId! },
    { skip: !agency.agencyId },
  );
  const readSuccess = rates?.status_code === 0;

  /**
   * Refetches data after a timeout
   * @param ms - milliseconds to wait before refetching
   */
  const refetch = (ms: number) => {
    if (apiCallTimeout) {
      clearTimeout(apiCallTimeout);
    }
    const timeout = setTimeout(() => {
      !isUninitialized && rtkRefetch();
    }, ms);
    setApiCallTimeout(timeout);
  };

  const [createRate] = useCreateExchangeRateMutation();
  const [updateRate] = useUpdateExchangeRateMutation();
  const [deleteRate] = useDeleteExchangeRateMutation();

  //=== USER ACTION HANDLERS ===//

  const handleCreate = () => {
    setCurrentlyEditing(ratesRows.length);
    const cabinets = rates!.result.map((e) => e.api_type);
    setNewApiType(
      Object.values(adsSystems).filter((e) => !cabinets.includes(e))[0],
    );
    setNewValueUsd(0);
    setNewValueRub(0);
  };

  const handleDelete = async (apiType: adsSystems) => {
    readSuccess &&
      agency.agencyId &&
      (await deleteRate({
        api_type: apiType === "META" ? "FACEBOOK" : apiType,
        agency_id: agency.agencyId,
      }));
    !isUninitialized && refetch(0);
  };
  const handleEditRates = (rowIndex: number) => (apiType: adsSystems) => {
    if (rates) {
      const index = rates.result.findIndex(
        (rate) => rate.api_type === (apiType === "META" ? "FACEBOOK" : apiType),
      );
      setNewApiType(apiType);
      setNewValueUsd(rates.result[index].value_usd);
      setNewValueRub(rates.result[index].value_rub);
      setCurrentlyEditing(rowIndex);
    }
  };
  const handleSubmitNewRates = async () => {
    const response = await createRate({
      agency_id: agency.agencyId!,
      api_type: newApiType === "META" ? "FACEBOOK" : newApiType,
      value_usd: newValueUsd,
      value_rub: newValueRub,
    });
    !isUninitialized && refetch(0);
    setCurrentlyEditing(-1);
  };
  const handleSubmitEditedRates = async () => {
    agency &&
      readSuccess &&
      (await updateRate({
        id: rates.result.find(
          (rate) =>
            rate.api_type === (newApiType === "META" ? "FACEBOOK" : newApiType),
        )!.id,
        api_type: newApiType === "META" ? "FACEBOOK" : newApiType,
        value_usd: newValueUsd,
        value_rub: newValueRub,
      }));
    !isUninitialized && refetch(0);
    setCurrentlyEditing(-1);
  };
  const handleAbortNewRates = () => {
    setCurrentlyEditing(-1);
  };

  //=== ROWS ===//
  let newRow = useMemo<
    (action: "create" | "update") => ArrayElement<ComplexListProps["rows"]>
      >(
      () => (action) => ({
        cabinet: {
          content:
          action === "create" ? (
            <AdsSystemSelect
              value={newApiType}
              onChange={setNewApiType}
              options={Object.values(adsSystems)}
            />
          ) : (
            <CabinetBadge
              title={ADS_SYSTEMS_READABLE[newApiType]}
              color={palette.primary.main}
              icon={newApiType}
            />
          ),
        },
        usd: {
          content: (
            <ExchangeRateInput
              value={newValueUsd}
              onChange={setNewValueUsd}
              formatter={(value) => (value > 0 ? `${value.toFixed(2)} KZT` : "")}
            />
          ),
        },
        rur: {
          content: (
            <ExchangeRateInput
              value={newValueRub}
              onChange={setNewValueRub}
              formatter={(value) => (value > 0 ? `${value.toFixed(2)} KZT` : "")}
            />
          ),
        },
        actions: {
          content: (
            <div className="flex flex-row gap-3 float-right">
              <IconButton
                className="p-0"
                onClick={
                  action === "create"
                    ? handleSubmitNewRates
                    : handleSubmitEditedRates
                }
              >
                <IconCheck {...iconProps} />
              </IconButton>
              <IconButton className="p-0" onClick={handleAbortNewRates}>
                <IconX {...iconProps} />
              </IconButton>
            </div>
          ),
        },
      }),
      [newApiType, newValueUsd, newValueRub],
      );

  const compileRatesRow = (rate: ExchangeRate, rateIndex: number) => ({
    cabinet: {
      content: (
        <CabinetBadge
          title={
            ADS_SYSTEMS_READABLE[
              rate.api_type === "FACEBOOK" ? "META" : rate.api_type
            ]
          }
          color={palette.primary.main}
          icon={rate.api_type === "FACEBOOK" ? "META" : rate.api_type}
        />
      ),
    },
    usd: {
      content: rate.value_usd > 0 ? rate.value_usd.toFixed(2) + " KZT" : "",
    },
    rur: {
      content: rate.value_rub > 0 ? rate.value_rub.toFixed(2) + " KZT" : "",
    },
    actions: {
      content: (
        <IconButtonStack
          iconButtons={actionIconButtons(
            handleEditRates(rateIndex),
            handleDelete,
            rate.api_type === "FACEBOOK"
              ? ("META" as adsSystems)
              : rate.api_type,
          )}
          iconProps={iconProps}
          className="gap-3 justify-end"
        />
      ),
    },
  });

  const ratesRows = useMemo(() => {
    const rows: ComplexListProps["rows"] = [];
    rates &&
      ADS_SYSTEMS_ORDER.forEach((e) => {
        const ratesEntries = rates.result.filter((rate) => rate.api_type === e);
        ratesEntries.length > 0 &&
          rows.push(compileRatesRow(ratesEntries[0], rows.length));
      });
    return rows;
  }, [rates, readSuccess]);

  const rows = useMemo(() => {
    const plusRow = {
      //all columns empty except last one, it has an IconButton
      cabinet: {
        content: "",
      },
      usd: {
        content: "",
      },
      rur: {
        content: "",
      },
      actions: {
        content: (
          // <div className="flex flex-row justify-end">
          <IconButton onClick={handleCreate} className="float-right p-0">
            <IconCirclePlus
              {...iconProps}
              className="stroke-grey-400 hover:stroke-black"
            />
          </IconButton>
          // </div>
        ),
      },
    };

    const rows = [...ratesRows];
    if (currentlyEditing < ratesRows.length)
      rows[currentlyEditing] = newRow("update");
    currentlyEditing == ratesRows.length
      ? rows.push(newRow("create"))
      : rows.length != ADS_SYSTEMS_ORDER.length && rows.push(plusRow);
    return rows;
  }, [currentlyEditing, newRow, ratesRows]);

  return (
    <div className="w-1/2 min-w-[40rem] mt-4">
      <ComplexList cols={cols} rows={rows} />
    </div>
  );
};
