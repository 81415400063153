//TODO: move it to i18n
export const i18nLoc = {
  yandex_type_account_login: "Введите логин Яндекс аккаунта",
  yandex_login: "Логин",
  yandex_follow_the: "Перейдите по",
  yandex_link: "ссылке",
  yandex_instruction_step_1:
    'Авторизуйтесь через нужный аккаунт, а затем предоставьте приложению разрешения на запрашиваемые доступы, нажав "Разрешить"',
  yandex_instruction_step_2:
    "После этого произойдет редирект на страницу app.danametrics.ai и токен Yandex будет добавлен",
  set_api_name:
    "Укажите название API, чтобы удобнее идентифицировать его в будущем",
  api_name: "Название для API",
  vk_type_account_id: "Введите ID рекламного кабинета.",
  vk_where_to_find_id: "по ссылке",
  vk_id: "ID рекламного кабинета",
  vk_follow_the: "Перейдите по",
  follow_the: "Перейдите по",
  vk_link: "ссылке",
  link: "ссылке",
  vk_instruction_step_1:
    'Предоставьте приложению разрешения на запрашиваемые доступы и нажмите "Confirm"',
  vk_instruction_step_2:
    "После этого произойдет редирект на страницу app.danametrics.ai и токен VKontakte будет добавлен",
  fb_type_account_id: "Введите ID рекламного аккаунта.",
  fb_instruction: "Инструкция",
  fb_where_to_find_id: "где найти ID рекламного аккаунта?",
  fb_company_id: "ID компании",
  fb_follow_the: "Перейдите по",
  fb_link: "ссылке",
  fb_instruction_step_1:
    'Авторизуйтесь через нужный аккаунт, а затем предоставьте приложению разрешения на запрашиваемые доступы, нажав " Разрешить"',
  fb_instruction_step_2:
    "После этого произойдет редирект на страницу app.danametrics.ai и токен facebook будет добавлен",
  google_type_login: "Введите Login Google.",
  google_login: "Login Google",
  google_type_account_id:
    "Введите ID рекламного аккаунта и ID управляющего аккаунта.",
  google_instruction_account_id: "Инструкция",
  google_where_to_find_account_id: "где найти ID управляющего аккаунта?",
  google_account_id: "ID управляющего аккаунта",
  google_type_client_id: "Введите идентификатор клиента.",
  google_where_to_find_client_id: "где найти ID клиента?",
  google_client_id: "ID клиента",
  google_follow_the: "Перейдите по",
  google_link: "ссылке",
  google_instruction_step_1:
    'Предоставьте приложению разрешения на запрашиваемые доступы и нажмите "Confirm"',
  google_instruction_step_2:
    "После этого произойдет редирект на страницу danametrics.ai и токен Google будет добавлен",

  tiktok_type_account_id: "Введите ID рекламного аккаунта TikTok.",
  tiktok_instruction: "Инструкция",
  tiktok_where_to_find_id: "где найти ID рекламного аккаунта?",
  tiktok_id: "ID",
  tiktok_follow_the: "Перейдите по",
  tiktok_link: "ссылке",
  tiktok_instruction_step_1:
    'Предоставьте приложению разрешения на запрашиваемые доступы и нажмите "Confirm"',
  tiktok_instruction_step_2:
    "После этого произойдет редирект на страницу app.danametrics.ai и токен TikTok будет добавлен",
};
