import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconSearch } from "@tabler/icons-react";
import {
  useDeleteClientMutation,
  useReadClientsQuery,
  useUpdateClientStatusMutation,
} from "entities/clients";
import { useLocale } from "entities/i18n";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "shared/ui/form";
import { Dialog, useDashboardDialog } from "shared/ui/components";
import { ComplexListProps, PaginatedComplexList } from "shared/ui/list";
import { compileListData } from "./utils";

export const AllClientsList = () => {
  const { admin: i18n, core: i18nCore } = useLocale();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [binFilter, setBinFilter] = useState<string>();
  const [nameFilter, setNameFilter] = useState<string>();
  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout>();

  const [page, setPage] = useState<number>(1);

  const [deleteClient] = useDeleteClientMutation();
  //! to be implemented later
  const [updateClientStatus] = useUpdateClientStatusMutation();

  const {
    action: selectedAction,
    index: selectedIndex,
    dialogToggler,
    dialogVisible,
  } = useDashboardDialog();

  const dialogOpenHandler = (action: DialogAction, index: number) => () => {
    selectedAction.set(action);
    selectedIndex.set(index);
    dialogToggler();
  };

  const {
    data: clients,
    isLoading,
    isUninitialized,
    refetch,
  } = useReadClientsQuery(
    { name: nameFilter, bin: binFilter, page, size: 10 },
    { refetchOnMountOrArgChange: false },
  );

  const handleMutation = (action: DialogAction, index: number) => {
    if (action === "deactivate") {
      clients &&
        updateClientStatus({
          client_id: clients?.result.items[index].id,
          is_active: !clients?.result.items[index].is_active,
        });
      clearTimeout(apiCallTimeout);
      setApiCallTimeout(
        setTimeout(async () => {
          isUninitialized || (await refetch());
        }, 1000),
      );

      dialogToggler();
    }
    if (action === "delete") {
      clients && deleteClient({ client_id: clients?.result.items[index].id });
      clearTimeout(apiCallTimeout);
      setApiCallTimeout(
        setTimeout(async () => {
          isUninitialized || (await refetch());
        }, 1500),
      );
      dialogToggler();
    }
  };

  const cols: ComplexListProps["cols"] = useMemo(() => {
    const cols = [
      {
        name: "name",
        title: i18n.clientName,
      },
      {
        name: "bin",
        title: i18n.BIN,
      },
      {
        name: "agencies",
        title: i18n.adAgencies,
      },
      {
        name: "actions",
        title: "",
      },
    ];
    return cols;
  }, [i18n]);

  const rows = useMemo(() => {
    if (clients) {
      return compileListData({
        clients: clients.result.items,
        i18n,
        navigate,
        palette,
        dialogOpenHandler,
      });
    }
    return [];
  }, [clients, i18n, palette, navigate]);

  if (isLoading || isUninitialized) return <div>Loading...</div>;

  return (
    <>
      <div className="flex flex-row gap-[2rem]">
        <TextInput
          id="search"
          placeholder={i18n.organizationName}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3 w-[15rem]"
          onChange={(e) => {
            setNameFilter(e.target.value);
            clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                isUninitialized || (await refetch());
              }, 1500),
            );
          }}
        />
        <TextInput
          id="search"
          placeholder={i18n.BIN}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3"
          onChange={(e) => {
            setBinFilter(e.target.value);
            clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                isUninitialized || (await refetch());
              }, 1500),
            );
          }}
        />
      </div>
      {clients && (
        <PaginatedComplexList.server
          data={{ cols, rows }}
          rowsPerPage={10}
          totalRows={clients.result.total}
          pages={clients.result.pages}
          handlePageChange={(page) => {
            setPage(page + 1); //WARN: without adding 1 it causes errors, because index in pagination component starts from 0, not 1. If there will be changes in pagination logic, you should change this line
            refetch();
          }}
        />
      )}
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {selectedAction.get() == "deactivate" &&
                (clients?.result.items[selectedIndex.get()].is_active
                  ? i18n.clients.deactivation
                  : i18n.clients.activation)}
              {selectedAction.get() === "delete" && i18n.clients.deletion}
            </div>
            <div className="font-bold">
              {rows && rows[selectedIndex.get()].name.content}
            </div>
            <div>
              <span className="font-bold">{i18n.BIN} </span>
              {rows && rows[selectedIndex.get()].bin.content}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={() =>
                  handleMutation(selectedAction.get(), selectedIndex.get())
                }
              >
                {selectedAction.get() === "deactivate" &&
                  (clients?.result.items[selectedIndex.get()].is_active
                    ? i18nCore.deactivate
                    : i18nCore.activate)}
                {selectedAction.get() === "delete" && i18nCore.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18nCore.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
