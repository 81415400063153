import { useRef, useEffect, useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  Stack,
  Typography,
  Button,
  Link,
  FormHelperText,
  InputAdornment,
  IconButton as MuiIconButton,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
import { IconAt, IconUserCircle } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useLazyAgencyDirectorRegisterQuery } from "entities/auth";
import { AnimateButton } from "shared/ui";
import { useLocale } from "entities/i18n";

const RegistrationForm = () => {
  const i18n = useLocale();
  const theme = useTheme();
  const [queryRegister, { isLoading, isSuccess, data }] =
    useLazyAgencyDirectorRegisterQuery();
  const scriptedRef = useRef(true);
  const navigate = useNavigate();
  const initialValues = {
    // email: "root@gmail.com",
    // full_name: "Имя Фамилия",
    email: "",
    full_name: "",
    termsOfService: false,
    submit: null,
  };

  const iconProps = {
    color: theme.palette.grey[400],
    size: 20,
    strokeWidth: 2.5,
  };

  const [emailState, setEmailState] = useState(initialValues.email);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  useEffect(() => {
    if (isSuccess) {
      data?.status_code == 0 &&
        navigate("/register-success", {
          replace: true,
          state: { email: emailState },
        });
    }
  }, [isSuccess]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18n.auth.errors.emailFormat)
            .max(255)
            .required(i18n.auth.errors.fieldRequired),
          full_name: Yup.string()
            .max(255)
            .required(i18n.auth.errors.fieldRequired),
          termsOfService: Yup.boolean().oneOf([true], i18n.auth.errors.TOS),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const { email, full_name } = values;
          try {
            setEmailState(email);
            await queryRegister({ email, full_name });
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} className="font-open-sans">
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  className="font-inter"
                >
                  <Typography
                    variant="subtitle2"
                    color="dark"
                    component="span"
                    justifyContent="center"
                    alignItems="center"
                    className="font-inter"
                    sx={{
                      fontWeight: 700,
                      fontSize: "36px",
                    }}
                  >
                    {i18n.auth.registration}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <OutlinedInput
                    id="email-registration"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="E-mail"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    startAdornment={
                      <InputAdornment position="start">
                        <MuiIconButton
                          aria-label="toggle email visibility"
                          edge="start"
                          color="secondary"
                        >
                          <IconAt {...iconProps} />
                        </MuiIconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                  {data?.status_code == 2014 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {i18n.auth.errors.emailExists}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.full_name && errors.full_name)}
                    id="full_name-registration"
                    type="text"
                    value={values.full_name}
                    name="full_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <MuiIconButton
                          aria-label="toggle full name visibility"
                          edge="start"
                          color="secondary"
                        >
                          <IconUserCircle {...iconProps} />
                        </MuiIconButton>
                      </InputAdornment>
                    }
                    placeholder={i18n.auth.fullNamePlaceholder}
                  />
                  {touched.full_name && errors.full_name && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-name-login"
                    >
                      {errors.full_name}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} sx={{ my: 1 }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="font-inter"
                >
                  <Checkbox
                    checked={values.termsOfService}
                    onChange={handleChange}
                    name="termsOfService"
                    color="primary"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                  >
                    {i18n.auth.TOS["1_accept"]}{" "}
                    {/*                     <Link
                      component={RouterLink}
                      to="#"
                      color="primary"
                      underline="always"
                    >
                      {i18n.auth.TOS["2_privacyPolicy"]}
                    </Link> */}
                    <a
                      href="./confidentiality.html"
                      target="_blank"
                      rel="noreferrer"
                      className="no-underline text-primary hover:underline"
                    >
                      {i18n.auth.TOS["2_privacyPolicy"]}
                    </a>{" "}
                    {i18n.auth.TOS["3_and"]}{" "}
                    {/*                     <Link
                      component={RouterLink}
                      to="#"
                      color="primary"
                      underline="always"
                    >
                      {i18n.auth.TOS["4_oferta"]}
                    </Link> */}
                    <a
                      href="./user_agreement.html"
                      target="_blank"
                      rel="noreferrer"
                      className="no-underline text-primary hover:underline"
                    >
                      {i18n.auth.TOS["4_oferta"]}
                    </a>
                    .
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <AnimateButton
                  type="scale"
                  direction="right"
                  offset={10}
                  scale={{
                    hover: 1.05,
                    tap: 0.95,
                  }}
                >
                  <Button
                    disableElevation
                    disabled={isLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className="bg-primary text-white normal-case"
                  >
                    {i18n.auth.register}
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  className="font-inter"
                >
                  <Link
                    component={RouterLink}
                    to="/auth"
                    color="text.primary"
                    className="no-underline"
                  >
                    {i18n.auth.entrance}
                  </Link>
                  <Link
                    component={RouterLink}
                    to="/reset-password"
                    color="text.primary"
                    className="no-underline"
                  >
                    {i18n.auth.passwordRecovery}
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;
