import { Button } from "@mui/material";
import { CommonLayout } from "shared/ui/layout";
import { TabBox } from "shared/ui/tabs";
import { IconAd, IconBriefcase } from "@tabler/icons-react";
import { AgencyList as AgencyListWidget } from "widgets/admin";
import { AllClientsList as AllClientsListWidget } from "widgets/admin";
import { useNavigate } from "react-router-dom";
import { useLocale } from "entities/i18n";
import { useMemo, useState } from "react";

const iconProps = {
  size: 14,
  strokeWidth: 1.4,
  color: "black",
};

export default () => {
  const navigate = useNavigate();
  const { admin: i18n } = useLocale();

  const [tabIndex, settabIndex] = useState(0);
  const { title, showBtn } = useMemo(() => {
    const result = {
      title: i18n.adAgencies,
      showBtn: true,
    };

    //If index === 1, dont show btn and show title for Clients
    if (tabIndex === 1) {
      result.title = i18n.clients.title;
      result.showBtn = false;
    }

    return result;
  }, [tabIndex]);

  const handleAdd = () => {
    navigate("/admin/agencies/create");
  };

  const onTabChange = (newVal: number) => settabIndex(newVal);

  return (
    <CommonLayout
      title={<h1>{title}</h1>}
      btns={
        showBtn ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="bg-primary text-white normal-case"
            onClick={handleAdd}
          >
            {i18n.add}
          </Button>
        ) : null
      }
    >
      <TabBox
        onChange={onTabChange}
        contentTabs={[
          {
            icon: <IconAd {...iconProps} />,
            title: i18n.agenciesTab,
            content: <AgencyListWidget />,
          },
          {
            icon: <IconBriefcase {...iconProps} />,
            title: i18n.clientsTab,
            content: <AllClientsListWidget />,
          },
        ]}
      />
    </CommonLayout>
  );
};
