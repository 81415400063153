// material-ui
import { Container, Stack, Typography, TypographyProps } from "@mui/material";

// ==============================|| FOOTER ||============================== //

interface FooterProps {
  matchDownSM: boolean;
  leftTypography: string;
  leftHref?: string;
  centerTypography?: string;
  centerHref?: string;
  centerNode?: React.ReactNode;
  rightTypography: string;
  rightHref?: string;
  className?: string;
}

export default ({
  matchDownSM,
  leftTypography,
  leftHref,
  centerTypography,
  centerHref,
  centerNode,
  rightTypography,
  rightHref,
  className,
}: FooterProps) => {
  const typographyProps = {
    variant: "subtitle2",
    color: "dark",
    component: "span",
    className: "font-inter",
  } as TypographyProps;
  return (
    <Container maxWidth="xl" className={`font-inter ${className}`}>
      <Stack
        direction={matchDownSM ? "column" : "row"}
        justifyContent={matchDownSM ? "center" : "space-between"}
        spacing={2}
        textAlign={matchDownSM ? "center" : "inherit"}
      >
        <a
          href={leftHref}
          target="_blank"
          rel="noreferrer"
          className="no-underline text-black"
        >
          <Typography {...typographyProps}>{leftTypography}</Typography>
        </a>
        {centerNode || (
          <a
            href={centerHref}
            target="_blank"
            rel="noreferrer"
            className="no-underline text-black"
          >
            <Typography {...typographyProps}>{centerTypography}</Typography>
          </a>
        )}
        <a
          href={rightHref}
          target="_blank"
          rel="noreferrer"
          className="no-underline text-black"
        >
          <Typography {...typographyProps}>{rightTypography}</Typography>
        </a>
      </Stack>
    </Container>
  );
};
