import { IconApi, IconBriefcase, IconInfoCircle, IconUserCircle } from "@tabler/icons-react"
import { ROUTES, tabIconProps } from "shared/constants"
import {TabBox} from "shared/ui/tabs"
import { AgencyUsersList } from "widgets/agencyUsersList"
import { ClientsApis } from "widgets/clientsApis"
import { ClientsList } from "widgets/clientsList"
import {AgencyForm} from "./AgencyForm"
import { useLocale } from "entities/i18n"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useReadClientsByAgencyQuery } from "entities/clients"

const TABS = ["info", "clients", "users", "api"];
const activeTabIndex = (activeTab: string) => TABS.findIndex((tab) => tab === activeTab);

type Props = {
    activeTab: "info" | "clients" | "users" | "api";
    agencyId: number;
    locationState: Agency;
    handlers?: {
        cancel?: () => void;
        add?: {
            client?: () => void;
            user?: () => void;
            api?: () => void;
        }
        changeTab: (index: number) => void;
    }
}

export const AgencyProfile = (props: Props) => {
  const { admin: i18n } = useLocale();
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  
  const {data: clients, refetch, isUninitialized} = useReadClientsByAgencyQuery(
    { agency_id: props.agencyId, page, size: 10 },
    { refetchOnMountOrArgChange: true }
  );
  
  const navigate = useNavigate();
  const handleCancel = props.handlers?.cancel || (() => navigate('/admin/agencies'));
  const handleAddApi = props.handlers?.add?.api || (() => navigate(`${ROUTES.createApi}/${props.agencyId}`));
  
  const [currentTab, setCurrentTab] = useState(
    activeTabIndex(props.activeTab || "info")
  )

  useEffect(() => {
    navigate(`${ROUTES.adminAgencies}/${props.agencyId}/${TABS[currentTab]}`)
  }, [currentTab, navigate, props.agencyId])


  const handleChangeTab = (index: number) => {
    props.handlers?.changeTab(index);
    setCurrentTab(index);
  }

  return <TabBox
    contentTabs={[
      {
        icon: <IconInfoCircle {...tabIconProps} />,
        title: i18n.info.title,
        content: (
          <AgencyForm
            userRole="admin"
            type="update"
            agency={props.locationState}
            handleCancel={handleCancel}
          />
        ),
      },
      {
        icon: <IconBriefcase {...tabIconProps} />,
        title: i18n.clients.title,
        content: clients && (
          <ClientsList
            clientsResult={clients.result}
            handlePageChange={(page) => setPage(page)}
            agencyId={props.agencyId}
            refetch={refetch}
            isUninitialized={isUninitialized}
            setTab={(tab: ArrayElement<typeof TABS>) => handleChangeTab(activeTabIndex(tab))}
          />
        ),
      },
      {
        icon: <IconUserCircle {...tabIconProps} />,
        title: i18n.users.title,
        // content: <div>Пользователи агентства {agencyId}</div>,
        content: (
          <AgencyUsersList
            agencyId={props.agencyId}
            userRole="admin"
          />
        ),
      },
      {
        icon: <IconApi {...tabIconProps} />,
        title: "API",
        content: <ClientsApis agencyId={props.agencyId} handleAdd={handleAddApi} />,
      },
    ]}
    activeTab={currentTab}
    onChange={handleChangeTab}
  />}