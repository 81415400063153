import { useLocation } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useLocale } from "entities/i18n";

export default () => {
  const location = useLocation();
  const i18n = useLocale();

  return (
    <Grid container spacing={3} className="font-open-sans">
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="font-inter"
        >
          <Typography
            variant="subtitle2"
            color="dark"
            component="span"
            justifyContent="center"
            alignItems="center"
            className="font-inter"
            sx={{
              fontWeight: 700,
              fontSize: "36px",
            }}
          >
            {i18n.auth.emailConfirmation}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography
            variant="body2"
            color="dark"
            component="span"
            justifyContent="center"
            alignItems="center"
            className="font-inter"
            sx={{
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            {i18n.auth.emailConfirmationText["1_toEmail"]}
            {location.state.email + " " || ""}
            {i18n.auth.emailConfirmationText["2_wasSent"]}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography
            variant="body2"
            color="dark"
            component="span"
            justifyContent="center"
            alignItems="center"
            className="font-inter"
            sx={{
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            <RouterLink to="/" className="text-decoration-none">
              {i18n.auth.toMain}
            </RouterLink>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
