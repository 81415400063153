import { IconButton, Box, SvgIcon } from "@mui/material";

// const iconsStyles = "w-[18px] h-[18px]";

export interface IBtn {
  icon: React.ReactNode;
  onClick: () => void;
  className?: string;
}

type Props = {
  btns: IBtn[];
};

export const IconBtnsList = ({ btns }: Props) => {
  return (
    <Box className="flex flex-row justify-end">
      {btns.map((btn: IBtn, i: number) => {
        return (
          <IconButton key={i} onClick={btn.onClick}>
            <SvgIcon
              className={/* `${iconsStyles} */`${
                btn.className ? " " + btn.className : ""
              }`}
            >
              {btn.icon}
            </SvgIcon>
          </IconButton>
        );
      })}
    </Box>
  );
};
