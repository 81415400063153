import { baseApi } from "shared/baseApi";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
// import { SuperAdminApi } from "entities/superAdmin";
import {
  AdminSlice,
  // AdminApi
} from "entities/admin";
import {
  AuthSlice,
  // AuthApi
} from "entities/auth";
import { localeSlice } from "entities/i18n";
// import { ClientsApi } from "entities/clients";
// import { ConnectorsApi } from "entities/connectors";
// import { StatApi } from "entities/stats";
// import { AgencyUsersApi } from "entities/users";
// import { ExchangeApi } from "entities/exchange";
// import { MediaplanApi } from "entities/mediaplan";

const Store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,

    // [SuperAdminApi.reducerPath]: SuperAdminApi.reducer,
    // [AdminApi.reducerPath]: AdminApi.reducer,
    // [AuthApi.reducerPath]: AuthApi.reducer,
    // [ClientsApi.reducerPath]: ClientsApi.reducer,
    // [ConnectorsApi.reducerPath]: ConnectorsApi.reducer,
    // [StatApi.reducerPath]: StatApi.reducer,
    // [AgencyUsersApi.reducerPath]: AgencyUsersApi.reducer,
    // [ExchangeApi.reducerPath]: ExchangeApi.reducer,
    // [MediaplanApi.reducerPath]: MediaplanApi.reducer,

    auth: AuthSlice.reducer,
    i18n: localeSlice.reducer,
    admin: AdminSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
  // .concat(AuthApi.middleware)
  // .concat(AdminApi.middleware)
  // .concat(SuperAdminApi.middleware)
  // .concat(ClientsApi.middleware)
  // .concat(ConnectorsApi.middleware)
  // .concat(StatApi.middleware)
  // .concat(AgencyUsersApi.middleware)
  // .concat(ExchangeApi.middleware)
  // .concat(MediaplanApi.middleware),
});

setupListeners(Store.dispatch);

export type AppDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;

export default Store;
