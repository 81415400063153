import { useDispatch } from "react-redux";
import { Picker } from "shared/ui/components";
import { LocaleReducers, fetchLocale, localeSlice, useCurrentLocale } from "entities/i18n";
import { ThunkDispatch, AnyAction } from "@reduxjs/toolkit";

const LocalePicker = () => {
  const dispatch = useDispatch<ThunkDispatch<LocaleReducers, typeof fetchLocale, AnyAction>>();
  const currentLocale = useCurrentLocale();

  // Так как подгрузка локали вызывается в app/index.tsx, 
  // то здесь мы просто меняем локаль в конфиге.
  // Это сразу приводит к подгрузке локали в стор,
  // так как мы подписаны на изменение конфига в useFetchLocale
  const changeLocale = (locale: string) => {
    dispatch(localeSlice.actions.setLocaleInConfig(locale as localeName));
  };

  const options = ["eng", "rus"];

  return (
    <Picker
      options={options}
      defaultOption={options.indexOf(currentLocale)}
      handleChange={(locale) => changeLocale(locale)}
      yTranslateOverride="-0.6rem"
    />
  );
};

export default LocalePicker;
