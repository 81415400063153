import { createSlice } from "@reduxjs/toolkit";
import { Locale, defaultLocale } from "shared/assets/i18n";
import { fetchLocale } from "./fetchLocale";
import { Reducers } from "shared/types";

const initialState = {
  locale: defaultLocale.locale as Locale,
  config: {currentLocale: defaultLocale.name as localeName},
  isLoading: false,
  error: undefined as string | undefined,
}

export interface LocaleReducers extends Reducers {
  i18n: typeof initialState
}

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setLocaleInConfig: (state, action) => {
      state.config = {...state.config, currentLocale: action.payload};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocale.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchLocale.fulfilled, (state, action) => {
      state.isLoading = false
      state.locale = action.payload.translationData
    })
    builder.addCase(fetchLocale.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
});
