import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
import {
  // PUBLIC_API_URL,
  QUERY_MEDIAPLAN_CREATE,
  QUERY_MEDIAPLAN_DELETE,
  QUERY_MEDIAPLAN_GET,
  QUERY_MEDIAPLAN_GET_ROW,
  QUERY_MEDIAPLAN_PLAN_FACT_ADS_FILTER,
  QUERY_MEDIAPLAN_PLAN_FACT_CAMPAIGNS_FILTER,
  QUERY_MEDIAPLAN_UPDATE,
  QUERY_MEDIAPLAN_UPDATE_ROW,
  QUERY_MEDIAPLAN_UPLOAD,
  QUERY_PLAN_FACT_ADS,
  QUERY_PLAN_FACT_CAMPAIGNS,
} from "shared/constants";

import {
  RequestCreateMediaplan,
  RequestDeleteMediaplan,
  RequestGetMediaplan,
  RequestGetMediaplanRow,
  RequestPlanFactAds,
  RequestPlanFactMediaplan,
  RequestPlanFactMediaplanAdsFilter,
  RequestPlanFactMediaplanCampaignsFilter,
  RequestUpdateMediaplan,
  RequestUpdateMediaplanRow,
  RequestUploadMediaplan,
  ResponseCreateMediaplan,
  ResponseDeleteMediaplan,
  ResponseGetMediaplan,
  ResponseGetMediaplanRow,
  ResponsePlanFactAds,
  ResponsePlanFactMediaplan,
  ResponsePlanFactMediaplanAdsFilter,
  ResponsePlanFactMediaplanCampaignsFilter,
  ResponseUpdateMediaplan,
  ResponseUpdateMediaplanRow,
  ResponseUploadMediaplan,
} from "./mediaplanTypes";

// export const MediaplanApi = createApi({
export const MediaplanApi = baseApi.injectEndpoints({
  // reducerPath: "MediaplanApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders: (headers) => {
  //     const token = getMemory("serviseAuth");
  //     if (token) {
  //       headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //     }
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    // POST: upload, create
    uploadMediaplan: builder.mutation<
      ResponseUploadMediaplan,
      RequestUploadMediaplan
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        const request = {
          url: QUERY_MEDIAPLAN_UPLOAD,
          method: "POST",
          params: { ...data, file: undefined },
          body: formData,
          formData: true,
        };
        // console.log(request);
        return request;
      },
    }),
    createMediaplan: builder.mutation<
      ResponseCreateMediaplan,
      RequestCreateMediaplan
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_CREATE,
        method: "POST",
        body: { ...data },
      }),
    }),
    // GET: get, plan_fact
    getMediaplan: builder.query<ResponseGetMediaplan, RequestGetMediaplan>({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_GET,
        method: "GET",
        params: { ...data },
      }),
    }),

    getMediaplanRow: builder.query<
      ResponseGetMediaplanRow,
      RequestGetMediaplanRow
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_GET_ROW,
        method: "GET",
        params: { ...data },
      }),
    }),

    planFactMediaplan: builder.query<
      ResponsePlanFactMediaplan,
      RequestPlanFactMediaplan
    >({
      query: (data) => ({
        url: QUERY_PLAN_FACT_CAMPAIGNS,
        method: "GET",
        params: { ...data },
      }),
    }),

    planFactAds: builder.query<ResponsePlanFactAds, RequestPlanFactAds>({
      query: (data) => ({
        url: QUERY_PLAN_FACT_ADS,
        method: "GET",
        params: { ...data },
      }),
    }),

    planFactMediaplanCampaignsFilter: builder.query<
      ResponsePlanFactMediaplanCampaignsFilter,
      RequestPlanFactMediaplanCampaignsFilter
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_PLAN_FACT_CAMPAIGNS_FILTER,
        method: "GET",
        params: { ...data },
      }),
    }),

    planFactMediaplanAdsFilter: builder.query<
      ResponsePlanFactMediaplanAdsFilter,
      RequestPlanFactMediaplanAdsFilter
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_PLAN_FACT_ADS_FILTER,
        method: "GET",
        params: { ...data },
      }),
    }),

    // PUT: update
    updateMediaplan: builder.mutation<
      ResponseUpdateMediaplan,
      RequestUpdateMediaplan
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_UPDATE,
        method: "PUT",
        body: { ...data },
      }),
    }),

    updateMediaplanRow: builder.mutation<
      ResponseUpdateMediaplanRow,
      RequestUpdateMediaplanRow
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_UPDATE_ROW,
        method: "PUT",
        params: { mediaplan_id: data.id },
        body: { ...data },
      }),
    }),

    // DELETE: delete
    deleteMediaplan: builder.mutation<
      ResponseDeleteMediaplan,
      RequestDeleteMediaplan
    >({
      query: (data) => ({
        url: QUERY_MEDIAPLAN_DELETE,
        method: "DELETE",
        params: { ...data },
      }),
    }),
  }),
});

export const {
  //hooks for all queries (excluding lazy)
  useGetMediaplanQuery,
  useGetMediaplanRowQuery,
  usePlanFactMediaplanQuery,
  usePlanFactMediaplanCampaignsFilterQuery,
  usePlanFactMediaplanAdsFilterQuery,
  usePlanFactAdsQuery,

  //hooks for all mutations
  useUpdateMediaplanMutation,
  useUpdateMediaplanRowMutation,
  useDeleteMediaplanMutation,
  useUploadMediaplanMutation,
  useCreateMediaplanMutation,

  //hooks for lazy queries
  useLazyGetMediaplanQuery,
  useLazyPlanFactMediaplanQuery,
  useLazyGetMediaplanRowQuery,
} = MediaplanApi;
