import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GlobalState } from "shared/types";
import { PUBLIC_API_URL } from "shared/constants";

const endpointsWithoutAuth = [
  "accessToken",
  "resetPassword",
  "verifyToken",
  "changePasswordWithToken",
  "agencyDirectorRegister",
  "refreshToken",
];

export const baseApi = createApi({
  reducerPath: "baseApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: PUBLIC_API_URL,
    prepareHeaders(headers, { getState, endpoint }) {
      if (endpointsWithoutAuth.includes(endpoint)) {
        return headers;
      }

      const state = getState() as GlobalState;
      headers.append("Authorization", `Bearer ${state.auth.accessToken}`);
    },
  }),
  endpoints: () => ({}),
});
