import { MainLayout, ProfileMenu } from "widgets";
import { LigthRoutes } from "shared/types";
import { AuthGuard } from "entities/auth/ui";
import { LocalePicker } from "features/i18n";

import Profile from "pages/profile";
import Home from "pages/home";
import Dashboard, { CampDashboard } from "pages/dashboard";
import { Mediaplan } from "pages/mediaplan";
import { AgencyList, CreateUser, RegisterAgency } from "pages/admin";
import { AgencyHome, Client, CreateClient } from "pages/clients";
import { AdminAgencyPage } from "pages/adminAgencyPage";
import { ROUTES } from "shared/constants";
import { CallbackApiPage, CreateApi } from "pages/apiPage";
import { AuthRouter } from "features/auth";
import { SystemAdminList } from "pages/superAdmin";
import { AgencyRouter } from "entities/admin";
import { UserProfile } from "pages/admin";

const HomePage = {
  ROOT: { component: SystemAdminList },
  MAIN_ADMIN: { component: SystemAdminList },
  SYSTEM_ADMIN: { component: AgencyList },
  AGENCY_DIRECTOR: {
    component: AgencyRouter,
    props: {
      agency: AgencyHome as React.ComponentType,
      agencyProps: {
        activeTab: "clients",
      },
      noAgency: RegisterAgency as React.ComponentType,
    } as React.ComponentProps<typeof AgencyRouter>,
  },
  MANAGER: { component: AgencyHome, props: { activeTab: "clients" } },
  NONE: { component: Home },
};

const MainRoutes: LigthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout
            headerWidget={
              <div className="flex flex-row gap-8">
                <LocalePicker />
                <ProfileMenu />
              </div>
            }
          />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <AuthRouter pages={HomePage} />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/dashboard",
          element: <Dashboard userRole="director" />,
        },
        {
          path: "/dashboard/:client",
          element: <Dashboard userRole="director" />,
        },
        {
          path: "/dashboard/:client/:campaign",
          element: <CampDashboard userRole="director" />,
        },
        {
          path: "/dashboard/:agency/:client/:campaign",
          element: <CampDashboard userRole="admin" />,
        },
        {
          path: "/agency/register",
          element: <RegisterAgency />,
        },
        {
          path: "/clients",
          element: <AgencyHome activeTab="clients" />,
        },
        //! temporary commented out
        {
          path: "/clients/create",
          element: <CreateClient />,
        },
        /*         {
          path: "/clients/:id",
          element: <EditClient />,
        }, */
        {
          path: "/clients/:clientId",
          element: <Client userRole="director" activeTab="info" />,
        },
        {
          path: "/clients/:clientId/info",
          element: <Client userRole="director" activeTab="info" />,
        },
        {
          path: "/clients/:clientId/api",
          element: <Client userRole="director" activeTab="api" />,
        },
        {
          path: "/clients/:clientId/brands",
          element: <Client userRole="director" activeTab="brands" />,
        },
        {
          path: "/users",
          element: <AgencyHome activeTab="users" />,
        },
        {
          path: "/users/create",
          element: <CreateUser userRole="director" />,
        },
        {
          path: "/users/manage",
          element: <UserProfile userRole="director" />,
        },
        {
          path: "/currencies",
          element: <AgencyHome activeTab="currencies" />,
        },
        // {
        //   path: "/admin/agencies",
        //   element: <AdminAgenciesPage />,
        // },
        {
          path: `${ROUTES.adminAgency}/:id`,
          element: <AdminAgencyPage />,
        },
        {
          // path: `${ROUTES.createApi}/:clientId`,
          path: `${ROUTES.createApi}/:agencyId/:clientId?`,
          element: <CreateApi />,
        },
        // {
        //   // path: `${ROUTES.createApi}/:clientId`,
        //   path: `${ROUTES.ya_callback}`,
        //   element: <CallbackApiPage />,
        // },
        {
          // path: `${ROUTES.createApi}/:clientId`,
          path: `${ROUTES.callback_page}/:callbackType`,
          element: <CallbackApiPage />,
        },
        {
          path: `${ROUTES.mediaplan}`,
          element: <Mediaplan action="create" />,
        },
        {
          path: `${ROUTES.mediaplan}/:id`,
          element: <Mediaplan action="edit" />,
        },
      ],
    },
  ],
};

export default MainRoutes;
