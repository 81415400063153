import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TabPanel from "./TabPanel";
import { a11yProps, TabIconConverter } from "./utils";

const TabBox = ({ contentTabs, ariaLabel, activeTab, onChange}: TabBoxProps) => {
  const [value, setValue] = React.useState(activeTab || 0);

  useEffect(() => {
    setValue(activeTab || 0);
  }, [activeTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="flex flex-col">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={ariaLabel || "Tab box"}
          className="min-h-0"
          TabIndicatorProps={{className: "bg-blue-accent"}}
        >
          {contentTabs.map((tab, index) => (
            <Tab
              icon={<TabIconConverter icon={tab.icon} />}
              label={tab.title}
              {...a11yProps(index)}
              key={index}
              iconPosition="start"
              className="p-2.5 pr-4 text-sm normal-case leading-none font-open-sans text-black font-400 min-h-0"
            />
          ))}
        </Tabs>
      </Box>
      {contentTabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabBox;
