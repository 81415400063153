import { CircularProgress } from "@mui/material";
import { CommonLayout } from "shared/ui";
import { useCreateCredentialsMutation } from "entities/connectors";
import { useEffect, useRef, useState } from "react";
import { getInfoFromURL } from "./lib";
import { useAuthSelector } from "entities/auth";

const i18nLoc = {
  createApi: "Create API",
};

export const CallbackApiPage = () => {
  //redirect to clients apis page after getting response from server in n seconds
  const [query, queryState] = useCreateCredentialsMutation();
  const [resultMsg, setresultMsg] = useState("");
  const alreadyCreated = useRef(false); //needs to prevent double connector creating in strict mode

  //yandex callback
  //https://app.danametrics.ai/api/v1/ya_callback/#state=17%7C%7C3%7C%7Cdata.metrics%7C%7Cyandex%20test&access_token=y0_AgAAAABxtCTIAAiNpQAAAADwPF2IYE-KQoDuTEGJcmr9HxMi2NFJfto&token_type=bearer&expires_in=31535916

  //google callback
  //https://dev.danametrics.ai/api/v1/google_callback/?state=17%7C%7C3%7C%7CGoogle%20Advertisment%7C%7Cgooggle&code=4/0AfJohXnZ9seOQvSLZfSNirJZnKV-SRrWqetmFJ4WjhZMFmCMrMJ4ZodPUo5T3XmWctbL8A&scope=https://www.googleapis.com/auth/adwords
  //

  //fb callback
  //https://dev.danametrics.ai/api/v1/fb_callback/?code=AQBzAjPMWtvzcK-r7OdxkjtQHWbzSrXbUL6gXlBBC3GU-od55B8ApSpA2hniBMnqFWJuQJYuVejCr3sPhP7wxC3htPHGIYkJ7dUZasKtLCVlG33LceOeXgwzEEq7-080bpgBeuJeEor2AfQcnVwhS4acXQZI_F17i9aJRusf3uYISYiTdrYzFk6VtvX4i9dwbK65bInJ4zwU3AycDYXFG7zZQIYee4_TLJxTqvuCw5fhKE2dbVueob7ZFOmJGFe2PGf6sEqUI05pvBJsInE96oXBcChD_AuYBPVxTHaROj84Z_82aYjFGsdLCZiqB_Gi9CPGl6OQylqge4hxclyQhFn8RKd6lJUv-L2mTYR0Ia2nRq5vLPWy4cO4uszSu9atgQA&state=39%7C%7C6385560281480463#_=_

  //vk
  //  //https://dev.danametrics.ai/api/v1/vk_callback/?code=436086b889486b4517

  //tt
  //https://dev.danametrics.ai/api/v1/tt_callback/?auth_code=9816c5697ca2996811d2264e467dbacbb9ad1d2c&code=9816c5697ca2996811d2264e467dbacbb9ad1d2c&state=somenewtext

  //google
  //https://dev.danametrics.ai/api/v1/google_callback/?state=someinfo&code=4/0AfJohXmkTTio7mhQyUmMEIkEgE_8WNAqwCNXpXn8hUSwYo8AvfHXRCGOTzidQaZ1ldbABQ&scope=https://www.googleapis.com/auth/adwords

  const requestBodyCache = useRef<any>();

  useEffect(() => {
    const fetchNewApi = async () => {
      if (queryState.isLoading || !queryState.isUninitialized) return;
      const {
        access_token,
        refresh_token,
        expires_in,
        connector_id,
        login,
        code,
        google_manager_id,
      } = getInfoFromURL(location);

      if (!requestBodyCache.current) {
        requestBodyCache.current = {
          connector_id,
          refresh_token,
          login,
          code,
          access_token,
          expires_in: expires_in || 0,
          google_manager_id,
        };
      }

      const res: any = await query(requestBodyCache.current);

      setresultMsg(res.data?.message);
      alreadyCreated.current = true;
    };

    fetchNewApi();
  }, [query]);

  return (
    <CommonLayout
      title={
        <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
          {i18nLoc.createApi}
        </h1>
      }
    >
      <div>{queryState.isLoading ? <CircularProgress /> : resultMsg}</div>
    </CommonLayout>
  );
};
