import { adsSystems } from "shared/constants";

export const getAdsSystemTypeFromPath = (
  pathname: string,
): adsSystems | undefined => {
  if (/ya_callback/.test(pathname)) return adsSystems.yandex;
  if (/google_callback/.test(pathname)) return adsSystems.google;
  if (/fb_callback/.test(pathname)) return adsSystems.fb;
  if (/vk_callback/.test(pathname)) return adsSystems.vk;
  if (/tt_callback/.test(pathname)) return adsSystems.tiktok;

  return;
};

function getInfoFromStr(str: string, type?: adsSystems) {
  const paramsVals = str.split("&");
  const result: {
    access_token?: string;
    refresh_token?: string;
    expires_in?: number;
    connector_id?: number;
    login?: string;
    code?: string;
    google_manager_id?: string;
  } = {};

  paramsVals.forEach((el) => {
    const [param, val] = el.split("=");

    if (param === "state") {
      // result.state = val;
      const stateEls = val.split("||");
      result.connector_id = parseInt(stateEls[0]);
      result.login = stateEls[1];

      if (stateEls.length >= 3) {
        result.google_manager_id = stateEls[2];
      }

      // check if name contains || and fix it
      // let name = stateEls[3];

      // if (stateEls.length > 4) {
      //   const arr = stateEls.slice(3);
      //   name = arr.join("||");
      // }
      //
      // result.name = name;
      return;
    }

    if (param === "access_token") {
      result.access_token = val;
      return;
    }

    //WARN: check it for fb, vk, google, tiktok
    if (param === "code" && type !== adsSystems.tiktok) {
      result.code = val;
      return;
    }

    if (param === "auth_code") {
      result.code = val;
      return;
    }

    if (param === "expires_in") {
      result.expires_in = parseInt(val);
    }
  });

  return result as {
    access_token?: string;
    refresh_token?: string;
    expires_in?: number;
    connector_id: number;
    login?: string;
    code?: string;
    google_manager_id?: string;
  };
}

export const getInfoFromHash = (
  hash: string,
  type?: adsSystems,
): {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  connector_id: number;
  login?: string;
  code?: string;
  google_manager_id?: string;
} => {
  const decoded = decodeURI(hash).replace("#", "");

  return getInfoFromStr(decoded, type);
};

export const getInfoFromQueryStr = (
  queryStr: string,
  type?: adsSystems,
): {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  connector_id: number;
  login?: string;
  code?: string;
  google_manager_id?: string;
} => {
  const decoded = decodeURI(queryStr).replace("?", "");

  return getInfoFromStr(decoded, type);
};

export const getInfoFromURL = (loc: Location) => {
  const { pathname, hash, search } = loc;

  //VK
  //https://oauth.vk.com/blank.html#access_token=vk1.a.XRHYlBQrApU8Mf0MA9GGtPltdmza6iwmw9n4mce3UKmO48pWBfbeVEiPBdjhAbo3e1fUlGviScKAExvNL4vzfmgQZmUY7nMrjdT5iOZ3tRGw4c8G3ekmc9U0UvKVoNk612-Jv8PY4irVAfbxhvZSyxETQyk7e6AU8nh03tOQ9VL4CYUj-_fz8u6pCVBW4kyXgYOngTFvc0ZUW7J8KC2jZg&user_id=7971070&state=somenewstaff

  const type = getAdsSystemTypeFromPath(pathname);
  const info =
    type === adsSystems.yandex || type === adsSystems.vk
      ? getInfoFromHash(hash, type)
      : getInfoFromQueryStr(search, type);

  return {
    type,
    ...info,
  };
};
