import { RootState } from "app/store";
import { AdminSlice } from "./adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLazyReadAgencyQuery } from "./adminApi";
import { useEffect, useMemo } from "react";
import { useSession } from "entities/auth";
import { User } from "shared/types";

import { useAuthSelector } from "entities/auth";

const generateAgencyArgs = (user: User | null) => {
  return {
    role: user?.roles.find((e) =>
      e.code === "AGENCY_DIRECTOR" || e.code === "MANAGER"
    )?.code as "AGENCY_DIRECTOR" | "MANAGER" | undefined,
  };
};

export const useAgency = () => {
  const dispatch = useDispatch();
  const agencyId = useSelector((state: RootState) => state.admin.agencyId);
  const auth = useAuthSelector();
  const user = auth.user;

  const [readAgency, { data: agency, isUninitialized }] =
    useLazyReadAgencyQuery();

  useEffect(() => {
    const readAgencyArgs = generateAgencyArgs(user);
    readAgencyArgs.role !== null && readAgency({
      role: user?.roles.find((e) => e.code === "AGENCY_DIRECTOR")
        ? "AGENCY_DIRECTOR"
        : "MANAGER",
    });
  }, [auth]);

  useEffect(() => {
    const readAgencyArgs = generateAgencyArgs(user);
    agency?.status_code !== 0 && !isUninitialized && agencyId !== null &&
      setTimeout(readAgency, 1000, readAgencyArgs);
  }, [agency]);

  useEffect(() => {
    const setAgency = (agencyId: number) =>
      dispatch(AdminSlice.actions.setAgency(agencyId));
    if (agency?.result.id) {
      console.log("Setting agency", agency.result.id);
      setAgency(agency.result.id);
    }
  }, [agency]);

  return {
    agencyId,
    agencyResponse: agency,
  };
};
