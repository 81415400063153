import { InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconCalendar } from "@tabler/icons-react";
import ru from "date-fns/locale/ru";
  
const DateInput = (props: {onChange: (date: Date | null) => void, defaultDate: Date | null}) => {
  const { palette } = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <DesktopDatePicker
        value={props.defaultDate}
        sx={{
          "& .MuiInputBase-root": {
            background: "none !important",
            pl: 2,
            "&:before": { bb: "none !important" },
            "& input": {
              py: 1,
            },
          },
        }}
        slots={{
          // inputAdornment: CalendarAdornment,
          openPickerIcon: IconCalendar
        }}
        slotProps={
          {
            inputAdornment: {
              position: "start",
              color: palette.grey[400],
            },
            openPickerIcon: {
              // size={22} strokeWidth={1.4} color={palette.grey[400]}
              size: 22,
              strokeWidth: 1.4,
              color: palette.grey[400],
            },
          }
        }
        onChange={props.onChange}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
