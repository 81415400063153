import * as metricsLib from "shared/lib/metrics";
import * as datesLib from "shared/lib/dates";
import { Link } from "react-router-dom";
import { defaultFallbackCurrency } from "shared/constants";

export const estimateBudgetRecommendedDaily = ({
  budgetPlan,
  budgetFact,
  startDate,
  endDate,
  numberCallback,
}: {
  budgetPlan?: number | null;
  budgetFact?: number | null;
  startDate: string | Date;
  endDate: string | Date;
  numberCallback?: (value: number) => number;
}) => {
  if (!budgetPlan || !budgetFact) return null;

  const budgetLeft = budgetPlan - budgetFact;
  if (budgetLeft <= 0) return "Бюджет исчерпан";

  const distance = datesLib.getDistanceBetweenDates(startDate, endDate);
  const daysLeft = distance - datesLib.getDistanceFromYesterday(startDate);
  const result = Math.round(budgetLeft / daysLeft);

  return result >= 0
    ? metricsLib.prettifyNumber(
      numberCallback ? numberCallback(result) : result,
      2,
    )
    : "Кампания проведена";
};

export const getCampaignLink = (
  userRole: "admin" | "director",
  campaign: PlanFactEntry["campaign"],
  clientId: string,
  agencyId?: string,
) => {
  return (
    <Link
      to={`/dashboard/${userRole === "admin" ? agencyId + "/" : ""
      }${clientId}/${campaign.id}`}
      className="text-black"
      state={{ campaign }}
      title={campaign.name}
    >
      {campaign.name}
    </Link>
  );
};

const transformCurrency = (
  value: number,
  from: Currency,
  to: Currency,
  rates: Record<Currency, number>,
) => (rates[to] / rates[from]) * value;

export const handleChangeCurrency = (
  currency: Currency,
  rowImmutable: PlanFactEntry,
  setters: SettersType,
  rates: Record<Currency, number>,
) => {
  setters["main"]({
    budget: {
      plan: transformCurrency(
        rowImmutable.budget.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.budget.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    kpi_cpm: {
      plan: transformCurrency(
        rowImmutable.kpi_cpm.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.kpi_cpm.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    kpi_cpu: {
      plan: transformCurrency(
        rowImmutable.kpi_cpu.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.kpi_cpu.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    kpi_cpc: {
      plan: transformCurrency(
        rowImmutable.kpi_cpc.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.kpi_cpc.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    kpi_cpa: {
      plan: transformCurrency(
        rowImmutable.kpi_cpa.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.kpi_cpa.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    kpi_cpv: {
      plan: transformCurrency(
        rowImmutable.kpi_cpv.plan as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
      fact: transformCurrency(
        rowImmutable.kpi_cpv.fact as number,
        (rowImmutable.currency
          ? rowImmutable.currency
          : defaultFallbackCurrency) as Currency,
        currency,
        rates,
      ),
    },
    currency,
  });
};

/**
 * Функция для определения цвета ячейки в зависимости от того, насколько
 * фактическое значение метрики *сейчас* отличается от планового значения *сейчас*.
 * @param daysPassed Количество дней, прошедших с начала кампании.
 * @param daysTotal Количество дней, запланированных для кампании.
 * @param metricPlan Плановое значение метрики.
 * @param metricFact Фактическое значение метрики.
 * @returns Цвет ячейки hex. Или null, если цвет не нужен.
 */
export const colorCodeRelativeCompletion = (
  daysPassed: number,
  daysTotal: number,
  metricPlan: number,
  metricFact: number,
): "#F04134" | "#00A04D" | null => {
  if (daysPassed === 0 && daysTotal === 0) return null;
  const metricCompletion = metricFact / metricPlan;
  const daysCompletion = daysPassed / daysTotal;
  const cappedDaysCompletion = Math.min(daysCompletion, 1);
  if (cappedDaysCompletion - metricCompletion > 0.1) return "#F04134";
  else if (cappedDaysCompletion - metricCompletion < -0.1) return "#00A04D";
  else return null;
};

/**
 * Функция для определения цвета ячейки в зависимости от того, насколько
 * фактическое значение метрики отличается от планового значения.
 * Если фактическое значение метрики больше планового на >10%, то цвет будет красным.
 * Если фактическое значение метрики меньше планового на >10%, то цвет будет зеленым.
 * @param metricPlan Плановое значение метрики.
 * @param metricFact Фактическое значение метрики.
 * @param moreIsBetter Если true, то чем больше фактическое значение метрики, тем лучше.
 * @returns Цвет ячейки hex. Или null, если цвет не нужен.
 */
export const colorCodeCompletion = (
  metricPlan: number,
  metricFact: number,
  moreIsBetter?: boolean,
): "#F04134" | "#00A04D" | null => {
  if (moreIsBetter) {
    if (metricFact - metricPlan > 0.1) return "#00A04D";
    else if (metricFact - metricPlan < -0.1) return "#F04134";
    else return null;
  } else {
    if (metricPlan - metricFact > 0.1) return "#00A04D";
    else if (metricPlan - metricFact < -0.1) return "#F04134";
    else return null;
  }
};

export const getCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "KZT":
      return "₸";
    case "RUB":
      return "₽";
    default:
      return "";
  }
};
