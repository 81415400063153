import React, {
  ChangeEventHandler,
  ComponentProps,
  SyntheticEvent,
} from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { Autocomplete, InputAdornment, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { createElement } from "react";
import { Icon } from "@tabler/icons-react";
import { errorSx, outlinedInputSx } from "shared/constants";

type TextInputProps = {
  id: string;
  label?: React.ReactNode;
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  type?: "text" | "password" | "number";
  icon?: React.ReactNode;
  className?: string;
  sx?: any;
  autocomplete?: {
    freeSolo?: boolean;
    options: any[];
    getLabel?: (option: any) => string;
    renderOption?: (props: any, option: any) => React.ReactNode;
    onChange?: (event: SyntheticEvent, value: any) => void;
    defaultValue?: any;
    value?: string;
  };
  autoFocus?: boolean;
};

export const TextInput = (props: TextInputProps) => {
  const textFieldProps = {
    hiddenLabel: true,
    id: props.id,
    value: props.value,
    onChange: props.onChange,
    placeholder: props.placeholder,
    disabled: props.disabled,
    fullWidth: props.fullWidth,
    multiline: props.multiline,
    rows: props.rows,
    type: props.type,
    onBlur: props.onBlur,
    onPaste: props.onPaste,
    helperText: props.helperText,
    autoFocus: props.autoFocus,
    variant: "filled" as TextFieldVariants,
    sx: {
      "& .MuiFilledInput-root": {
        background: "white !important",
        border: "none",
        borderRadius: "0.25rem",
        padding: 0,
        "&:after, &:before": {
          border: "none",
        },
      },
      ...props.sx,
    },
    size: "small" as "small" | "medium",
    InputProps: {
      startAdornment: props.icon && (
        <InputAdornment position="start" sx={{ pl: ".5rem" }}>
          {props.icon}
        </InputAdornment>
      ),
    },
  };

  return (
    <div className={`${props.className} font-open-sans`}>
      {props.label && (
        <InputLabel
          shrink
          htmlFor={props.id}
          className="text-lg font-bold text-black"
        >
          {props.label}
        </InputLabel>
      )}
      {/* Если есть в пропсах autocomplete, то оборачиваем TextField в Autocomplete */}
      {props.autocomplete
        ? (
          <Autocomplete
            freeSolo={props.autocomplete.freeSolo === undefined
              ? true
              : props.autocomplete.freeSolo}
            autoComplete
            autoHighlight
            id="suggestion"
            size="small"
            options={props.autocomplete.options}
            getOptionLabel={props.autocomplete.getLabel || ((option) => option)}
            renderInput={/* (params) => field(params) */ (params) => (
              <TextField {...textFieldProps} {...params} />
            )}
            value={props.autocomplete.value}
            renderOption={props.autocomplete.renderOption}
            defaultValue={props.autocomplete.defaultValue}
            onChange={props.autocomplete.onChange}
            slotProps={{
              popper: {
                sx: {
                  minWidth: "15rem",
                },
                placement: "bottom-start",
              },
            }}
          />
        )
        : <TextField {...textFieldProps} />}
    </div>
  );
};

export const CommonTextInput = (
  props: {
    name: string;
    placeholder: string;
    icon: Icon;
    onChange: ChangeEventHandler<HTMLInputElement>;
    props?: Partial<ComponentProps<typeof TextInput>>;
  },
) => {
  const { palette } = useTheme();
  return (
    <TextInput
      fullWidth
      id={props.name}
      placeholder={props.placeholder}
      icon={createElement(props.icon, {
        size: 16,
        color: palette.grey[400],
      })}
      className="w-full"
      sx={{ ...outlinedInputSx(palette), ...errorSx }}
      onChange={props.onChange}
      {...props.props}
    />
  );
};

export default TextInput;
