import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export const FirstLogin = ({ loginRole }: {loginRole: "agency-director" | "system-admin" | "agency-user"}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const token = location.pathname.split("/").slice(2, 3).toString();

  const message = useRef("");

  let whereTo: string;
  switch (loginRole) {
    case "agency-director": whereTo = "/auth"; break;
    case "agency-user": whereTo = "/auth"; break;
    case "system-admin": whereTo = "/auth/system-admin"; break;
    default: whereTo = "/auth";
  }

  useEffect(() => {
    if (token) {
      dispatch({ type: "auth/addActivationToken", payload: token });
      navigate(whereTo, { replace: true });
      message.current = "token is set. you'll be redirected to login page";
    }
    else message.current = "token not provided. no redirect for you. go away!";
  }, [token, dispatch, navigate]);

  return <span>{message.current.toString()}</span>;
}

export default FirstLogin;