import { InputAdornment } from "@mui/material";
import MuiIconButton from "@mui/material/IconButton";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

interface InputAdornmentProps extends React.InputHTMLAttributes<any> {
  toogle: boolean
}

export default (props: InputAdornmentProps) => {
  return (<InputAdornment position="start">
    <MuiIconButton
      aria-label="toggle password visibility"
      onClick={props.onClick}
      edge="start"
      color="secondary"
    >
      {props.toogle ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    </MuiIconButton>
  </InputAdornment>
  );
}