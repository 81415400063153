import {
  ComponentProps,
  useEffect,
  useState,
} from "react";
import { Button } from "@mui/material";

import {
  IconChartLine,
  IconCoins,
  IconPercentage,
  IconSearch,
} from "@tabler/icons-react";

import { Dialog } from "shared/ui/components";
import { DateInput } from "shared/ui/form";
import { useLocale } from "entities/i18n";

import {
  useCreateMediaplanMutation,
  useUpdateMediaplanRowMutation,
} from "entities/mediaplan";

import { Formik } from "formik";
import { useAgency } from "entities/admin";
import { useParams } from "react-router-dom";

import * as datesLib from "shared/lib/dates";
import {
  getInitialValues,
  handleSubmitCampaign,
  validationSchema,
} from "./models/campaignDialogUtils";

import { CommonTextInput, TextFieldWrapper } from "shared/ui/form";

export const CommonButton = (
  props: {
    variant: "contained" | "outlined";
    onClick: () => void;
    children: React.ReactNode;
    props?: ComponentProps<typeof Button>;
  },
) => {
  return (
    <Button
      disableElevation
      variant={props.variant}
      color="primary"
      size="large"
      className={props.variant === "contained"
        ? "text-white normal-case h-10"
        : "text-primary normal-case h-10"}
      onClick={props.onClick}
      {...props.props}
    >
      {props.children}
    </Button>
  );
};

type AutocompleteConfig = {
  options: any[];
  freeSolo?: boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (props: any, option: any) => React.ReactNode;
};

export const CampaignDialog = (
  props: {
    toggler: () => void;
    mode: "add" | "edit";
    campaign: MediaplanRow | null;
    mediaplanId: number | null;
    mediaplanName: string | null;
    autocomplete: {
      cabinet: AutocompleteConfig;
      brand: AutocompleteConfig;
      status: AutocompleteConfig;
      purchaseUnit: AutocompleteConfig;
    };
    isUninitialized: boolean;
    refetch: () => void;
  },
) => {
  const { dashboard: i18n } = useLocale();

  const [currency, setCurrency] = useState<Currency>(
    props.campaign?.currency || "USD",
  );

  const { agencyId } = useAgency();
  const { client: clientId } = useParams();

  const [createMediaplan, createMediaplanState] = useCreateMediaplanMutation();
  const [updateMediaplanRow, updateMediaplanRowState] =
    useUpdateMediaplanRowMutation();

  useEffect(() => {
    const postAddRoutine = () => {
      !props.isUninitialized && props.refetch();
      props.toggler();
    };

    createMediaplanState.data && createMediaplanState.data.status_code === 0 &&
      setTimeout(postAddRoutine, 1000);
  }, [createMediaplanState.data]);

  useEffect(() => {
    const postEditRoutine = () => {
      !props.isUninitialized && props.refetch();
      props.toggler();
    };

    updateMediaplanRowState.data &&
      updateMediaplanRowState.data.status_code === 0 &&
      setTimeout(postEditRoutine, 1000);
  }, [updateMediaplanRowState.data]);

  const initialValues = getInitialValues(props.mode, props.campaign);

  const handleSubmit = handleSubmitCampaign(
    props.mode,
    props.campaign,
    currency,
    createMediaplan,
    updateMediaplanRow,
    props.mediaplanId,
    agencyId,
    clientId,
    props.mediaplanName === null ? undefined : props.mediaplanName,
  );

  return (
    <Dialog toggler={props.toggler}>
      <h1>
        {props.mode === "add"
          ? "Новая рекламная кампания"
          : "Редактирование рекламной кампании"}
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(
          {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          },
        ) => {
          const formikEvents = {
            onChange: handleChange,
            onBlur: handleBlur,
          };
          const formikErrors = (name: keyof typeof initialValues) => ({
            // error: touched[name] && Boolean(errors[name]),
            helperText: touched[name] ? errors[name] : undefined,
          });
          const formikValues = (name: keyof typeof initialValues) => ({
            value: values[name] ?? "",
          });

          return (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row gap-8 w-[70rem] my-4">
                <TextFieldWrapper label={i18n.cabinet} className="w-1/4">
                  <CommonTextInput
                    name={"cabinet"}
                    placeholder={i18n.cabinet}
                    icon={IconSearch}
                    onChange={(e) => console.log(e.target.value)}
                    props={{
                      ...formikEvents,
                      ...formikErrors("cabinet"),
                      ...formikValues("cabinet"),
                      autocomplete: {
                        ...props.autocomplete.cabinet,
                        defaultValue: props.campaign?.ad_cabinet,
                        onChange: (e, value) => handleChange("cabinet")(value),
                      },
                    }}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper label={i18n.brand} className="w-1/4">
                  <CommonTextInput
                    name={"brand"}
                    placeholder={i18n.brand}
                    icon={IconSearch}
                    onChange={(e) => console.log(e.target.value)}
                    props={{
                      ...formikEvents,
                      ...formikErrors("brand"),
                      ...formikValues("brand"),
                      autocomplete: {
                        ...props.autocomplete.brand,
                        defaultValue: props.campaign?.brand,
                        onChange: (e, value) => handleChange("brand")(value),
                      },
                    }}
                  />
                </TextFieldWrapper>
                <div className="flex flex-row gap-4 w-1/2">
                  <TextFieldWrapper label={i18n.start}>
                    <DateInput
                      onChange={(date) => {
                        date &&
                          handleChange("startDate")(
                            datesLib.formatDate(date, "yyyy-MM-dd"),
                          );
                      }}
                      defaultDate={props.campaign
                        ? new Date(props.campaign.date_from)
                        : new Date()}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.finish}>
                    <DateInput
                      onChange={(date) => {
                        date &&
                          handleChange("finishDate")(
                            datesLib.formatDate(date, "yyyy-MM-dd"),
                          );
                      }}
                      defaultDate={props.campaign?.date_to
                        ? new Date(props.campaign?.date_to)
                        : new Date()}
                    />
                  </TextFieldWrapper>
                </div>
              </div>
              <div className="flex flex-row gap-8 w-[70rem] my-4">
                <TextFieldWrapper label={i18n.campaign} className="w-1/4">
                  <CommonTextInput
                    name={"campaignName"}
                    placeholder={i18n.campaignName}
                    icon={IconSearch}
                    onChange={(e) => console.log(e.target.value)}
                    props={{
                      ...formikEvents,
                      ...formikErrors("campaignName"),
                      ...formikValues("campaignName"),
                    }}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper label={i18n.status} className="w-1/4">
                  <CommonTextInput
                    name={"status"}
                    placeholder={i18n.status}
                    icon={IconSearch}
                    onChange={(e) => console.log(e.target.value)}
                    props={{
                      ...formikEvents,
                      ...formikErrors("status"),
                      ...formikValues("status"),
                      autocomplete: {
                        ...props.autocomplete.status,
                        defaultValue: props.campaign?.status,
                        onChange: (e, value) => handleChange("status")(value),
                      },
                    }}
                  />
                </TextFieldWrapper>
                <div className="flex flex-row items-end gap-4 w-1/2">
                  <TextFieldWrapper label={i18n.budget}>
                    <CommonTextInput
                      name={"budget"}
                      placeholder={i18n.budget}
                      icon={IconSearch}
                      onChange={(e) => console.log(e.target.value)}
                      props={{
                        ...formikEvents,
                        ...formikErrors("budget"),
                        ...formikValues("budget"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <div className="flex flex-row gap-2">
                    <CommonButton
                      variant={currency === "KZT" ? "contained" : "outlined"}
                      onClick={() => setCurrency("KZT")}
                      props={{
                        sx: { width: "3.5rem", px: 0, minWidth: "3.5rem" },
                      }}
                    >
                      KZT
                    </CommonButton>
                    <CommonButton
                      variant={currency === "RUB" ? "contained" : "outlined"}
                      onClick={() => setCurrency("RUB")}
                      props={{
                        sx: { width: "3.5rem", px: 0, minWidth: "3.5rem" },
                      }}
                    >
                      RUB
                    </CommonButton>
                    <CommonButton
                      variant={currency === "USD" ? "contained" : "outlined"}
                      onClick={() => setCurrency("USD")}
                      props={{
                        sx: { width: "3.5rem", px: 0, minWidth: "3.5rem" },
                      }}
                    >
                      USD
                    </CommonButton>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row gap-4">
                <TextFieldWrapper label={i18n.purchaseUnit} className="w-1/2">
                  <CommonTextInput
                    name={"purchaseUnit"}
                    placeholder={i18n.purchaseUnit}
                    icon={IconSearch}
                    onChange={handleChange}
                    props={{
                      ...formikEvents,
                      ...formikErrors("purchaseUnit"),
                      ...formikValues("purchaseUnit"),
                      autocomplete: {
                        ...props.autocomplete.purchaseUnit,
                        defaultValue: props.campaign?.ad_pricing,
                        onChange: (e, value) =>
                          handleChange("purchaseUnit")(value),
                      },
                    }}
                  />
                </TextFieldWrapper>
              </div>
              <div className="flex flex-row gap-4 my-4">
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.impressions}>
                    <CommonTextInput
                      name={"impressions"}
                      placeholder={i18n.planned.impressions}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("impressions"),
                        ...formikValues("impressions"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.clicks}>
                    <CommonTextInput
                      name={"clicks"}
                      placeholder={i18n.planned.clicks}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("clicks"),
                        ...formikValues("clicks"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.views}>
                    <CommonTextInput
                      name={"views"}
                      placeholder={i18n.planned.views}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("views"),
                        ...formikValues("views"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.reach}>
                    <CommonTextInput
                      name={"reach"}
                      placeholder={i18n.planned.reach}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("reach"),
                        ...formikValues("reach"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.conversions}>
                    <CommonTextInput
                      name={"conversions"}
                      placeholder={i18n.planned.conversions}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("conversions"),
                        ...formikValues("conversions"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.installs}>
                    <CommonTextInput
                      name={"installs"}
                      placeholder={i18n.planned.installs}
                      icon={IconChartLine}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("installs"),
                        ...formikValues("installs"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
              </div>
              <div className="flex flex-row gap-4 my-4">
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.cpm}>
                    <CommonTextInput
                      name={"cpm"}
                      placeholder={i18n.planned.cpm}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpm"),
                        ...formikValues("cpm"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.cpc}>
                    <CommonTextInput
                      name={"cpc"}
                      placeholder={i18n.planned.cpc}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpc"),
                        ...formikValues("cpc"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.cpv}>
                    <CommonTextInput
                      name={"cpv"}
                      placeholder={i18n.planned.cpv}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpv"),
                        ...formikValues("cpv"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.cpu}>
                    <CommonTextInput
                      name={"cpu"}
                      placeholder={i18n.planned.cpu}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpu"),
                        ...formikValues("cpu"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
                <div className="flex flex-row gap-4 w-1/3">
                  <TextFieldWrapper label={i18n.cpa}>
                    <CommonTextInput
                      name={"cpa"}
                      placeholder={i18n.planned.cpa}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpa"),
                        ...formikValues("cpa"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                  <TextFieldWrapper label={i18n.cpi}>
                    <CommonTextInput
                      name={"cpi"}
                      placeholder={i18n.planned.cpi}
                      icon={IconCoins}
                      onChange={handleChange}
                      props={{
                        ...formikEvents,
                        ...formikErrors("cpi"),
                        ...formikValues("cpi"),
                        type: "number",
                      }}
                    />
                  </TextFieldWrapper>
                </div>
              </div>
              <div className="flex flex-row gap-4 my-4 w-full">
                <TextFieldWrapper label={i18n.ctr + "%"} className="w-1/6">
                  <CommonTextInput
                    name={"ctr"}
                    placeholder={i18n.planned.ctr}
                    icon={IconPercentage}
                    onChange={handleChange}
                    props={{
                      ...formikEvents,
                      ...formikErrors("ctr"),
                      ...formikValues("ctr"),
                      type: "number",
                    }}
                  />
                </TextFieldWrapper>
                <TextFieldWrapper label={i18n.vtr + "%"} className="w-1/6">
                  <CommonTextInput
                    name={"vtr"}
                    placeholder={i18n.planned.vtr}
                    icon={IconPercentage}
                    onChange={handleChange}
                    props={{
                      ...formikEvents,
                      ...formikErrors("vtr"),
                      ...formikValues("vtr"),
                      type: "number",
                    }}
                  />
                </TextFieldWrapper>
              </div>
              <hr />
              <div className="flex flex-row gap-4 my-4">
                <CommonButton variant="contained" onClick={handleSubmit}>
                  {props.mode === "add" ? "Добавить" : "Сохранить"}
                </CommonButton>
                <CommonButton variant="outlined" onClick={props.toggler}>
                  Отмена
                </CommonButton>
              </div>
              {/* for debug purposes <div className="text-red">{JSON.stringify(errors)}</div>*/}
              {createMediaplanState.data &&
                createMediaplanState.data.status_code === 0 && (
                <div className="text-primary">
                  {"Кампания успешно добавлена"}
                </div>
              )}
              {(createMediaplanState.error ||
                (createMediaplanState.data &&
                  createMediaplanState.data.status_code !== 0)) && (
                <div className="text-red">
                  {"Ошибка при добавлении кампании"}
                </div>
              )}
              {updateMediaplanRowState.data &&
                updateMediaplanRowState.data.status_code === 0 && (
                <div className="text-primary">
                  {"Кампания успешно обновлена"}
                </div>
              )}
              {(updateMediaplanRowState.error ||
                (updateMediaplanRowState.data &&
                  updateMediaplanRowState.data.status_code !== 0)) && (
                <div className="text-red">
                  {"Ошибка при обновлении кампании"}
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
