import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

import { fetchLocale } from "./fetchLocale";
import { LocaleReducers } from "./localeSlice";
import { Locale } from "shared/assets/i18n";

export const useFetchLocale = (name?: localeName) => {
  const dispatch =
    useDispatch<ThunkDispatch<LocaleReducers, typeof fetchLocale, AnyAction>>();

  let success = false;

  useEffect(() => {
    dispatch(fetchLocale(name));
  }, [name]);

  return success;
};

type localeSlices = keyof Locale;

export const useLocaleSlice = (slice: localeSlices) => {
  return useSelector<LocaleReducers, Locale[localeSlices] >((state) =>
    state.i18n.locale[slice]
  );
};

export const useLocale = () => {
  return useSelector<LocaleReducers, Locale>((state) => state.i18n.locale);
};

export const useCurrentLocale = () => {
  return useSelector<LocaleReducers, localeName>(
    (state) => state.i18n.config.currentLocale
  );
};
