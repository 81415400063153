import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import { AgencyForm } from "widgets/admin";
import { CommonLayout } from "shared/ui/layout";
import { useLocale } from "entities/i18n";
import { useAuthSelector } from "entities/auth";
import { useReadAgencyQuery } from "entities/admin";

const allowedRoles = ["AGENCY_DIRECTOR"];

const RegisterAgency = () => {
  const { admin } = useLocale();
  const { user } = useAuthSelector();
  const allowedIn = user?.roles.some((role) =>
    allowedRoles.includes(role.code)
  );

  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };

  const { data } = useReadAgencyQuery({
    role: "AGENCY_DIRECTOR",
  });

  return (
    <>
      <CommonLayout
        title={<h1>{admin.dataForRegistration}</h1>}
        className="bg-grey-200 py-8"
      >
        {allowedIn
          ? (
            data && (
              <AgencyForm
                userRole="director"
                type={data.status_code === 0 ? "update" : "create"}
                agency={data.status_code === 0 ? data.result : undefined}
                handleCancel={handleCancel}
              />
            )
          )
          : <div className="pt-8">{admin.notADirector}</div>}
      </CommonLayout>
    </>
  );
};

export default RegisterAgency;
