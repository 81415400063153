import { ComplexTableProps, styles } from "shared/ui/table/ComplexTable";
import { useMemo } from "react";

import { TableCell, TableHead, TableRow } from "@mui/material";

/**
 * Функция для получения правильной шапки таблицы из столбцов
 * @param cols - столбцы таблицы, см. ComplexTableProps
 * @param groupTitles - объект с ReactNode для групп столбцов, см. ComplexTableProps
 * @returns [topRowCellComponents, bottomRowCellComponents] - массивы JSX-компонентов для верхней и нижней строк шапки таблицы
 */
const getTableHeaderCells = (
  cols: ComplexTableProps<any>["cols"],
  groupTitles: ComplexTableProps<any>["groupTitles"]
) => {

  // Получаем верхнюю строку шапки таблицы (пока в виде объекта)
  let topCells = cols.map((col) => {
    return col.group
      ? {
        node: col.group,
        rowSpan: 1,
        colSpan: 1,
        specialCellStyles: col.specialCellStyles ? col.specialCellStyles : "",
      }
      : {
        node: col.title,
        rowSpan: 2,
        colSpan: 1,
        specialCellStyles: col.specialCellStyles ? col.specialCellStyles : "",
      };
  });

  // Получаем нижнюю строку шапки таблицы 
  // (тоже объект, исключаем все ячейчи, что уже есть в верхней строке)
  let bottomCells = cols.map((col, index) => {
    return topCells[index].node !== col.title
      ? {
        node: col.title,
        colSpan: 1,
        rowSpan: 1,
        specialCellStyles: col.specialCellStyles ? col.specialCellStyles : "",
      }
      : null;
  });

  bottomCells = bottomCells.filter((cell) => cell !== null);

  // Дедуплицируем и узнаём colSpan для ячеек-групп (верхняя строка)
  topCells = topCells.reduce(
    (acc, curr) => {
      if (!acc.length) {
        acc.push(curr);
        return acc;
      }
      const last = acc[acc.length - 1];
      if (last.node === curr.node) {
        last.colSpan++;
      } else {
        acc.push(curr);
      }
      return acc;
    },
    [] as {
      node: React.ReactNode;
      rowSpan: number;
      colSpan: number;
      specialCellStyles: string;
    }[]
  );

  // Если groupTitles передан, то заменяем названия групп
  // на соответствующие ReactNode

  if (groupTitles) {
    topCells = topCells.map((cell) => {
      if (groupTitles[cell.node as string]) {
        return {
          ...cell,
          node: groupTitles[cell.node as string],
        };
      }
      return cell;
    });
  }

  // Получаем массивы JSX-компонентов для верхней и нижней строк шапки таблицы
  const topRowCellComponents = topCells.map((cell, index) => {
    return (
      <TableCell
        sx={{ border: 1 }}
        key={index}
        colSpan={cell.colSpan}
        rowSpan={cell.rowSpan}
        className={`${styles.tableCell} ${styles.tableHeaderCell} ${cell.specialCellStyles}`}
      >
        {cell.node}
      </TableCell>
    );
  });

  const bottomRowCellComponents = bottomCells.map((cell, index) => {
    return (
      <TableCell
        sx={{ border: 1 }}
        key={index}
        className={`${styles.tableCell} ${styles.tableHeaderCell} ${cell?.specialCellStyles}`}
      >
        {cell?.node}
      </TableCell>
    );
  });

  return [topRowCellComponents, bottomRowCellComponents];
};
 
const ComplexTableHeader = ({ cols, groupTitles }: { 
  cols: ComplexTableProps<any>["cols"], 
  groupTitles: ComplexTableProps<any>["groupTitles"]
}) => {
  const [headerTopCells, headerBottomCells] = useMemo(
    () => getTableHeaderCells(cols, groupTitles),
    [cols, groupTitles]
  );

  return (
    <TableHead>
      <TableRow>{headerTopCells}</TableRow>
      <TableRow>{headerBottomCells}</TableRow>
    </TableHead>
  );
}

export default ComplexTableHeader;