import { useMemo, useState } from "react";
import { IconBtnsList, IBtn } from "shared/ui";
import { IconPower, IconTrash, IconEdit } from "@tabler/icons-react";
import {
  UpdateApiName,
  UpdateApiStatus,
  DeleteApi,
} from "./dialogsForApiManage";

import { actionsIconProps } from "shared/constants";

type Props = {
  apiId: number;
  apiName: string;
  isActive: boolean;
  refetch: () => void;
  // setChanges: any;
};

export const BtnsManageApi = ({
  apiId,
  apiName,
  isActive, // setChanges,
  refetch
}: Props) => {
  const [openUpdateName, setopenUpdateName] = useState(false);
  const [openUpdateStatus, setopenUpdateStatus] = useState(false);
  const [openDeleteApi, setopenDeleteApi] = useState(false);

  const btns: IBtn[] = useMemo(() => {
    return [
      {
        icon: <IconEdit {...actionsIconProps} />,
        onClick: () => {
          setopenUpdateName(true);
        },
      },
      {
        icon: (
          <IconPower
            {...actionsIconProps}
            color={isActive ? "currentColor" : "red"}
          />
        ),
        onClick: () => {
          setopenUpdateStatus(true);
        },
      },
      {
        icon: <IconTrash {...actionsIconProps} />,
        onClick: () => {
          setopenDeleteApi(true);
        },
      },
    ];
  }, [apiId, isActive, apiName]);

  const apiProps = useMemo(
    () => ({ apiId, apiName, isActive, refetch }),
    [apiId, apiName, isActive]
  );

  return (
    <>
      <IconBtnsList btns={btns} />
      <UpdateApiName
        {...apiProps}
        open={openUpdateName}
        setOpen={setopenUpdateName}
      />
      <UpdateApiStatus
        {...apiProps}
        open={openUpdateStatus}
        setOpen={setopenUpdateStatus}
      />
      <DeleteApi
        {...apiProps}
        open={openDeleteApi}
        setOpen={setopenDeleteApi}
      />
    </>
  );
};
