import React from "react";

const useChartLegendHelper = (
  chartRef: React.MutableRefObject<HTMLDivElement>
) => {
  // Проверка на то, что легенда уже добавлена
  // Так мы избекаем ее дублирования из-за двукратного вызова useEffect
  const added = React.useRef<boolean>(false);

  React.useEffect(() => {
    const node = chartRef.current;
    if (node) {

      // Получаем легенду
      const chartLegend = node.querySelector(
        ".apexcharts-legend"
      ) as HTMLDivElement;

      // Клонируем легенду и стилизуем ее
      const newLegend = chartLegend.cloneNode(true) as HTMLDivElement;
      newLegend.setAttribute(
        "style",
        "position: sticky; bottom: 0; left: 0; width: 100%;"
      );

      // Добавляем легенду в DOM
      // Добавляем класс visible. Для стандартной легенды настроена глобальная невидимость в src/style.css
      if (added.current) return;
      newLegend.classList.add("visible");
      node.appendChild(newLegend);
      added.current = true;
    }
  }, [chartRef]);
};

export default useChartLegendHelper;