import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
import {
  // PUBLIC_API_URL,
  QUERY_CREATE_EXCHANGE_RATE,
  QUERY_DELETE_EXCHANGE_RATE,
  QUERY_READ_ALL_EXCHANGE_RATES,
  QUERY_UPDATE_EXCHANGE_RATE,
  adsSystems,
} from "shared/constants";

export type ExchangeRate = {
  agency_id: number;
  api_type: adsSystems | "FACEBOOK";
  value_rub: number;
  value_usd: number;
  id: number;
};

export type ResponseReadExchangeRates = Omit<ResponseCommon, "result"> & {
  result: ExchangeRate[];
};
export type ResponseMutateExchangeRate = Omit<ResponseCommon, "result"> & {
  result: ExchangeRate;
};

// export const ExchangeApi = createApi({
export const ExchangeApi = baseApi.injectEndpoints({
  // reducerPath: "ExchangeApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders: (headers) => {
  //     const token = getMemory("serviseAuth");
  //     if (token) {
  //       headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //     }
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    createExchangeRate: builder.mutation<
      ResponseMutateExchangeRate,
      {
        agency_id: number;
        api_type: adsSystems | "FACEBOOK";
        value_rub: number;
        value_usd: number;
      }
    >({
      query: (data) => ({
        url: QUERY_CREATE_EXCHANGE_RATE,
        method: "POST",
        body: {
          ...data,
          api_type: data.api_type === "META" ? "FACEBOOK" : data.api_type,
        },
      }),
    }),
    readExchangeRates: builder.query<
      ResponseReadExchangeRates,
      {
        agency_id: number;
      }
    >({
      query: (data) => ({
        url: QUERY_READ_ALL_EXCHANGE_RATES,
        method: "GET",
        params: { ...data },
      }),
    }),
    updateExchangeRate: builder.mutation<
      ResponseMutateExchangeRate,
      {
        id: number;
        api_type: adsSystems | "FACEBOOK";
        value_rub: number;
        value_usd: number;
      }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_EXCHANGE_RATE,
        method: "PUT",
        body: {
          ...data,
          api_type: data.api_type === "META" ? "FACEBOOK" : data.api_type,
        },
      }),
    }),
    deleteExchangeRate: builder.mutation<
      ResponseCommon,
      { agency_id: number; api_type: adsSystems | "FACEBOOK" }
    >({
      query: (data) => ({
        url: QUERY_DELETE_EXCHANGE_RATE,
        method: "DELETE",
        params: {
          ...data,
          api_type: data.api_type === "META" ? "FACEBOOK" : data.api_type,
        },
      }),
    }),
  }),
});

export const {
  useCreateExchangeRateMutation,
  useReadExchangeRatesQuery,
  useUpdateExchangeRateMutation,
  useDeleteExchangeRateMutation,
} = ExchangeApi;
