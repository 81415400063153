import { Button, styled } from "@mui/material";
import { IconDownload } from "@tabler/icons-react";
import { useUploadMediaplanMutation } from "entities/mediaplan";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES, MEDIAPLAN_SAMPLE_RELATIVE_PATH } from "shared/constants";
import { Dialog } from "shared/ui/components";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const DashboardBtns = (props: {
  agencyId: number;
  clientId: number;
  disableAddBtn?: boolean;
  onAddCampaign: () => void;
}) => {
  console.log({ d: props.disableAddBtn });

  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);

  const [uploadMediaplan] = useUploadMediaplanMutation();

  const handleUploadMediaplan = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (file) {
      const uploadPromise = uploadMediaplan({
        agency_id: props.agencyId,
        client_id: props.clientId,
        file: file,
      }).unwrap();
      uploadPromise.then((res) => {
        res.status_code === 0 &&
          navigate(ROUTES.mediaplan, {
            state: {
              mediaplan: res.result,
              clientId: props.clientId,
              agencyId: props.agencyId,
            },
          });
      });
    }
  }, [file]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const toggleDialog = () => setDialogVisible(!dialogVisible);

  /* Plan to upload file:
    1. Add a dialog window
    2. Show dialog on button click
    3. Add a file input to the dialog
    * drag-n-drop
    * click opens file explorer
    4. Add a button to upload the file
    5. Add a button to cancel the upload
    6. Call the API to upload the file
    7. Show the progress of the upload
    8. Once the result is received, navigate to the next page, pass the result
    as location.state and show the result in a ComplexTable
  */

  return (
    <div className="flex flex-row gap-3">
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="bg-primary text-white normal-case"
        onClick={toggleDialog}
      >
        Загрузить медиаплан
      </Button>
      {/* ==== DIALOG ==== */}
      {dialogVisible && (
        <Dialog toggler={toggleDialog}>
          <div className="h-[30rem] w-[50rem] p-5">
            <div className="h-full w-full border-2 border-dashed border-grey-300 flex flex-col gap-4 justify-center items-center rounded-md">
              <div className="w-72 font-bold text-lg text-grey-300 text-center">
                Нажмите в любое место или перетащите файл
              </div>
              <Button
                component="label"
                variant="contained"
                color="primary"
                size="large"
                className="h-10 w-72 text-white normal-case"
              >
                Загрузить файл
                <VisuallyHiddenInput
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleUploadMediaplan}
                />
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <a
        className="flex flex-row items-center gap-2 group cursor-pointer no-underline"
        href={process.env.PUBLIC_URL + MEDIAPLAN_SAMPLE_RELATIVE_PATH}
        download
      >
        <IconDownload
          size={26}
          color="#808080"
          strokeWidth={1.8}
          className="group-hover:stroke-black transition-all"
        />
        <div className="w-36 leading-[115%] text-[#808080] group-hover:text-black transition-all cursor-pointer no-underline">
          скачать типовой медиаплан
        </div>
      </a>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="bg-primary text-white normal-case"
        onClick={props.onAddCampaign}
        disabled={props.disableAddBtn}
      >
        Добавить кампанию
      </Button>
    </div>
  );
};
