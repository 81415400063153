import React from "react";
import { IStatsData } from "shared/models/mocks";

type Props = {
  title: string;
  color: string;
  icon: StatEntry["cabinet"]["type"] | null;
};

const AdCabinetBadge = ({ title, color, icon }: Props) => {
  if (!icon) {
    return (
      <div className="p-0.5 flex align-center">
        <span className="mt-0.5">{title}</span>
      </div>
    );
  }

  let iconName = icon.toLowerCase().trim().replaceAll(" ", "");

  if (/facebook|instagram|fb|meta/.test(iconName)) {
    iconName = "facebook";
  } else if (/youtube|gdn/.test(iconName)) {
    iconName = "google";
  }

  return (
    <div className="p-0.5 flex align-center">
      <img
        src={`/icons/ad_cabinets/${iconName}.svg`}
        className={`fill-${color} mr-2`}
        alt={`${icon}`}
      ></img>
      <span className="mt-0.5">{title}</span>
    </div>
  );
};

export default AdCabinetBadge;
