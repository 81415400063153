function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      className={value === index ? "flex flex-col justify-start flex-auto" : "hidden"}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
export default TabPanel;