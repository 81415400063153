import { AuthPage } from "widgets/auth";
import { AuthCard } from "features/auth";
import { RegistrationSuccess } from "features/auth";

export default () => {
  return (
    <AuthPage
      component={
        <AuthCard>
          <RegistrationSuccess />
        </AuthCard>
      }
    />
  );
};
