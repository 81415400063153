import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CommonLayout } from "shared/ui";
import { SystemAdminList as AdminListWidget } from "widgets/superAdmin";
import { useLocale } from "entities/i18n";

const SystemAdminList = () => {
  const navigate = useNavigate();
  const handlerAdd = () => navigate("/system-admin/create");
  
  const i18n = useLocale().superAdmin;

  return (
    <CommonLayout
      title={
        <h1 className="block my-0 leading-normal border border-orange-accent text-[2.25rem]">
          {i18n.admins}
        </h1>
      }
      btns={
        <Button
          disableElevation
          disabled={false}
          fullWidth
          size="large"
          variant="contained"
          className="bg-primary text-white normal-case"
          onClick={handlerAdd}
        >{i18n.add}</Button>
      }
    >
      <AdminListWidget />
    </CommonLayout>
  );
};

export default SystemAdminList;