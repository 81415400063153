import { useAuthSelector } from "entities/auth";
import { useMemo } from "react";

const ROLES_BY_IMPORTANCE = [
  "ROOT",
  "MAIN_ADMIN",
  "SYSTEM_ADMIN",
  "AGENCY_DIRECTOR",
  "MANAGER",
  "NONE",
] as const;

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type RoleCode = ArrayElement<typeof ROLES_BY_IMPORTANCE>;

type Props = {
  pages: Omit<Partial<{ [K in RoleCode]: { component: React.ComponentType<any>, props?: object } }>, "NONE"> & {
    NONE: { component: React.ComponentType<any>, props?: object };
  };
};

export const AuthRouter = ({ pages }: Props) => {
  const { user } = useAuthSelector();
  const role = useMemo(
    () =>
      user?.roles.find((role) =>
        ROLES_BY_IMPORTANCE.includes(role.code as RoleCode)
      ),
    [user]
  );
  const Page = pages[(role?.code || 'NONE') as RoleCode] || pages.NONE;
  return Page && <Page.component {...Page.props} />;
};
