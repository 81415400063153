import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  agencyId: null as number | null,
};

type AdminState = typeof initialState;

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAgency: (state: AdminState, action: PayloadAction<number>) => {
      state.agencyId = action.payload;
    },
    deleteAgency: (state: AdminState) => {
      state.agencyId = null;
    },
  },
});
