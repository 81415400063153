import React from "react";

export function a11yProps(index: number) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}
  
export const TabIconConverter = ({ icon }: { icon: React.ReactElement }) => {
  return <div className="w-4 h-4 mr-[7px]">{icon}</div>;
};