import { Palette } from "@mui/material";

export const backIconProps = {
  color: "black",
  size: 20,
  strokeWidth: "1.5",
};

export const tabIconProps = {
  size: 14,
  strokeWidth: 1.4,
  color: "black",
};

export const actionsIconProps = {
  size: 20,
  strokeWidth: 1.5,
  color: "black",
};

export const outlinedInputSx = (palette: Palette) => {
  return {
    borderColor: palette.grey[200],
    color: palette.grey[200],
    border: 1,
    borderRadius: 2,
    "&:hover": { borderColor: palette.grey[400] },
    "& .MuiFilledInput-root": {
      background: "none !important",
      pl: 0,
      "&:before": { bb: "none !important" },
    },
  };
};

export const errorSx = { "& .MuiFormHelperText-root": { color: "red" } };
