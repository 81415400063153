import { CommonLayout } from "shared/ui/layout";
import { useLocale } from "entities/i18n";
import { Button } from "@mui/material";
import { TabBox } from "shared/ui/tabs";
import {
  IconBriefcase,
  IconCurrencyTenge,
  IconUserCircle,
} from "@tabler/icons-react";
import { ClientsList as ClientsListWidget } from "widgets/clients";
import { useNavigate } from "react-router-dom";
import { CurrencyList as CurrencyListWidget } from "widgets/director";
import { useEffect, useMemo, useRef, useState } from "react";
import { AgencyUsersList as AgencyUsersListWidget } from "widgets/agencyUsersList";
import { useAgency } from "entities/admin";
import { useAuthSelector } from "entities/auth";
import { OnModerationMessage } from "./ui";

const iconProps = {
  size: 14,
  strokeWidth: 1.4,
  color: "black",
};

const DIRECTOR_TABS = {
  clients: 0,
  users: 1,
  currencies: 2,
} as const;

const MANAGER_TABS = {
  clients: 0,
  currencies: 1,
} as const;

export const AgencyHome = (props: {
  activeTab: keyof typeof DIRECTOR_TABS | keyof typeof MANAGER_TABS;
}) => {
  const i18n = useLocale().admin;
  const user = useAuthSelector().user;
  const [userIsDirector, setUserIsDirector] = useState(false);

  const [activeTabName, setActiveTabName] = useState<
    keyof typeof DIRECTOR_TABS | keyof typeof MANAGER_TABS
  >(props.activeTab);
  const [activeTab, setActiveTab] = useState<number>();

  useEffect(() => {
    setUserIsDirector(
      user?.roles.find((r) => r.code === "AGENCY_DIRECTOR") !== undefined,
    );
  }, [user]);

  useEffect(() => {
    if (userIsDirector) {
      setActiveTab(DIRECTOR_TABS[activeTabName]);
    } else {
      activeTabName != "users" && setActiveTab(MANAGER_TABS[activeTabName]);
    }
  }, [activeTabName, userIsDirector]);

  const navigate = useNavigate();
  const handleAddClient = () => navigate("/clients/create");
  const handleAddUser = () => navigate("/users/create");

  const { agencyId, agencyResponse } = useAgency();

  // const agencyDirector = agencyResponse
  //   ? {
  //       ...agencyResponse.result.director,
  //       id: agencyResponse.result.director_id,
  //     }
  //   : undefined;

  const contentForTabs: ContentTab[] = useMemo(() => {
    if (!user)
      return [
        { title: "", icon: <IconBriefcase {...iconProps} />, content: null },
      ];

    const clientsTab = {
      icon: <IconBriefcase {...iconProps} />,
      title: i18n.clients.title,
      content: <ClientsListWidget />,
    };

    const usersTab = {
      icon: <IconUserCircle {...iconProps} />,
      title: i18n.users.title,
      content: agencyId ? (
        <AgencyUsersListWidget agencyId={agencyId} userRole="director" />
      ) : (
        "error: agencyId is not defined"
      ),
    };

    const currenciesTab = {
      icon: <IconCurrencyTenge {...iconProps} />,
      title: i18n.currencies.title,
      content: <CurrencyListWidget />,
    };

    return userIsDirector
      ? [clientsTab, usersTab, currenciesTab]
      : [clientsTab, currenciesTab];
  }, [userIsDirector]);

  const handleAddButtonClicked = (
    tab: keyof typeof DIRECTOR_TABS | keyof typeof MANAGER_TABS,
  ) => {
    switch (tab) {
      case "clients":
        return handleAddClient;
      case "users":
        return userIsDirector ? handleAddUser : () => {};
      default:
        return () => {};
    }
  };

  return (
    <CommonLayout
      title={<h1>{contentForTabs[activeTab ?? 0].title}</h1>}
      btns={
        activeTab !== undefined &&
        activeTabName !== "currencies" && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="bg-primary text-white normal-case"
            onClick={handleAddButtonClicked(activeTabName)}
          >
            {i18n.add}
          </Button>
        )
      }
    >
      <TabBox
        contentTabs={contentForTabs}
        ariaLabel="Управление страницей"
        onChange={(index: number) => {
          setActiveTabName(
            (userIsDirector
              ? Object.keys(DIRECTOR_TABS)[index]
              : Object.keys(MANAGER_TABS)[index]) as
              | keyof typeof MANAGER_TABS
              | keyof typeof DIRECTOR_TABS,
          );
        }}
        activeTab={activeTab}
      />
      {agencyResponse && !agencyResponse?.result.confirmed ? (
        <OnModerationMessage />
      ) : null}
    </CommonLayout>
  );
};
