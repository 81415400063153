import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib/memory";
// import { GlobalState } from "shared/types";
import {
  PUBLIC_API_URL,
  QUERY_APPROVE_AGENCY,
  QUERY_CREATE_AGENCY,
  QUERY_CREATE_AGENCY_BY_SYS_ADMIN,
  QUERY_DELETE_AGENCY,
  QUERY_GET_AGENCIES,
  QUERY_READ_AGENCY_BY_DIRECTOR,
  QUERY_READ_AGENCY_BY_MANAGER,
  QUERY_UPDATE_AGENCY,
  QUERY_UPDATE_AGENCY_STATUS,
} from "shared/constants";

export type DirectorById = {
  director_id: number;
};

export type DirectorByContacts = {
  director_full_name: string;
  director_phone: string;
  director_email: string;
};

export type Director = DirectorById | DirectorByContacts;

// export const AdminApi = createApi({
export const AdminApi = baseApi.injectEndpoints({
  // reducerPath: "adminApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   // prepareHeaders: (headers) => {
  //   //   const token = getMemory("serviseAuth");
  //   //   if (token) {
  //   //     headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //   //   }
  //   //   return headers;
  //   // },
  //   prepareHeaders(headers, { getState }) {
  //     const state = getState() as GlobalState;
  //     headers.append("Authorization", `Bearer ${state.auth.accessToken}`);
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    createAgency: builder.mutation<ResponseAgency, CreateAgencySchema>({
      query: (data: CreateAgencySchema) => ({
        url: QUERY_CREATE_AGENCY,
        method: "POST",
        body: { ...data },
      }),
    }),
    readAgency: builder.query<
      ResponseAgency,
      { role: "AGENCY_DIRECTOR" | "MANAGER" }
    >({
      query: (data) => {
        switch (data.role) {
          case "AGENCY_DIRECTOR":
            return {
              url: QUERY_READ_AGENCY_BY_DIRECTOR,
              method: "GET",
            };
          case "MANAGER":
            return {
              url: QUERY_READ_AGENCY_BY_MANAGER,
              method: "GET",
            };
        }
      },
    }),
    getAgencies: builder.query<
      PaginatedResponseAgency,
      { page?: number; size?: number; bin?: string; name?: string }
    >({
      query: (params) => ({
        url: QUERY_GET_AGENCIES,
        method: "GET",
        params,
      }),
    }),
    approveAgency: builder.mutation<ResponseAgency, { agency_id: number }>({
      query: (data) => ({
        url: QUERY_APPROVE_AGENCY,
        method: "PUT",
        body: data,
      }),
    }),
    createAgencyBySysAdmin: builder.mutation<
      ResponseAgency,
      Omit<CreateAgencySchema, "director_id"> &
        Director & {
          confirmed: boolean;
        }
    >({
      query: (data) => ({
        url: QUERY_CREATE_AGENCY_BY_SYS_ADMIN,
        method: "POST",
        body: data,
      }),
    }),
    deleteAgency: builder.mutation<ResponseCommon, { agency_id: number }>({
      query: (data) => ({
        url: QUERY_DELETE_AGENCY,
        method: "DELETE",
        params: data,
      }),
    }),
    updateAgencyByAdmin: builder.mutation<
      ResponseAgency,
      Omit<CreateAgencySchema, "director_id"> &
        Partial<DirectorByContacts> & { agency_id: number }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_AGENCY,
        method: "PUT",
        body: data,
      }),
    }),
    updateAgencyStatus: builder.mutation<
      ResponseCommon,
      { agency_id: number; is_active: boolean }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_AGENCY_STATUS,
        method: "PUT",
        params: data,
      }),
    }),
  }),
});

export const {
  useCreateAgencyMutation,
  useReadAgencyQuery,
  useLazyReadAgencyQuery,
  useGetAgenciesQuery,
  useLazyGetAgenciesQuery,
  useApproveAgencyMutation,
  useCreateAgencyBySysAdminMutation,
  useDeleteAgencyMutation,
  useUpdateAgencyByAdminMutation,
  useUpdateAgencyStatusMutation,
  usePrefetch,
} = AdminApi;
