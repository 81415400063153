import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
import {
  // PUBLIC_API_URL,
  QUERY_READ_STATS,
} from "shared/constants";

// export const StatApi = createApi({
export const StatApi = baseApi.injectEndpoints({
  // reducerPath: "StatApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders: (headers) => {
  //     const token = getMemory("serviseAuth");
  //     if (token) {
  //       headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //     }
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    readStatByClient: builder.query<ResponseReadConnectors, number>({
      query: (client_id) => ({
        //TODO: Rewrite it to get stats
        url: `${QUERY_READ_STATS}/?client_id=${client_id}`,
        method: "GET",
        // params: { ...data },
      }),
    }),
    readStatistics: builder.query<
      ResponseReadStatistics,
      RequestReadStatistics
    >({
      query: (data) => ({
        url: QUERY_READ_STATS,
        method: "GET",
        params: data,
      }),
    }),
  }),
});

export const { useReadStatByClientQuery, useReadStatisticsQuery } = StatApi;
