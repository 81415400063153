import { PaginatedComplexTable } from "shared/ui/table";
// import { useDashboardTable } from "entities/data";
import { useLocale } from "entities/i18n";
import { useTheme } from "@mui/material/styles";
import { getColumnsByI18n, getGroupTitlesByI18n } from "./getColumns";
import transformer from "./transformer";

import { useExchangeRates } from "features/exchange";
import { ResponsePlanFactMediaplan } from "entities/mediaplan";
import { useEffect, useState } from "react";
import { defaultFallbackCurrency } from "shared/constants";

type DashboardTableProps = {
  className?: string;
  res: {
    data: ResponsePlanFactMediaplan;
    isLoading: boolean;
    error: any;
  };
  clientId?: string;
  agencyId?: string;
  fetchDataPage: (page: number) => void;
  userRole: "admin" | "director";
  onEditRow: (id: number) => void;
};

const DashboardTable = ({
  className,
  res,
  fetchDataPage,
  clientId,
  agencyId,
  userRole,
  onEditRow,
}: DashboardTableProps) => {
  // const [data, isLoading, error] = useDashboardTable();
  const { data, isLoading, error } = res;
  const { rates } = useExchangeRates();

  console.log({ data, isLoading, error });

  // useEffect(() => {
  //   console.log('rates', rates)
  // }, [rates])

  const [displayCurrency, setDisplayCurrency] = useState<Currency>(
    defaultFallbackCurrency,
  );

  useEffect(() => {
    console.log("displayCurrency", displayCurrency);
  }, [displayCurrency]);

  const i18n = useLocale();
  const { palette } = useTheme();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error...</div>;
  }

  return (
    <>
      {data.status_code === 0 && rates && (
        <PaginatedComplexTable<PlanFactEntry>
          rawData={data.result.items}
          cols={getColumnsByI18n(i18n, userRole, setDisplayCurrency)}
          groupTitles={getGroupTitlesByI18n(i18n)}
          rowTransformer={transformer(
            i18n.dashboard,
            palette,
            onEditRow,
            userRole,
            rates,
            displayCurrency,
            clientId,
            agencyId,
          )}
          className={className}
          pages={data.result.pages}
          rowsPerPage={20}
          totalRows={data.result.total}
          handlePageChange={(page) => {
            fetchDataPage(page);
          }}
        />
      )}
      {data.status_code !== 0 && <div>Something went wrong...</div>}
      {data.status_code === 2022 && (
        <div className="font-bold">Mediaplan does not exist</div>
      )}
    </>
  );
};

export default DashboardTable;
