import { SuspenseProps, useState, useRef, useEffect, MutableRefObject } from 'react';
import { Box } from '@mui/material';

interface Props {
  offsetWidth: number
  offsetHeight: number
}

export default ({ children }: SuspenseProps) => {
  const ref: MutableRefObject<Props> = useRef({} as Props);

  const speed = 0.3;
  const cloud = 40;
  
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {  
    window.addEventListener("mousemove", setParalaxStyle);  
    return () => {
      window.removeEventListener("mousemove", setParalaxStyle);
    };
  }, []);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
    setHeight(ref?.current?.offsetHeight);
  }, [ref]);

  const setParalaxStyle = (e: any) => {
    e.preventDefault();
    const x = e.clientX - width / 2;
    const y = e.clientY - height / 2;
    const disX = x - position.x;
    const disY = y - position.y;
    if (disX != 0 || disY != 0) {
      setPosition({
        x: position.x + (disX * speed),
        y: position.y + (disY * speed)
      });
    }
  }

  return (
    <Box className="paralax"
      ref={ref}
      sx={{
        width: "100%",
        height: "100%",
        position: 'fixed',
        scale: "1.3",
        zIndex: -1,
        bottom: 0,
        transform: `translate(${position.x / cloud -10}%, ${position.y / cloud - 10}%)`,
        overflow: "hidden",
      }}
    >
      {children}
    </Box>
  );
}