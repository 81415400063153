import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconSearch } from "@tabler/icons-react";

import { Dialog } from "shared/ui/components";
import { PaginatedComplexList } from "shared/ui/list";
import { compileListData } from "./compileListData";

import {
  useLazyReadSystemAdminsQuery,
  useLazyDeleteSystemAdminQuery,
  useLazyUpdateSystemAdminQuery,
} from "entities/superAdmin";
import { useLocale } from "entities/i18n";
import { TextInput } from "shared/ui/form";

type DialogAction = "delete" | "deactivate";

const SystemAdminList = () => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;
  const errorMessages = i18nGlobal.errors;
  const { palette } = useTheme();
  const navigate = useNavigate();

  const [readSystemAdmins, readStatus] = useLazyReadSystemAdminsQuery();
  const [deleteSystemAdmin, deleteStatus] = useLazyDeleteSystemAdminQuery();
  const [updateSystemAdmin, updateStatus] = useLazyUpdateSystemAdminQuery();

  const [dialogVisible, setDialogVisible] = useState(false);
  const dialogToggler = () => {
    setDialogVisible(!dialogVisible);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAction, setSelectedAction] = useState<DialogAction>("delete");
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const [page, setPage] = useState(1);

  useEffect(() => {
    readSystemAdmins({
      show_deleted: false,
      full_name: search,
      page,
      size: 15,
    });
  }, [page]);

  useEffect(() => {
    if (deleteStatus.data?.status_code === 0) {
      readSystemAdmins({
        show_deleted: false,
        full_name: search,
        page,
        size: 10,
      });
    }
  }, [deleteStatus.data]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      readSystemAdmins({
        show_deleted: false,
        full_name: search,
        page,
        size: 15,
      });
    }
  }, [updateStatus.data]);

  useEffect(() => {
    clearTimeout(searchTimeout!);
    setSearchTimeout(
      setTimeout(() => {
        readSystemAdmins({
          show_deleted: false,
          full_name: search,
          page,
          size: 15,
        });
      }, 500)
    );
  }, [search]);

  const handleButtonClick = (index: number, action: DialogAction) => () => {
    setSelectedIndex(index);
    setSelectedAction(action);
    dialogToggler();
  };

  const dialogActionHandler = (action: DialogAction) => {
    switch (action) {
      case "delete":
        //eslint-disable-next-line
        return (e: React.MouseEvent<HTMLButtonElement>) => {
          readStatus.data &&
            deleteSystemAdmin({
              user_id: readStatus.data.result.items[selectedIndex].id,
            });
        };
      case "deactivate":
        //eslint-disable-next-line
        return (e: React.MouseEvent<HTMLButtonElement>) => {
          readStatus.data &&
            updateSystemAdmin({
              system_admin_id: readStatus.data.result.items[selectedIndex].id,
              is_active: !readStatus.data.result.items[selectedIndex].is_active,
            });
        };
    }
  };

  const dataForList = useMemo(() => {
    const cols = [
      {
        name: "name",
        title: i18n.listHeaders.name,
      },
      {
        name: "email",
        title: i18n.listHeaders.email,
      },
      {
        name: "id",
        title: i18n.listHeaders.id,
      },
      {
        name: "actions",
        title: "",
      },
    ];
    return readStatus.data
      ? compileListData(
        readStatus.data,
        handleButtonClick,
        navigate,
        palette,
        i18n,
        cols
      )
      : {
        cols,
        rows: [],
      };
  }, [readStatus.data, i18n]);

  if (readStatus.isLoading) return <div>{i18n.loading}</div>;
  if (readStatus.isError) return <div>{errorMessages.unexpected}</div>;
  return (
    <>
      <TextInput
        id="search"
        placeholder={i18n.listHeaders.name}
        icon={<IconSearch size={16} color={palette.grey[400]} />}
        className="mt-8 mb-3"
        onChange={(e) => setSearch(e.target.value)}
      />
      {readStatus.data && (
        <PaginatedComplexList.server
          data={dataForList}
          rowsPerPage={10}
          totalRows={readStatus.data.result.total}
          pages={readStatus.data.result.pages}
          handlePageChange={(page) => setPage(page)}
        />
      )}
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {selectedAction == "deactivate" &&
                (readStatus.data?.result.items[selectedIndex].is_active
                  ? i18n.deactivation
                  : i18n.activation)}
              {selectedAction == "delete" && i18n.deletion}
            </div>
            <div className="font-bold">
              {dataForList.rows[selectedIndex].name.content}
            </div>
            <div>
              <span className="font-bold">ID: </span>
              {dataForList.rows[selectedIndex].id.content}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={dialogActionHandler(selectedAction)}
              >
                {selectedAction === "deactivate" &&
                  (readStatus.data?.result.items[selectedIndex].is_active
                    ? i18n.deactivate
                    : i18n.activate)}
                {selectedAction === "delete" && i18n.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18n.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default SystemAdminList;
