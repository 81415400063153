import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Stack, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import { TextInput } from "shared/ui/form";
import { useLazyEditUserQuery } from "entities/superAdmin";
import { useLazyResetPasswordQuery } from "entities/auth";
import { useLocale } from "entities/i18n";
import { getByErrorCode } from "shared/lib";

const EditSystemAdmin = () => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;
  const i18nErrors = i18nGlobal.errors;
  const [query, queryState] = useLazyEditUserQuery();
  const scriptedRef = useRef(true);
  const navigate = useNavigate();
  const {
    state: { id, email, phone, full_name },
  } = useLocation();

  const [queryResetPassword, statusResetPassword] = useLazyResetPasswordQuery();

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const handleCancel = () => navigate("/system-admin");

  const initialValues = {
    full_name,
    email,
    submit: null,
  };

  const latestValues = useRef(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        full_name: Yup.string().required("Обязательное поле").max(255),
        email: Yup.string().required("Обязательное поле"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (JSON.stringify(latestValues.current) !== JSON.stringify(values)) {
          try {
            await query({ ...values, user_id: id, phone });
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }
        latestValues.current = values;
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-col gap-8">
            <div className="flex flex-row gap-6">
              <div className="w-1/2 flex flex-col gap-6">
                <TextInput
                  fullWidth
                  id="full_name"
                  label={i18n.listHeaders.name}
                  placeholder={i18n.listHeaders.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                />
                {touched.full_name && errors.full_name && (
                  <FormHelperText error>
                    {errors.full_name.toString()}
                  </FormHelperText>
                )}
                <TextInput
                  fullWidth
                  id="email"
                  label={i18n.listHeaders.email}
                  placeholder="agency@agency.xxx"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email && (
                  <FormHelperText error>
                    {errors.email.toString()}
                  </FormHelperText>
                )}
                {errors.submit && (
                  <FormHelperText error>{errors.submit}</FormHelperText>
                )}
                {isSubmitting && (
                  <FormHelperText>{i18n.submitting}</FormHelperText>
                )}
              </div>
              <div className="pt-7">
                <Button
                  disableElevation
                  disabled={
                    statusResetPassword.isLoading ||
                    statusResetPassword.data !== undefined
                  }
                  onClick={() => queryResetPassword(values.email)}
                  size="large"
                  variant="outlined"
                  className="bg-transparent border-primary text-primary normal-case"
                >
                  {i18n.resetPassword}
                </Button>
                <span className="block text-black text-sm mt-5">
                  {statusResetPassword.data &&
                    (statusResetPassword.data.status_code === 0
                      ? i18n.resetPasswordSuccess 
                      : i18nErrors[
                        getByErrorCode(statusResetPassword.data.status_code)
                      ])}
                </span>
              </div>
            </div>
            <Stack direction="row" spacing="2rem" width="20rem">
              <Button
                disableElevation
                type="submit"
                disabled={
                  statusResetPassword.isLoading ||
                  statusResetPassword.data !== undefined
                }
                fullWidth
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
              >
                {i18n.save}
              </Button>

              <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                variant="outlined"
                className="bg-primary text-white normal-case"
                onClick={handleCancel}
              >
                {i18n.cancel}
              </Button>
            </Stack>
            <span>
              {queryState.data?.status_code == 0 &&
                i18n.editAdminSuccess}
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditSystemAdmin;
