import { EditClient } from "features/clients";

export const ClientInfo = (
  props: ClientWidgetProps & { client: Client; handleCancel: () => any }
) => (
  <EditClient
    id={props.clientId}
    client={props.client}
    handleCancel={props.handleCancel}
  />
);