/* eslint-disable indent */
import { useEffect, useMemo, useState } from "react";
import {
  IconAd,
  IconArrowLeft,
  // IconCalendar,
  // IconSearch,
  IconUserCircle,
} from "@tabler/icons-react";
import { IconButton, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  DashboardBtns,
  DashboardCharts,
  DashboardFilters,
  DashboardTable,
  FiltersState,
  initialFiltersState,
} from "widgets/dashboard";
// import { useDashboardTable } from "entities/data";
import { getDashboardContent } from "entities/data";
import { useReadStatisticsQuery } from "entities/stats";
import { useAgency } from "entities/admin";
import {
  ResponsePlanFactMediaplan,
  useLazyGetMediaplanRowQuery,
  usePlanFactMediaplanQuery,
} from "entities/mediaplan";

import { useAllowedAccess } from "shared/lib";

import { CommonLayout } from "shared/ui/layout";
import { TabBox } from "shared/ui/tabs";
import { SwitchBox } from "shared/ui/components";
import { formatDate } from "shared/lib/dates";
import { CampaignDialog } from "widgets/dashboard";

const adPricingOptions = ["CPA", "CPC", "CPM", "CPI", "CPV"];

const Dashboard = ({ userRole }: { userRole: "admin" | "director" }) => {
  const allowedAccess = useAllowedAccess();

  const theme = useTheme();
  const location = useLocation();

  const clientName =
    (location.state?.client?.name || sessionStorage.getItem("clientName")) ??
    "";

  useEffect(() => {
    clientName && sessionStorage.setItem("clientName", clientName);
  }, []);

  const params = useParams();
  const clientId = params["client"];

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  const [filters, setFilters] = useState<FiltersState>(initialFiltersState);
  const [campaignDialogVisible, setCampaignDialogVisible] = useState(false);
  const [campaignDialogMode, setCampaignDialogMode] = useState<"add" | "edit">(
    "add",
  );
  const [campaignDialogId, setCampaignDialogId] = useState<number | null>(null);

  // TODO: get stats by dates and filters for the client
  const agency = useAgency();
  const [pageNumber, setPageNumber] = useState(1);

  // ! Этот эндпоинт старый, со старой схемой данных.
  // ! Данные из него шли и в таблицу, и в графики.
  // ! Теперь пока что данные для таблицы и графиков разные.
  // ! Ниже будет новый эндпоинт для таблицы -- usePlanFactMediaplanQuery
  const res = useReadStatisticsQuery(
    {
      agency_id:
        userRole === "admin" ? Number(params["agency"]) : agency.agencyId || -1,
      client_id: Number(clientId),
      page: pageNumber,
      size: 20,
    },
    {
      skip: userRole !== "admin" && !agency.agencyId,
      refetchOnMountOrArgChange: true,
    },
  );
  const { data, isLoading, error, isUninitialized, refetch } = res;

  // ! Этот эндпоинт новый, с новой схемой данных.
  const planFactRes = usePlanFactMediaplanQuery(
    {
      agency_id:
        userRole === "admin" ? Number(params["agency"]) : agency.agencyId || -1,
      client_id: Number(clientId),
      ad_cabinet:
        !filters.cabinet || filters.cabinet === "Все"
          ? undefined
          : filters.cabinet,
      mediaplan:
        filters.mediaplan && filters.mediaplan !== "Все"
          ? filters.mediaplan
          : undefined,
      brand:
        filters.brand && filters.brand !== "Все" ? filters.brand : undefined,
      status:
        filters.status && filters.status !== "Все" ? filters.status : undefined,
      campaign_name: filters.campaign || undefined,
      filter_end_date: filters.endDate
        ? formatDate(filters.endDate, "yyyy-MM-dd")
        : undefined,
      filter_start_date: filters.startDate
        ? formatDate(filters.startDate, "yyyy-MM-dd")
        : undefined,
    },
    { skip: userRole !== "admin" && !agency.agencyId },
  );

  //! Старый эндпоинт (/statistics)
  useEffect(() => {
    if (isUninitialized) return;
    refetch();
  }, [clientId, agency.agencyId]);

  //! Новый эндпоинт (/plan-fact)
  useEffect(() => {
    if (planFactRes.isUninitialized) return;
    refetch();
  }, [clientId, agency.agencyId]);

  //На основе данных с сервера формируем контент для отображения.
  const calculatedContent = useMemo(() => {
    if (!data?.result?.items) return;
    if (data.status_code !== 0) return;
    if (data.result?.items?.length === 0) return;

    return getDashboardContent(
      /* data.result.items */ (data.result?.items as StatEntry[]).slice(0, 5),
      theme,
    );
  }, [data]);

  const handleEditRow = (id: number) => {
    setCampaignDialogId(id);
    setCampaignDialogMode("edit");
    setCampaignDialogVisible(true);
  };

  const handleAddCampaign = () => {
    setCampaignDialogMode("add");
    setCampaignDialogVisible(true);
  };

  const [
    getMediaplanRow,
    {
      data: mediaplanRowData,
      isLoading: mediaplanRowIsLoading,
      error: mediaplanRowError,
    },
  ] = useLazyGetMediaplanRowQuery();

  // const extractPlanValues = (data: ResponsePlanFactMediaplan, id: number) => {
  //   return {
  //     // campaign: data.result.items.find((item) => item.id === id)?.campaign_name,
  //     // cabinet: data.result.items.find((item) => item.id === id)?.ad_cabinet,
  //     // brand: data.result.items.find((item) => item.id === id)?.brand,
  //     // mediaplan: data.result.items.find((item) => item.id === id)?.mediaplan,
  //     // status: data.result.items.find((item) => item.id === id)?.status,
  //     // startDate: data.result.items.find((item) => item.id === id)?.start_date,
  //     // endDate: data.result.items.find((item) => item.id === id)?.end_date,
  //     campaignName: data.result.items[id - 1].campaign.name,
  //     cabinet: data.result.items[id - 1].cabinet.name,
  //     brand: data.result.items[id - 1].brand,
  //     status: data.result.items[id - 1].status,
  //     startDate: new Date(data.result.items[id - 1].date.start_date),
  //     finishDate: new Date(data.result.items[id - 1].date.end_date),
  //     budget: data.result.items[id - 1].budget.plan,
  //     currency: data.result.items[id - 1].currency.toUpperCase() as Currency,
  //     keyMetric: data.result.items[id - 1].ad_pricing,
  //     purchaseUnit: data.result.items[id - 1].ad_pricing,
  //   } as Omit<PlanValuesEntry, "id">;
  // }

  useEffect(() => {
    if (
      typeof campaignDialogId === "number" &&
      planFactRes.data?.status_code === 0
    ) {
      getMediaplanRow({
        mediaplan_id:
          planFactRes.data.result?.items[campaignDialogId]?.mediaplan.id,
      });
    }
  }, [campaignDialogId]);

  console.log({ data });

  return allowedAccess ? (
    <>
      <CommonLayout
        className="bg-grey-100"
        title={
          <>
            <IconButton className="mr-2" onClick={handleBack}>
              <IconArrowLeft size={18} className="inline" />
            </IconButton>
            <h1 className="leading-0 m-0 p-0 inline">{clientName}</h1>
          </>
        }
        btns={
          <DashboardBtns
            clientId={Number(clientId)}
            agencyId={Number(params["agency"] || agency.agencyId)}
            onAddCampaign={handleAddCampaign}
            disableAddBtn={data?.result?.items?.length === 0}
          />
        }
      >
        <TabBox
          contentTabs={[
            {
              title: "Кампании",
              icon: <IconAd strokeWidth={1.4} size={14} color="black" />,
              content:
                //! Uncomment for switch to graphs
                //! Раскомментировать для переключения на графики

                /*<SwitchBox
                      uncheckedNode={*/ planFactRes.data && (
                  <>
                    <DashboardFilters
                      userRole={userRole}
                      onChange={setFilters}
                    />
                    <DashboardTable
                      res={{
                        data: planFactRes.data,
                        isLoading: planFactRes.isLoading,
                        error: planFactRes.error,
                      }}
                      clientId={clientId}
                      agencyId={
                        userRole === "admin"
                          ? params["agency"]
                          : agency.agencyId?.toString()
                      }
                      fetchDataPage={(page) => {
                        setPageNumber(page + 1);
                      }}
                      onEditRow={handleEditRow}
                      userRole={userRole}
                      className="flex flex-col flex-auto"
                    />
                  </>
                ),
              //! Uncomment for switch to graphs
              //! Раскомментировать для переключения на графики

              /*}
                      uncheckedTitle="Таблицей"
                      checkedNode={
                        <DashboardCharts
                          content={calculatedContent}
                          isLoading={isLoading}
                          //FIXME: some problems with error type
                          //!temporary workaround
                          error={Boolean(error)}
                        />
                      }
                      checkedTitle="Графиками"
                      className="flex flex-col flex-auto"
                    />*/
            },
            {
              title: "Пользователи",
              icon: (
                <IconUserCircle strokeWidth={1.4} size={14} color="black" />
              ),
              content: <>Пользователи</>,
            },
          ]}
        />
      </CommonLayout>

      {userRole === "director" &&
        ((() => {
          const visibleValue =
            campaignDialogVisible &&
            campaignDialogId &&
            mediaplanRowData?.result?.id ==
              planFactRes.data?.result?.items[campaignDialogId]?.mediaplan
                ?.id &&
            !mediaplanRowIsLoading;
          const boolConverted = !!(visibleValue === 0 || visibleValue);
          return boolConverted;
        })() ||
          (campaignDialogMode === "add" && campaignDialogVisible)) && (
          <CampaignDialog
            toggler={() => {
              setCampaignDialogVisible(!campaignDialogVisible);
            }}
            mode={campaignDialogMode}
            campaign={
              campaignDialogMode === "edit" &&
              mediaplanRowData?.status_code === 0
                ? mediaplanRowData.result.data
                : null
            }
            mediaplanId={
              campaignDialogMode === "edit" &&
              mediaplanRowData?.status_code === 0
                ? mediaplanRowData.result.id
                : null
            }
            mediaplanName={
              campaignDialogMode === "edit" &&
              mediaplanRowData?.status_code === 0
                ? mediaplanRowData.result.name
                : null
            }
            autocomplete={{
              //TODO: replace planFactRes with filters options from DashboardFilters
              cabinet: {
                freeSolo: false,
                options: Array.from(
                  new Set(
                    planFactRes.data?.result?.items?.map(
                      (item) => item.cabinet.name,
                    ) || [],
                  ),
                ),
              },
              brand: {
                freeSolo: true,
                options: Array.from(
                  new Set(
                    planFactRes.data?.result?.items?.map(
                      (item) => item.brand,
                    ) || [],
                  ),
                ),
              },
              status: {
                freeSolo: true,
                options: Array.from(
                  new Set(
                    planFactRes.data?.result?.items?.map(
                      (item) => item.status,
                    ) || [],
                  ),
                ),
              },
              purchaseUnit: {
                freeSolo: false,
                options: adPricingOptions,
              },
            }}
            isUninitialized={planFactRes.isUninitialized}
            refetch={planFactRes.refetch}
          />
        )}
    </>
  ) : (
    <div>{"redirecting you soon..."}</div>
  );
};

export default Dashboard;
