import { Palette } from "@mui/material";
import { ColumnName } from "./getColumns";
import { CabinetBadge } from "shared/ui/icons";
import { adsSystems } from "shared/constants";
import * as metricsLib from "shared/lib/metrics";
import { IconEdit } from "@tabler/icons-react";


export const campTransformer = (
  palette: Palette,
  userRole: "admin" | "director",
  campaignId: string,
  clientId: string,
  agencyId?: string,
) => {
  return (
    stats: PlanFactEntryAd,
    statsImmutable: PlanFactEntryAd,
    rowIndex: number,
    setters: SettersType,
  ) => {
    const row = {} as Record<ColumnName, React.ReactNode>;

    // const { brand, mediaplan, campaign, cabinet } = stats;
    // row.id = rowIndex + 1;
    // row.brand = brand;
    // row.campaignId = campaign.id;
    // // row.campaign = campaign.name;
    // row.campaign =
    //   ((userRole === "admin" && agencyId && clientId) || (userRole === "director" && clientId)) && campaign.id ? (
    //     getCampaignLink(userRole, campaign, clientId, agencyId)
    //   ) : (
    //     campaign.name
    //   );
    // // row.mediaplanId = <Link to={`/mediaplan/${mediaplan.id}`} className="text-black">{mediaplan.id}</Link>;
    // row.mediaplanId = mediaplan.id;
    // // row.mediaplan = mediaplan.type;
    // row.mediaplan = mediaplan.name;
    // row.cabinet = (
    //   <CabinetBadge
    //     icon={cabinet.type as adsSystems}
    //     color="primary"
    //     title={cabinet.name}
    //   />
    // );

    const { cabinet, brand } = stats;

    row.id = rowIndex + 1;
    row.cabinet = (
      <CabinetBadge
        icon={cabinet.type as adsSystems}
        color="primary"
        title={cabinet.name}
      />
    );
    row.brand = brand;

    const { ad, ad_group, geo, social } = stats;
    row.ad_group = ad_group;
    row.ad = ad.toString();

    row.geo = geo;
    row.socDem = social;

    const { status, start_date, end_date, days, ad_pricing, currency } = stats;
    row.status = status;
    const [startDate, endDate] = [
      // new Date(date.start_date),
      // new Date(date.date_end),
      start_date,
      end_date,
    ];
    row.date = `${startDate} - ${endDate}`;
    // row.date = `${datesLib.formatDate(startDate)} - ${datesLib.formatDate(
    //   endDate,
    // )}`;
    row.daysPassed = days;
    row.purchaseUnit = ad_pricing;
    // todo: row.currency needs a picker and counting redone
    row.currency = currency;

    const { budget } = stats;
    row.budget = budget ? metricsLib.prettifyNumber(budget, 2) : null;

    //import all KPIs from stats
    const { kpi_cpa, kpi_cpc, kpi_cpm, kpi_cpu, kpi_ctr, kpi_cpv, kpi_vtr } =
      stats;

    row.cpa = kpi_cpa
      ? metricsLib.prettifyNumber(Number(kpi_cpa.toFixed(2)))
      : null;
    row.cpc = kpi_cpc
      ? metricsLib.prettifyNumber(Number(kpi_cpc.toFixed(2)))
      : null;
    row.cpm = kpi_cpm
      ? metricsLib.prettifyNumber(Number(kpi_cpm.toFixed(2)))
      : null;
    row.cpu = kpi_cpu
      ? metricsLib.prettifyNumber(Number(kpi_cpu.toFixed(2)))
      : null;
    row.ctr = kpi_ctr
      ? metricsLib.prettifyNumber(Number(kpi_ctr.toFixed(2)))
      : null;
    row.cpv = kpi_cpv
      ? metricsLib.prettifyNumber(Number(kpi_cpv.toFixed(2)))
      : null;
    row.vtr = kpi_vtr
      ? metricsLib.prettifyNumber(Number(kpi_vtr.toFixed(2)))
      : null;

    const { impressions, clicks, conversions, installs, frequency, reach } =
      stats;

    row.impressions = impressions
      ? metricsLib.prettifyNumber(Math.floor(impressions))
      : null;
    row.clicks = clicks ? metricsLib.prettifyNumber(Math.floor(clicks)) : null;
    row.conversions = conversions
      ? metricsLib.prettifyNumber(Math.floor(conversions))
      : null;
    row.installs = installs
      ? metricsLib.prettifyNumber(Math.floor(installs))
      : null;
    row.frequency = frequency ? metricsLib.prettifyNumber(frequency, 2) : null;
    row.reach = reach ? metricsLib.prettifyNumber(Math.floor(reach)) : null;

    row.edit = <IconEdit strokeWidth={1.5} color={"#BDBDBD"} size={18} />;

    (Object.keys(row) as ColumnName[]).forEach((key) => {
      if (row[key] === null || row[key] === undefined) {
        row[key] = /* long hyphen character, please do not delete */ "—";
      }
    });

    return row;
  };
};

export default campTransformer;
