import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";

import Palette from "./palette";
import CustomShadows from "./shadows";

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useMemo(() => Palette(), []);

  const themeCustomShadows = useMemo(() => {
    const shadowsObj = CustomShadows(theme)
    const shadowsArr = ["none", ...Object.values(shadowsObj)]
    const emptiesToPush = 25 - shadowsArr.length
    for (let i = 0; i < emptiesToPush; i++) shadowsArr.push("none")
    return shadowsArr as Theme["shadows"]
  }, [theme]);
  
  const themeOptions = useMemo(() => {
    return {
      palette: theme.palette,
      shadows: themeCustomShadows,
    };
  }, [theme, themeCustomShadows]);

  const themes = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
