import { MainLayout, ProfileMenu } from "widgets";
import { LigthRoutes } from "shared/types";
import { AuthGuard } from "entities/auth/ui";
import { LocalePicker } from "features/i18n";
import { AgencyList, CreateAgency, Agency, CreateUser } from "pages/admin";
import Dashboard from "pages/dashboard";
import { CreateClient, EditClient } from "pages/clients";
import { UserProfile } from "pages/admin";

const AdminRoutes: LigthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout
            headerWidget={
              <div className="flex flex-row gap-8">
                <LocalePicker />
                <ProfileMenu />
              </div>
            }
          />
        </AuthGuard>
      ),
      children: [
        {
          path: "/admin/agencies",
          element: <AgencyList />,
        },
        {
          path: "/admin/agencies/create",
          element: <CreateAgency />,
        },
        {
          path: "/admin/agencies/:id/info",
          element: <Agency activeTab="info" />,
        },
        {
          path: "/admin/agencies/:id/clients",
          element: <Agency activeTab="clients" />,
        },
        {
          path: "/admin/agencies/:id/clients/create",
          element: <CreateClient />,
        },
        {
          path: "/admin/agencies/:agencyId/clients/:clientId",
          element: <EditClient />,
        },
        {
          path: "/admin/clients/:clientId",
          element: <EditClient />,
        },
        {
          path: "/admin/agencies/:id/users",
          element: <Agency activeTab="users" />,
        },
        {
          path: "/admin/agencies/:id/users/create",
          element: <CreateUser userRole="admin"/>,
        },
        {
          path: "/admin/agencies/:id/users/manage",
          element: <UserProfile userRole="admin" />,
        },
        {
          path: "/admin/agencies/:id/api",
          element: <Agency activeTab="api" />,
        },
        {
          path: "/admin/agencies/:id",
          element: <Agency />,
        },
        {
          path: "/admin",
          element: <AgencyList />,
        },
        {
          path: "admin/dashboard/:agency/:client",
          element: <Dashboard userRole="admin"/>,
        }
      ],
    },
  ],
};

export default AdminRoutes;
