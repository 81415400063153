import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
// import { GlobalState } from "shared/types";
import {
  // PUBLIC_API_URL,
  QUERY_UPDATE_USER_IN_AGENCY,
  QUERY_DELETE_USER_IN_AGENCY,
  QUERY_CREATE_USER_IN_AGENCY,
  QUERY_READ_ALL_USERS_BY_AGENCY,
  QUERY_READ_USER_BY_EMAIL,
  QUERY_UPDATE_USER_STATUS,
  QUERY_UPDATE_USER,
} from "shared/constants";
import { User } from "shared/types";

// export const AgencyUsersApi = createApi({
export const AgencyUsersApi = baseApi.injectEndpoints({
  // reducerPath: "AgencyUsersApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   // prepareHeaders: (headers) => {
  //   //   const token = getMemory("serviseAuth");
  //   //   if (token) {
  //   //     headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //   //   }
  //   //   return headers;
  //   // },
  //   prepareHeaders(headers, { getState }) {
  //     const state = getState() as GlobalState;
  //     headers.append("Authorization", `Bearer ${state.auth.accessToken}`)
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    createAgencyUserFromExisting: builder.mutation<
      ResponseCommon,
      RequestCreateAgencyUserFromExisting
    >({
      query: (data) => ({
        url: QUERY_CREATE_USER_IN_AGENCY,
        method: "POST",
        body: { ...data },
      }),
    }),
    createAgencyUserFromScratch: builder.mutation<
      ResponseCommon,
      RequestCreateAgencyUserFromScratch
    >({
      query: (data) => ({
        url: QUERY_CREATE_USER_IN_AGENCY,
        method: "POST",
        body: { ...data },
      }),
    }),
    readAgencyUsers: builder.query<
      ResponseReadAgencyUsers,
      {
        agency_id: number;
        full_name?: string;
        client_name?: string;
        page?: number;
        size?: number;
      }
    >({
      query: (data) => ({
        url: QUERY_READ_ALL_USERS_BY_AGENCY,
        method: "GET",
        params: { ...data },
      }),
    }),
    deleteAgencyUser: builder.mutation<
      ResponseCommon,
      { agency_id: number; user_id: number }
    >({
      query: (data) => ({
        url: QUERY_DELETE_USER_IN_AGENCY,
        method: "DELETE",
        params: { ...data },
      }),
    }),
    updateAgencyUserClients: builder.mutation<
      ResponseCommon,
      RequestUpdateAgencyUserClients
    >({
      query: (data) => ({
        url: QUERY_UPDATE_USER_IN_AGENCY,
        method: "PUT",
        body: { ...data },
      }),
    }),
    readUserByEmail: builder.query<
      Omit<ResponseCommon, "result"> & { result: User },
      { email: string }
    >({
      query: (data) => ({
        url: QUERY_READ_USER_BY_EMAIL,
        method: "GET",
        params: { ...data },
      }),
    }),
    updateUserStatus: builder.mutation<
      Omit<ResponseCommon, "result">,
      { user_id: number; is_active: boolean }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_USER_STATUS,
        method: "PUT",
        body: { ...data },
      }),
    }),
    updateUser: builder.mutation<
      Omit<ResponseCommon, "result"> & { result: User },
      { user_id: number; phone: string; full_name: string; email: string }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_USER,
        method: "PUT",
        params: { user_id: data.user_id },
        body: { ...data, user_id: undefined },
      }),
    }),
  }),
});

export const {
  useCreateAgencyUserFromExistingMutation,
  useCreateAgencyUserFromScratchMutation,
  useReadAgencyUsersQuery,
  useLazyReadUserByEmailQuery,
  useReadUserByEmailQuery,
  useDeleteAgencyUserMutation,
  useUpdateUserStatusMutation,
  useUpdateUserMutation,
} = AgencyUsersApi;
