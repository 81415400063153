import { useEffect, useRef } from "react";
import { useLazyResetPasswordQuery } from "entities/auth";

import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  FormLabel,
} from "@mui/material";
import MuiIconButton from "@mui/material/IconButton";
import { IconAt } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { AnimateButton } from "shared/ui";
import { SuccessSendEmail } from "./ui";
import { useLocale } from "entities/i18n";

// ============================|| JWT - LOGIN ||============================ //

export default () => {
  const i18n = useLocale();
  const theme = useTheme();
  const [queryResetPassword, { isLoading, isError, data }] = useLazyResetPasswordQuery();

  const iconProps = {
    color: theme.palette.grey[400],
    size: 20,
    strokeWidth: 2.5,
  };


  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    [],
  );

  const stackProps: any = { 
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    spacing: 2,
    className: "font-inter",
  }

  const typographyProps: any = {
    variant: "subtitle2",
    color: "dark",
    component: "span",
    justifyContent: "center",
    alignItems: "center",
    className: "font-inter",
  }

  return (
    <Formik
      initialValues={{
        submit: null,
        // email: "root@gmail.com"
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(i18n.auth.errors.emailFormat)
          .max(255)
          .required(i18n.auth.errors.fieldRequired),
      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          queryResetPassword(values.email);
          if (scriptedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ email: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="font-open-sans">
            <Grid item xs={12}>
              <Stack {...stackProps}>
                <Typography {...typographyProps}
                  sx={{
                    fontWeight: 700,
                    fontSize: "36px",
                  }}
                >
                  {i18n.auth.passwordRecovery}
                </Typography>
              </Stack>
            </Grid> 
            {data?.status_code && data?.status_code === 0 ? (<SuccessSendEmail email={values.email} />):(
              <>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <FormLabel>
                      {i18n.auth.enterEmail}
                    </FormLabel>
                    <OutlinedInput
                      id="email-login"
                      type="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="E-mail"
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      startAdornment={
                        <InputAdornment position="start">
                          <MuiIconButton
                            aria-label="toggle email visibility"
                            edge="start"
                          >
                            <IconAt {...iconProps} />
                          </MuiIconButton>
                        </InputAdornment>
                      }
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                    {isError && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {i18n.auth.errors.wrongCredentials}
                      </FormHelperText>
                    )}
                    {data?.status_code && data.status_code > 0 && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {data.message}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item sx={{
                  width: "100vh",
                  xs:12,
                  mt:5,
                }}>
                  <AnimateButton
                    type="scale"
                    direction="right"
                    offset={0}
                    scale={{
                      hover: 1.05,
                      tap: 0.95,
                    }}
                  >
                    <Button
                      disableElevation
                      disabled={isLoading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className="bg-primary text-white normal-case"
                    >
                      {i18n.auth.passwordRecoveryButtonTitle}
                    </Button>
                  </AnimateButton>
                </Grid>
                <Grid item xs={12} sx={{ mt: -1 }}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    className="font-inter"
                  >
                    <Link
                      component={RouterLink}
                      to="/auth"
                      color="text.primary"
                      className="no-underline"
                    >
                      {i18n.auth.entrance}
                    </Link>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};
