import Routes from "app/routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { JWTProvider } from "./context";
import { ThemeCustomization } from "shared/ui/theme";
import { useCurrentLocale, useFetchLocale } from "entities/i18n";
  
function App() {

  //Селектор для получения текущей локали
  const currentLocale = useCurrentLocale();
  //Хук для подгрузки локали в стор
  useFetchLocale(currentLocale);

  return (
    <ThemeCustomization>
      <Provider store={store}>
        <JWTProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </JWTProvider>
      </Provider>
    </ThemeCustomization>
  );
}

export default App;