import { useLocale } from "entities/i18n";

export default () => {
  const { admin } = useLocale();

  return (
    <div className="fixed z-50 top-0 left-0 h-full w-full bg-[rgba(240,240,240,0.7)] flex justify-center items-center">
      <div className="max-w-1/2">
        <div className="font-bold text-[#D00000] text-xl text-center">
          {admin.success}
        </div>
        <div className="font-bold text-[#D00000] text-xl text-center">
          {admin.success2}
        </div>
        <div className="font-bold text-[#D00000] text-xl text-center">
          {admin.success3}
        </div>
      </div>
    </div>
  );
};
