import { Box } from "@mui/system";
import { PaginatedComplexList } from "shared/ui/list";
import { BtnsManageClient } from "./BtnsManageClient";
import { Link } from "react-router-dom";
import { Dialog, useDashboardDialog } from "shared/ui/components";
import { Button } from "@mui/material";
import { useLocale } from "entities/i18n";
import {
  useDeleteClientMutation,
  useUpdateClientStatusMutation,
} from "entities/clients";
import { useState } from "react";

type Props = {
  clientsResult: PaginatedResult<Client>;
  agencyId: number;
  refetch: () => void;
  isUninitialized: boolean;
  handlePageChange: (page: number) => void;
  setTab: (tab: string) => void;
};

export const ClientsList = ({
  clientsResult,
  agencyId,
  refetch,
  isUninitialized,
  handlePageChange,
  setTab
}: Props) => {
  const {
    action: selectedAction,
    index: selectedIndex,
    dialogToggler,
    dialogVisible
  } = useDashboardDialog();

  const { admin: i18n } = useLocale();

  const [updateStatusMutation] = useUpdateClientStatusMutation();
  const [deleteMutation] = useDeleteClientMutation();
  const [refetchTimeout, setRefetchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const dialogActionHandler = () => {
    if (selectedAction.get() == "deactivate") {
      return () => {
        dialogToggler();
        updateStatusMutation({
          client_id: clientsResult.items[selectedIndex.get()].id,
          is_active: !clientsResult.items[selectedIndex.get()].is_active,
          agency_id: agencyId,
        })
          .unwrap()
          .then(() => {
            if (refetchTimeout) {
              clearTimeout(refetchTimeout);
            }
            !isUninitialized &&
              setRefetchTimeout(
                setTimeout(() => {
                  refetch();
                }, 500)
              );
          });
      };
    } else if (selectedAction.get() == "delete") {
      return () => {
        dialogToggler();
        deleteMutation({
          client_id: clientsResult.items[selectedIndex.get()].id,
          agency_id: agencyId,
        })
          .unwrap()
          .then(() => {
            if (refetchTimeout) {
              clearTimeout(refetchTimeout);
            }
            !isUninitialized &&
              setRefetchTimeout(
                setTimeout(() => {
                  refetch();
                }, 500)
              );
          });
      };
    }
  };

  const dialogOpenHandler = (action: DialogAction, index: number) => () => {
    selectedAction.set(action);
    selectedIndex.set(index);
    dialogToggler();
  };

  return (
    <>
      <PaginatedComplexList.server
        rowsPerPage={10}
        totalRows={clientsResult.total}
        pages={clientsResult.pages}
        handlePageChange={handlePageChange}
        data={{
          cols: [
            {
              name: "clientName",
              title: i18n.clientName,
            },
            {
              name: "bin",
              title: i18n.BIN,
            },
            {
              name: "agencies",
              title: i18n.adCabinets,
            },
            {
              name: "btns",
              title: "",
            },
          ],
          rows: clientsResult.items.map((cl, i) => {
            return {
              clientName: {
                content: (
                  <Link
                    to={`/admin/dashboard/${agencyId}/${cl.id}`}
                    state={{ client_name: cl.name }}
                    className={`text-${
                      cl.is_active ? "black" : "grey-400"
                    }`}
                  >
                    {cl.name}
                  </Link>
                ),
              },
              bin: {
                content: (
                  <span
                    className={`text-${
                      cl.is_active ? "black" : "grey-400"
                    }`}
                  >
                    {cl.bin}
                  </span>
                ),
              },
              agencies: {
                content: <Box> </Box>,
              },
              btns: {
                content: (
                  <BtnsManageClient
                    agencyId={agencyId}
                    client={cl}
                    index={i}
                    setTab={setTab}
                    dialogOpenHandler={dialogOpenHandler}
                  />
                ),
              },
            };
          }),
        }}
      />
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {selectedAction.get() == "deactivate" &&
                (clientsResult.items[selectedIndex.get()].is_active
                  ? i18n.clients.deactivation
                  : i18n.clients.activation)}
              {selectedAction.get() == "delete" && i18n.clients.deletion}
            </div>
            <div className="font-bold">{clientsResult.items[selectedIndex.get()].name}</div>
            <div>
              <span className="font-bold">{i18n.BIN}: </span>
              {clientsResult.items.length > 0 ? clientsResult.items[selectedIndex.get()].bin : ""}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={dialogActionHandler()}
              >
                {selectedAction.get() === "deactivate" &&
                  (clientsResult.items[selectedIndex.get()].is_active
                    ? i18n.deactivate
                    : i18n.activate)}
                {selectedAction.get() === "delete" && i18n.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18n.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
