import { useEffect, useMemo, useState } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import {
  InstructionFb,
  InstructionYandex,
  InstructionVK,
  InstructionGAds,
  InstructionTT,
} from "./model";
import { useReadClientsByAgencyQuery } from "entities/clients";
import { adsSystems } from "shared/constants";

const i18nLoc = {
  client: "Client",
  adsSystem: "Ads System",
};

const adsSystemsList = [
  { label: "Yandex", value: adsSystems.yandex },
  { label: "facebook", value: adsSystems.fb },
  { label: "VK", value: adsSystems.vk },
  { label: "Google", value: adsSystems.google },
  { label: "TikTok", value: adsSystems.tiktok },
];

export const CreateApiForm = ({
  agencyId,
  clientId,
}: {
  agencyId: number;
  clientId: number;
}) => {
  const [adsSystemType, setadsSystemType] = useState<string>("");
  const [client_id, setclientId] = useState<number>(clientId);
  const [clients, setclients] = useState<Client[]>([]);

  const { data, isLoading } = useReadClientsByAgencyQuery(
    {
      // agency_id: agencyId as unknown as number,
      agency_id: agencyId,
    },
    { skip: !agencyId },
  );

  useEffect(() => {
    //No need to set list of clients
    if (clientId || isLoading) return;

    if (data?.message === "Success") {
      setclients(data.result.items);
    }
  }, [isLoading, data]);

  const handleAdsSystemTypeChoose = (_e: any, val: any) =>
    setadsSystemType(val ? val.value : null);

  const handleClientChoose = (_e: any, val: any) =>
    setclientId(val ? val.id : null);

  const clientsList = useMemo(() => {
    const result: { label: String; id: number }[] = [];

    clients.forEach((cl) => result.push({ label: cl.name, id: cl.id }));

    return result;
  }, [clients]);

  const getInstructions = (adsSystemType: string | null) => {
    switch (adsSystemType) {
      case adsSystems.yandex:
        return <InstructionYandex agencyId={agencyId} clientId={client_id} />;
      case adsSystems.fb:
        return <InstructionFb agencyId={agencyId} clientId={client_id} />;
      case adsSystems.vk:
        return <InstructionVK agencyId={agencyId} clientId={client_id} />;
      case adsSystems.google:
        return <InstructionGAds agencyId={agencyId} clientId={client_id} />;
      case adsSystems.tiktok:
        return <InstructionTT agencyId={agencyId} clientId={client_id} />;
      default:
        return null;
    }
  };

  return (
    <Box className="py-5">
      <Box className="choose flex gap-8">
        {clientId ? null : ( //no need to choose client id
          <div>
            <h4 className="m-0 mb-1 ml-1">{i18nLoc.client}</h4>
            <Autocomplete
              className="bg-white w-80"
              disablePortal
              options={clientsList}
              onChange={handleClientChoose}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        )}

        <div>
          <h4 className="m-0 mb-1 ml-1">{i18nLoc.adsSystem}</h4>
          <Autocomplete
            className="bg-white w-80"
            disablePortal
            options={adsSystemsList}
            onChange={handleAdsSystemTypeChoose}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </Box>
      <Box className="instructions-box mt-5">
        {getInstructions(adsSystemType)}
      </Box>
    </Box>
  );
};
