import { Backdrop } from "@mui/material";
import { useState } from "react";

type DialogProps = {
  toggler: () => void;
  children: React.ReactNode;
};

export const useDashboardDialog = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedAction, setSelectedAction] =
    useState<DialogAction>("deactivate");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const dialogToggler = () => setDialogVisible(!dialogVisible);

  return {
    dialogVisible,
    dialogToggler,
    action: { get: () => selectedAction, set: setSelectedAction },
    index: { get: () => selectedIndex, set: setSelectedIndex },
  };
};

//eslint-disable-next-line
export const Dialog = (props: DialogProps) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 50 }}
        onClick={props.toggler}
        open={true}
      >
      </Backdrop>
      <div className="bg-white rounded-lg py-4 px-6 text-black z-[100] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {props.children}
      </div>
    </>
  );
};
