import { format } from "date-fns";

const formatDate = (date: Date, template?: string) => {
  try {
    return format(date, template || "dd.MM.yyyy");
  } catch (err) {
    console.error({ err });
    return date as unknown as string;
  }
};

const formatBetweenDates = (date1: Date, date2: Date, template?: string) => {
  return `${format(date1, template || "dd.MM.yyyy")} — ${format(
    date2,
    template || "dd.MM.yyyy",
  )}`;
};

const parseDate = (date: string) => {
  const separator = date.includes(".") ? "." : "-";
  // const [day, month, year] = date.split(separator);
  const [year, month, day] = date.split(separator);
  const validateDate = (day: number, month: number, year: number) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    );
  };
  const [dayNum, monthNum, yearNum] = [
    Number(day),
    Number(month),
    Number(year),
  ];
  if (!validateDate(dayNum, monthNum, yearNum)) {
    throw new Error(
      `Invalid date received - ${date}. Start of UNIX time returned instead.`,
    );
  }
  return new Date(Number(year), Number(month) - 1, Number(day));
};

const getDistanceBetweenDates = (
  date1: string | Date,
  date2: string | Date,
): number => {
  if (typeof date1 === "string") {
    date1 = parseDate(date1) as Date;
  }
  if (typeof date2 === "string") {
    date2 = parseDate(date2) as Date;
  }
  const days: number = Math.floor(
    (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24),
  );
  return days;
};

const getDaysPassed = (startDate: string | Date, endDate: string | Date) => {
  if (typeof startDate === "string") {
    startDate = parseDate(startDate) as Date;
  }
  if (typeof endDate === "string") {
    endDate = parseDate(endDate) as Date;
  }
  const passed = getDistanceFromYesterday(startDate);
  const total = getDistanceBetweenDates(startDate, endDate);
  return `${passed < total ? passed : total}/${total}`;
};

const getDistanceFromYesterday = (date: string | Date) => {
  if (typeof date === "string") {
    date = parseDate(date) as Date;
  }
  const days: number = Math.floor(
    (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  );
  return days - 1;
};

export {
  formatDate,
  getDistanceBetweenDates,
  formatBetweenDates,
  getDistanceFromYesterday,
  getDaysPassed,
  parseDate,
};
