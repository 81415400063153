import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  DirectorByContacts,
  useCreateAgencyBySysAdminMutation,
  useCreateAgencyMutation,
  useUpdateAgencyByAdminMutation,
  useApproveAgencyMutation,
} from "entities/admin";
import { TextInput } from "shared/ui/form";
import RegisterAgencyMessage from "./RegisterAgencyMessage";

import { useAuthSelector } from "entities/auth";
import { useLocale } from "entities/i18n";
import { getByErrorCode } from "shared/lib";

//Применяется в дальнейшем к полям формы, для вывода ошибок
const errorSx = { "& .MuiFormHelperText-root": { color: "red" } };

type Props = {
  type: "create" | "update";
  agency?: ResponseAgency["result"];
  userRole: "admin" | "director";
  handleCancel: () => void;
};

export const AgencyForm = ({ type, agency, userRole, handleCancel }: Props) => {
  const i18nGlobal = useLocale();
  const [i18nErrors, i18n] = [i18nGlobal.errors, i18nGlobal.admin];
  const { user } = useAuthSelector();
  const [
    createAgencyByDirector,
    { data: dataByDirector, isLoading: isLoadingByDirector },
  ] = useCreateAgencyMutation();
  const [
    createAgencyByAdmin,
    { data: dataByAdmin, isLoading: isLoadingByAdmin },
  ] = useCreateAgencyBySysAdminMutation();
  const [
    updateAgencyByAdmin,
    { data: dataUpdateByAdmin, isLoading: isLoadingUpdateByAdmin },
  ] = useUpdateAgencyByAdminMutation();

  const [requiresConfirmation, setRequiresConfirmation] = useState(agency?.confirmed == false);

  const [approveAgency, approveAgencyStatus] = useApproveAgencyMutation();
  const handleApproveAgency = () => {
    agency && approveAgency({ agency_id: agency.id });
  };

  const initialValues: Omit<CreateAgencySchema, "director_id"> &
    DirectorByContacts = {
      bin: agency?.bin ?? "",
      name: agency?.name ?? "",
      organization_name: agency?.organization_name ?? "",
      responsible_full_name: agency?.responsible_full_name ?? "",
      responsible_email: agency?.responsible_email ?? "",
      responsible_phone: agency?.responsible_phone ?? "",
      actual_address: agency?.actual_address ?? "",
      legal_address: agency?.legal_address ?? "",
      iic: agency?.iic ?? "",
      bank: agency?.bank ?? "",
      director_email: agency?.director?.email ?? "",
      director_phone: agency?.director?.phone ?? "",
      director_full_name: agency?.director?.full_name ?? "",
      is_active: agency?.is_active ?? true,
    };
  const [displayFormAccepted, setDisplayFormAccepted] = useState(false);
  const [isEditing, setIsEditing] = useState(type == "create");

  useEffect(() => {
    if (approveAgencyStatus.isSuccess && approveAgencyStatus.data.status_code === 0) {
      setTimeout(() => {
        setRequiresConfirmation(false);
        setIsEditing(false);
      }, 500);
    }
  }, [approveAgencyStatus, handleCancel]);

  const directorEditable = useMemo(
    () =>
      userRole == "admin" &&
      (type == "create" || (type == "update" && isEditing)),
    [userRole, type, isEditing]
  );
  const [directorChanged, setDirectorChanged] = useState(false);

  useEffect(() => {
    setDisplayFormAccepted(dataByDirector?.status_code == 0);
  }, [dataByDirector]);

  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  useEffect(() => {
    if (dataByAdmin?.status_code === 0) {
      setIsEditing(false);
      dataByAdmin.result.id &&
        approveAgency({ agency_id: dataByAdmin?.result?.id });
    }
  }, [dataByAdmin]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        /* Валидация */
        validationSchema={Yup.object({}).shape({
          name: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
          bin: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
          responsible_full_name: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .max(255),
          responsible_email: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .email(i18nErrors.form.emailFormat)
            .max(255),
          responsible_phone: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .matches(/\+7[0-9]{10}/g, i18nErrors.form.phoneFormat)
            .max(12, i18nErrors.form.phoneFormat),
          actual_address: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .max(255),
          organization_name: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .max(255),
          legal_address: Yup.string()
            .required(i18nErrors.form.fieldRequired)
            .max(255),
          iic: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
          bank: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
          director_email: Yup.string()
            .email(i18nErrors.form.emailFormat)
            .max(255),
          director_phone: Yup.string()
            .matches(/\+7[0-9]{10}/g, i18nErrors.form.phoneFormat)
            .max(255),
          director_full_name: Yup.string().max(255),
        })}
        /* Отправка формы */
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            /* Создание агентства директором. */
            userRole == "director" &&
              (await createAgencyByDirector({
                ...values,
                director_id: user ? user.id : -1,
              }));

            /* Создание агентства администратором. */
            userRole == "admin" &&
              type == "create" &&
              (await createAgencyByAdmin({ ...values, confirmed: false }));

            /* Обновление агентства систем-админом */
            const directorEmailEdited =
              values?.director_email != agency?.director?.email;
            setDirectorChanged(directorEmailEdited);
            const overrides = directorEmailEdited
              ? {}
              : {
                director_email: agency?.director.email,
                director_full_name: agency?.director.full_name,
                director_phone: agency?.director.phone,
              };
            userRole == "admin" &&
              type == "update" &&
              isEditing &&
              agency?.id &&
              (await updateAgencyByAdmin({
                ...values,
                ...overrides,
                agency_id: agency?.id,
              }));
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (error: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ name: error.message });
              setSubmitting(false);
            }
            //eslint-disable-next-line no-console
            console.error(error);
          } finally {
            setIsEditing(false);
          }
        }}
      >
        {/* Форма */}
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="flex flex-row gap-[8rem] ">
              <div className="flex flex-col gap-6 w-[35rem] mb-8">
                <h2 className="mb-0 mt-12 leading-0">{i18n.generalInfo}</h2>
                <TextInput
                  id="name"
                  label={i18n.clientName}
                  placeholder={i18n.organizationName}
                  helperText={touched.name ? errors.name : undefined}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.name}
                />
                <TextInput
                  id="bin"
                  label={i18n.BIN}
                  placeholder={i18n.BIN}
                  helperText={touched.bin ? errors.bin : undefined}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.bin}
                />
                <TextInput
                  id="responsible_full_name"
                  label={i18n.responsiblePersonName}
                  placeholder={i18n.responsiblePerson}
                  helperText={
                    touched.responsible_full_name
                      ? errors.responsible_full_name
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.responsible_full_name}
                />
                <TextInput
                  id="responsible_email"
                  label={i18n.responsiblePersonEmail}
                  placeholder="agency@agency.xxx"
                  helperText={
                    touched.responsible_email
                      ? errors.responsible_email
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.responsible_email}
                />
                <TextInput
                  id="responsible_phone"
                  label={i18n.responsiblePersonPhone}
                  placeholder={i18n.phoneNumber}
                  helperText={
                    touched.responsible_phone
                      ? errors.responsible_phone
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.responsible_phone}
                />
                <TextInput
                  id="actual_address"
                  label={i18n.actualAddress}
                  placeholder={i18n.actualOrganizationAddress}
                  helperText={
                    touched.actual_address ? errors.actual_address : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.actual_address}
                />
              </div>
              <div className="flex flex-col gap-6 w-[35rem]">
                <h2 className="mb-0 mt-12 leading-0">{i18n.requisites}</h2>
                <TextInput
                  id="organization_name"
                  label={i18n.organizationFullName}
                  placeholder={i18n.organizationName}
                  helperText={
                    touched.organization_name
                      ? errors.organization_name
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.organization_name}
                />
                <TextInput
                  id="legal_address"
                  label={i18n.legalAddress}
                  placeholder={i18n.legalOrganizationAddress}
                  helperText={
                    touched.legal_address ? errors.legal_address : undefined
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={errorSx}
                  fullWidth
                  disabled={displayFormAccepted || !isEditing}
                  value={values?.legal_address}
                />
                <div className="flex flex-row gap-2 w-full justify-stretch">
                  <TextInput
                    className="w-1/2"
                    id="iic"
                    label={i18n.checkingAccount}
                    placeholder={i18n.checkingAccount}
                    helperText={touched.iic ? errors.iic : undefined}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={errorSx}
                    fullWidth
                    disabled={displayFormAccepted || !isEditing}
                    value={values?.iic}
                  />
                  <TextInput
                    className="w-1/2"
                    id="bank"
                    label={i18n.bank}
                    placeholder={i18n.organizationName}
                    helperText={touched.bank ? errors.bank : undefined}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={errorSx}
                    fullWidth
                    disabled={displayFormAccepted || !isEditing}
                    value={values?.bank}
                  />
                </div>
                <TextInput
                  disabled={!directorEditable || !isEditing}
                  value={
                    userRole == "director"
                      ? type == "create"
                        ? user?.email
                        : agency?.director?.email
                      : values?.director_email
                  }
                  onChange={(e) => {
                    handleChange(e);
                    setDirectorChanged(
                      e.target.value != agency?.director?.email
                    );
                  }}
                  onBlur={handleBlur}
                  id="director_email"
                  label="E-mail"
                  placeholder={i18n.email}
                  sx={errorSx}
                  fullWidth
                />
                <TextInput
                  disabled={!directorEditable || !isEditing}
                  value={
                    userRole == "director"
                      ? type == "create"
                        ? user?.phone
                        : agency?.director?.phone
                      : values?.director_phone
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="director_phone"
                  label={i18n.phone}
                  placeholder={i18n.phoneNumber}
                  sx={errorSx}
                  fullWidth
                />
                <TextInput
                  disabled={!directorEditable || !isEditing}
                  value={
                    userRole == "director"
                      ? type == "create"
                        ? user?.full_name
                        : agency?.director?.full_name
                      : values?.director_full_name
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="director_full_name"
                  label={i18n.director}
                  placeholder={i18n.directorFullName}
                  sx={errorSx}
                  fullWidth
                />
              </div>
            </div>

            {/* Отображение ошибок */}
            {userRole == "director" &&
              dataByDirector &&
              dataByDirector.status_code !== 0 && (
              <div className="pt-8">
                {i18nErrors[getByErrorCode(dataByDirector.status_code)]}
              </div>
            )}
            {userRole == "admin" &&
              dataByAdmin &&
              dataByAdmin.status_code !== 0 && (
              <div className="pt-8">
                {i18nErrors[getByErrorCode(dataByAdmin.status_code)]}
              </div>
            )}
            {userRole == "admin" &&
              dataUpdateByAdmin &&
              dataUpdateByAdmin.status_code !== 0 && (
              <div className="pt-8">
                {i18nErrors[getByErrorCode(dataUpdateByAdmin.status_code)]}
              </div>
            )}

            {/* Кнопки */}
            <div className="flex flex-row gap-6">
              {type == "update" && (
                <Button
                  disableElevation
                  disabled={approveAgencyStatus.isLoading}
                  size="large"
                  variant="contained"
                  className="bg-primary text-white font-open-sans normal-case"
                  onClick={() => {
                    requiresConfirmation
                      ? agency && handleApproveAgency()
                      : setIsEditing(!isEditing);
                  }}
                >
                  {requiresConfirmation && i18n.agency.approve}
                  {!requiresConfirmation &&
                    (isEditing ? i18n.cancel : i18n.edit)}
                </Button>
              )}

              {(type == "create" ||
                (type == "update" && (isEditing || requiresConfirmation))) && (
                <Button
                  disableElevation
                  disabled={displayFormAccepted}
                  size="large"
                  //guard against accidental submit
                  type={
                    requiresConfirmation && type === "update"
                      ? "button"
                      : "submit"
                  }

                  // outlined "cancel" button if confirming,
                  // filled "send" / "save" button if creating
                  variant={type === "update" && requiresConfirmation ? "outlined" : "contained"}
                  className={`font-open-sans normal-case ${
                    type === "update" && requiresConfirmation
                      ? "text-primary border-primary"
                      : "bg-primary text-white"
                  }`}
                  onClick={() => {
                    requiresConfirmation && handleCancel();
                  }}
                >
                  {requiresConfirmation && i18n.cancel}
                  {!requiresConfirmation &&
                    (type == "create" ? i18n.send : i18n.save)}
                </Button>
              )}
            </div>
            {/* Отправка.... */}
            {(isLoadingByAdmin ||
              isLoadingByDirector ||
              isLoadingUpdateByAdmin) && (
              <div className="pt-8">{i18n.submitting}</div>
            )}
            {/* Отображение сообщения об успешной отправке 
            (при создании или обновлении админом, внизу формы) */}
            {userRole == "admin" &&
              dataByAdmin &&
              dataByAdmin.status_code === 0 && (
              <div className="pt-8">{i18n.createAgencyByAdminSuccess}</div>
            )}
            {userRole == "admin" &&
              dataUpdateByAdmin &&
              dataUpdateByAdmin.status_code === 0 && (
              <div className="pt-8">
                {directorChanged
                  ? i18n.updateAgencyDirectorByAdminSuccess
                  : i18n.updateAgencyByAdminSuccess}
              </div>
            )}
          </form>
        )}
      </Formik>

      {/* Отображение сообщения об успешной отправке (большое красное, при создании директором) */}
      {displayFormAccepted && <RegisterAgencyMessage />}
    </>
  );
};


