import { CommonLayout } from "shared/ui";
import { useLocale } from "entities/i18n";
import { useAgency } from "entities/admin";
import { useAuthSelector } from "entities/auth";
import { getByErrorCode } from "shared/lib";

const Home = () => {
  const i18n = useLocale();
  const { agencyId, agencyResponse } = useAgency();
  const { user } = useAuthSelector();

  return (
    <CommonLayout
      title={
        <h1 className="block my-0 leading-normal border border-orange-accent text-[2.25rem]">
          {i18n.welcome}
          {user?.full_name ?? "Guest"}!
        </h1>
      }
    >
      <span className="block pt-8 pb-2 font-bold">
        Данные вашего агентства: ({agencyId})
      </span>
      {agencyResponse &&
        (agencyResponse.status_code !== 0
          ? i18n.errors[getByErrorCode(agencyResponse.status_code)]
          : Object.entries(agencyResponse.result).map(
            ([key, value]) =>
              key != "director" && (
                <span key={key} className="block pb-2">
                  {key}: {value as string}
                </span>
              )
          ))}
    </CommonLayout>
  );
};

export default Home;
