import { ReactNode } from "react";
import { Link } from "react-router-dom";

const i18n = {
  logoAlt: "Логотип Danametrics",
};

/**
 * @param {string} startPage - Ссылка на главную страницу (для лого)
 * @param {ReactNode} children - (опционально) Виджеты для хедера (например, ProfileMenu)
 */
type HeaderProps = {
  startPage: string;
  children?: ReactNode;
};

const Header = ({ startPage, children }: HeaderProps) => {
  return (
    <header className="h-14 z-100 bg-white rounded-b-[10px] flex flex-row justify-between items-center px-[52px] font-inter">
      <Link to={startPage}>
        <img src="/logo.svg" alt={i18n.logoAlt} className="w-64" />
      </Link>
      <div className="flex flex-row justify-between items-center gap-5">
        {children}
      </div>
    </header>
  );
};

export default Header;
