import { useLocation, useParams, useNavigate } from "react-router-dom";
import { CommonLayout } from "shared/ui";
import { IconButton as MuiIconButton } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { UserProfile as UserProfileForm } from "features/admin";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

export const UserProfile = ({ userRole }: {userRole: "admin" | "director"}) => {
  const location = useLocation();
  const params = useParams();
  const agencyId = location.state ? location.state.agencyId
    : params.agencyId ?? params.id;
  const navigate = useNavigate();
  // const handleCancel = () => navigate("/clients", { replace: true, state: { clientIdByDirector } });
  const handleCancel = () => userRole === 'admin' ? navigate(-1) : navigate('/users');

  const i18n = useLocale().agencyUsers;

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18n.manage}
          </h1>
        </div>
      }
    >
      <UserProfileForm
        handleCancel={handleCancel}
        agencyId={Number(agencyId)}
        userRole={userRole}
      />
    </CommonLayout>
  );
};
