import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from 'yup'
import { Button } from "@mui/material";

import { useUpdateUserMutation } from "entities/users";
import { useAuthAction, useAuthSelector, useMeQuery } from "entities/auth";
import { useLocale } from "entities/i18n";
import {TextInput} from "shared/ui/form";

const errorSx = { "& .MuiFormHelperText-root": { color: "red" } };

export const ProfileForm = () => {

  const { user } = useAuthSelector();
  const navigate = useNavigate();
  const { auth: i18n, core: i18nCore, errors: i18nErrors } = useLocale();

  const [isEditing, setIsEditing] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    user && setName(user.full_name);
    user && setPhone(user.phone);
  }, [user]);

  const handleChangeName = (e: any) => setName(e.target.value);
  const handleChangePhone = (e: any) => setPhone(e.target.value);

  const [updateUser] = useUpdateUserMutation();

  const validationSchema = Yup.object({
    fullName: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
    phone: Yup.string()
      .required(i18nErrors.form.fieldRequired)
      .matches(/\+7[0-9]{10}/g, i18nErrors.form.phoneFormat),
    email: Yup.string()
      .email(i18nErrors.form.emailFormat)
      .required(i18nErrors.form.fieldRequired),
  });

  const {data: me, refetch, isUninitialized} = useMeQuery(null);
  useAuthAction().reloadMe(me?.result)

  return (
    <Formik
      initialValues={{ fullName: ".", phone: "+77777777777", email: "agency@agency.xxx" }}
      onSubmit={async () => {
        user &&
            await updateUser({
              phone,
              full_name: name,
              email: user.email,
              user_id: user.id,
            });
        !isUninitialized && await refetch();
        navigate(0);
      }}
      validationSchema={validationSchema}
    >
      {({
        errors,
        touched,
        handleSubmit,
        handleBlur,
        handleChange,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className="flex flex-col gap-8 mt-8  max-w-[35rem]"
        >
          <TextInput
            id="fullName"
            label={i18n.fullName}
            placeholder={i18n.fullNamePlaceholder}
            disabled={!isEditing}
            helperText={touched.fullName && errors.fullName ? errors.fullName : undefined}
            onBlur={handleBlur}
            onChange={e => {handleChangeName(e), handleChange(e)}}
            sx={errorSx}
            fullWidth
            value={name}
          />{" "}
          <TextInput
            id="phone"
            label={i18n.phoneNumber}
            placeholder="+7XXXXXXXXXX"
            disabled={!isEditing}
            helperText={touched.phone && errors.phone ? errors.phone : undefined}
            onBlur={handleBlur}
            onChange={e => {handleChangePhone(e), handleChange(e)}}
            sx={errorSx}
            fullWidth
            value={phone}
          />
          <TextInput
            id="email"
            label={i18n.email}
            placeholder="email@agency.xxx"
            helperText={touched.email && errors.email ? errors.email : undefined}
            onBlur={handleBlur}
            sx={errorSx}
            fullWidth
            disabled
            value={user?.email}
          />
          <div className="flex flex-row gap-4">
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white font-open-sans normal-case"
              onClick={() => {
                isEditing && handleSubmit();
                setIsEditing(!isEditing);
              }}
            >
              {isEditing ? i18nCore.save : i18nCore.edit}
            </Button>
            {isEditing && (
              <Button
                disableElevation
                size="large"
                variant="outlined"
                className="font-open-sans normal-case"
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              >
                {i18nCore.cancel}
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}
