import { useDispatch } from "react-redux";
import { AdminSlice } from "./adminSlice";
import { useMemo } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";

export const useAdminAction = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators({ ...AdminSlice.actions }, dispatch);
  }, [dispatch]);
};
