import MuiIconButton from "@mui/material/IconButton" 
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { CommonLayout } from "shared/ui";
import { CreateUser as CreateUserForm } from "features/admin";
import { useLocale } from "entities/i18n";
import { useParams } from "react-router-dom";
import { useAgency } from "entities/admin";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

export const CreateUser = ({ userRole }: {userRole: 'admin' | 'director'}) => {
  const navigate = useNavigate();
  const handleCancel = () => userRole === 'director' ? navigate('/users') : navigate(-1);
  const i18n = useLocale().agencyUsers;

  const { id } = useParams();
  const { agencyId } = useAgency();

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18n.add}
          </h1>
        </div>
      }
    >
      <CreateUserForm agencyId={Number(id ?? agencyId)} handleCancel={handleCancel}/>
    </CommonLayout>
  )
};