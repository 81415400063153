/* eslint-disable indent */

import * as Yup from "yup";
import { formatDate } from "shared/lib/dates";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import {
  RequestCreateMediaplan,
  RequestUpdateMediaplanRow,
  ResponseCreateMediaplan,
  ResponseUpdateMediaplanRow,
} from "entities/mediaplan";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react";

export const validationSchema = Yup.object({
  cabinet: Yup.string().required("Обязательное поле"),
  brand: Yup.string().required("Обязательное поле"),
  startDate: Yup.date().required("Обязательное поле"),
  finishDate: Yup.date().required("Обязательное поле"),
  campaignName: Yup.string().required("Обязательное поле"),
  status: Yup.string().required("Обязательное поле"),
  budget: Yup.number().required("Обязательное поле"),
  purchaseUnit: Yup.string().required("Обязательное поле"),
  impressions: Yup.number().nullable(),
  clicks: Yup.number().nullable(),
  views: Yup.number().nullable(),
  reach: Yup.number().nullable(),
  conversions: Yup.number().nullable(),
  installs: Yup.number().nullable(),
  cpm: Yup.number().nullable(),
  cpc: Yup.number().nullable(),
  cpv: Yup.number().nullable(),
  cpu: Yup.number().nullable(),
  cpa: Yup.number().nullable(),
  cpi: Yup.number().nullable(),
  ctr: Yup.number().nullable(),
  vtr: Yup.number().nullable(),
});

export const getInitialValues = (
  mode: "add" | "edit",
  campaign: MediaplanRow | null,
) =>
  mode === "add"
    ? {
      cabinet: "",
      brand: "",
      startDate: formatDate(new Date(), "yyyy-MM-dd"),
      finishDate: formatDate(new Date(), "yyyy-MM-dd"),
      campaignName: "",
      status: "",
      budget: null as null | number,
      purchaseUnit: "",
      impressions: null as null | number,
      clicks: null as null | number,
      views: null as null | number,
      reach: null as null | number,
      conversions: null as null | number,
      installs: null as null | number,
      cpm: null as null | number,
      cpc: null as null | number,
      cpv: null as null | number,
      cpu: null as null | number,
      cpa: null as null | number,
      cpi: null as null | number,
      ctr: null as null | number,
      vtr: null as null | number,
    }
    : {
      cabinet: campaign?.ad_cabinet,
      brand: campaign?.brand,
      startDate: campaign?.date_from,
      finishDate: campaign?.date_to,
      campaignName: campaign?.campaign,
      status: campaign?.status,
      budget: campaign?.budget,
      purchaseUnit: campaign?.ad_pricing,
      impressions: campaign?.impressions,
      clicks: campaign?.clicks,
      views: campaign?.views,
      reach: campaign?.reach,
      conversions: campaign?.conversions,
      installs: campaign?.installs,
      cpm: campaign?.cpm,
      cpc: campaign?.cpc,
      cpv: campaign?.cpv,
      cpu: campaign?.cpu,
      cpa: campaign?.cpa,
      cpi: campaign?.cpi,
      ctr: campaign?.ctr,
      vtr: campaign?.vtr,
    };

type CreateMediaplanFunction = MutationTrigger<
  MutationDefinition<
    RequestCreateMediaplan,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    never,
    ResponseCreateMediaplan,
    "MediaplanApi"
  >
>;

type UpdateMediaplanRowFunction = MutationTrigger<
  MutationDefinition<
    RequestUpdateMediaplanRow,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    never,
    ResponseUpdateMediaplanRow,
    "MediaplanApi"
  >
>;

export const handleSubmitCampaign = (
  mode: "add" | "edit",
  campaign: MediaplanRow | null,
  currency: Currency,
  createMediaplan: CreateMediaplanFunction,
  updateMediaplanRow: UpdateMediaplanRowFunction,
  mediaplanId: number | null,
  agencyId: number | null,
  clientId?: string,
  mediaplanName?: string
) =>
(values: ReturnType<typeof getInitialValues>) => {
  if (mode === "add") {
    createMediaplan(
      {
        client_id: clientId ? parseInt(clientId) : -1,
        agency_id: agencyId ?? -1,
        // unique timestamped name for mediaplan
        name: `${values.campaignName}-${Date.now().toString()}`,
        data: [{
          mediaplan: "0",
          campaign_id: "",
          currency: currency,
          ad_cabinet: values.cabinet!,
          brand: values.brand!,
          campaign: values.campaignName!,
          status: values.status!,
          date_from: values.startDate!,
          date_to: values.finishDate!,
          days: "",
          budget: values.budget!,
          ad_pricing: values.purchaseUnit!,
          impressions: values.impressions!,
          clicks: values.clicks!,
          views: values.views!,
          reach: values.reach!,
          conversions: values.conversions!,
          installs: values.installs!,
          frequency: null,
          cpm: values.cpm!,
          cpc: values.cpc!,
          cpv: values.cpv!,
          cpu: values.cpu!,
          cpa: values.cpa!,
          cpi: values.cpi!,
          ctr: values.ctr!,
          vtr: values.vtr!,
        }],
      },
    );
  } else {
    campaign && mediaplanId && updateMediaplanRow({
      client_id: clientId ? parseInt(clientId) : -1,
      agency_id: agencyId ?? -1,
      name: mediaplanName ?? Date.now().toString(),
      id: mediaplanId,
      data: {
        mediaplan: campaign.mediaplan.toString(),
        campaign_id: campaign.campaign_id,
        currency: currency,
        ad_cabinet: values.cabinet ?? campaign.ad_cabinet,
        brand: values.brand ?? campaign.brand,
        campaign: values.campaignName ?? campaign.campaign,
        status: values.status ?? campaign.status,
        date_from: values.startDate ?? campaign.date_from,
        date_to: values.finishDate ?? campaign.date_to,
        days: "",
        budget: values.budget ?? campaign.budget,
        ad_pricing: values.purchaseUnit ?? campaign.ad_pricing,
        impressions: values.impressions ?? campaign.impressions,
        clicks: values.clicks ?? campaign.clicks,
        views: values.views ?? campaign.views,
        reach: values.reach ?? campaign.reach,
        conversions: values.conversions ?? campaign.conversions,
        installs: values.installs ?? campaign.installs,
        frequency: campaign.frequency,
        cpm: values.cpm ?? campaign.cpm,
        cpc: values.cpc ?? campaign.cpc,
        cpv: values.cpv ?? campaign.cpv,
        cpu: values.cpu ?? campaign.cpu,
        cpa: values.cpa ?? campaign.cpa,
        cpi: values.cpi ?? campaign.cpi,
        ctr: values.ctr ?? campaign.ctr,
        vtr: values.vtr ?? campaign.vtr,
      },
    });
  }
};
