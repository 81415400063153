import { IconArrowLeft } from "@tabler/icons-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CommonLayout } from "shared/ui/layout";
import { Button, IconButton as MuiIconButton } from "@mui/material";
import { backIconProps } from "shared/constants";

import { MediaplanTable } from "widgets/mediaplan";
import {
  useCreateMediaplanMutation,
  useUpdateMediaplanMutation,
  useGetMediaplanQuery,
} from "entities/mediaplan";
import { useEffect, useMemo, useState } from "react";
import { useLocale } from "entities/i18n";

/**
 *
 * Mediaplan page component
 *
 * ! ==================================================
 * ! This component expects to receive a location.state
 * ! object with the following structure:
 * ! ==================================================
 *  {
 *   clientId: number,
 *   agencyId: number,
 *   mediaplan: {
 *      filename: string,
 *      data: MediaplanResponseSchema
 *   }
 *
 */

export const Mediaplan = ({ action }: { action: "create" | "edit" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dashboard: i18n, core: i18nCore } = useLocale();

  const [createMediaplan] = useCreateMediaplanMutation();
  const [updateMediaplan] = useUpdateMediaplanMutation();

  const locationState = location.state as {
    clientId: number;
    agencyId: number;
    mediaplan: {
      filename: string;
      data: MediaplanResponseSchema[];
    };
  };

  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const { data, isLoading } = useGetMediaplanQuery({ id }, { skip: !id });

  const {
    mediaplan: mediaplanImmutable,
    clientId,
    agencyId,
  } = useMemo(
    () =>
      locationState || {
        mediaplan: {
          filename: data?.result.name,
          data: data?.result.data,
        },
        clientId: data?.result.client_id,
        agencyId: data?.result.agency_id,
      },
    [data]
  );

  const [isEditing, setIsEditing] = useState<boolean>(action === "create");

  const mediaplanMutable = useMemo(
    () => ({
      filename: mediaplanImmutable.filename,
      data: [...mediaplanImmutable.data].map((row) =>
        JSON.parse(JSON.stringify(row))
      ),
    }),
    [mediaplanImmutable]
  );

  const [mediaplanData, setMediaplanData] = useState<MediaplanResponseSchema[]>(
    mediaplanMutable.data
  );

  useEffect(() => {
    console.log(mediaplanMutable.data);
    setMediaplanData(mediaplanMutable.data);
  }, [mediaplanMutable]);

  const handleEditMetric = (
    rowIndex: number,
    metricName: MediaplanEditableMetric,
    newValue: number
  ) => {
    const newMediaplanData = [...mediaplanData] as MediaplanResponseSchema[];
    newMediaplanData[rowIndex][metricName] = newValue;
    setMediaplanData(newMediaplanData);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    createMediaplan({
      agency_id: agencyId,
      client_id: clientId,
      name: mediaplanMutable.filename,
      data: mediaplanData,
    })
      .unwrap()
      .then((res) => {
        alert(
          "Медиаплан успешно загружен! ID медиаплана в базе: " +
            res.result.id +
            ". Имя медиаплана в базе: " +
            res.result.name
        );
        setTimeout(handleBack, 1000)
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setMediaplanData(
      [...mediaplanImmutable.data].map((row) => JSON.parse(JSON.stringify(row)))
    );
    if (action === "create") {
      navigate(-1);
    }
  };

  const handleAction = () => {
    if (action === "create" && isEditing) handleSave();
    // if (action === "edit") {
    //   if (isEditing) {
    //     updateMediaplan({
    //       id,
    //       agency_id: agencyId,
    //       client_id: clientId,
    //       name: mediaplanMutable.filename,
    //       data: mediaplanData,
    //     })
    //       .unwrap()
    //       .then((res) => {
    //         alert(
    //           "Медиаплан успешно обновлен! ID медиаплана в базе: " +
    //             res.result.id +
    //             ". Имя медиаплана в базе: " +
    //             res.result.name
    //         );
    //       })
    //       .catch((err) => {
    //         throw new Error(err);
    //       });
    //   } else setIsEditing(!isEditing);
    // }
  };

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton aria-label="go back" edge="start" onClick={handleBack}>
            <IconArrowLeft {...backIconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {action === "create" && i18n.uploadMediaplan}
            {action === "edit" && !isEditing && i18n.viewMediaplan}
            {action === "edit" && isEditing && i18n.editMediaplan}
          </h1>
        </div>
      }
    >
      <div className="flex flex-col gap-4 pb-8 ">
        <h2 className="block text-2xl font-bold text-start mb-2 ml-8">
          {mediaplanImmutable.data ? mediaplanImmutable.data[0].mediaplan : ""}
        </h2>
        <div className="overflow-x-auto">
          {mediaplanImmutable.data && (
            <MediaplanTable
              mediaplan={mediaplanImmutable}
              onEditMetric={handleEditMetric}
              editable={isEditing}
            />
          )}
        </div>
        <div className="flex flex-row justify-start gap-4 h-10">
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="large"
            className="bg-primary text-white normal-case h-10"
            onClick={handleAction}
          >
            {isEditing ? i18nCore.save : i18nCore.edit}
          </Button>
          {(action === "create" || (action === "edit" && isEditing)) && (
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              size="large"
              className="text-primary normal-case h-10"
              onClick={handleCancel}
            >
              {i18nCore.cancel}
            </Button>
          )}
        </div>
      </div>
    </CommonLayout>
  );
};
