import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {CabinetBadge} from "shared/ui/icons";
import { ADS_SYSTEMS_READABLE, adsSystems } from "shared/constants";

export const AdsSystemSelect = ({
  value,
  onChange,
  options,
}: {
    value: adsSystems;
    onChange: (value: adsSystems) => void;
    options: adsSystems[];
  }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  
  const handleInput = (e: SelectChangeEvent<adsSystems>) => {
    setInputValue(e.target.value as adsSystems);
  };
  
  const handleBlur = () => {
    setEditing(false);
    onChange(inputValue);
  };
  
  useEffect(() => {
    onChange(inputValue);
  }, [inputValue, onChange]);
  
  const handleKeyDown: React.KeyboardEventHandler = (
    e: React.KeyboardEvent<HTMLSelectElement>
  ) => {
    if (e.key === "Enter" || e.key === "Escape") {
      handleBlur();
    }
  };
  
  const handleFocus = () => {
    setEditing(true);
  };
  
  return (
    <div className="flex flex-row flex-auto justify-end h-[1.5rem]">
      {editing ? (
        <Select
          autoFocus
          className="w-full bg-grey-100 border border-grey-100 border-solid outline-none shadow-none"
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          open
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                setInputValue(option);
                handleBlur();
              }}
            >
              {ADS_SYSTEMS_READABLE[option]}
            </MenuItem>
          ))}
        </Select>
      ) : (
      //eslint-disable-next-line
          <span
          className="w-full leading-[1.5rem]"
          role="cell"
          onFocus={handleFocus}
          onClick={handleFocus}
        >
          <CabinetBadge
            title={ADS_SYSTEMS_READABLE[inputValue]}
            color={"#FFA900"}
            icon={inputValue}
          />
        </span>
      )}
    </div>
  );
};