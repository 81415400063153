import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useLocale } from "entities/i18n";
import {
  useCreateAgencyUserFromExistingMutation,
  useCreateAgencyUserFromScratchMutation,
  useLazyReadUserByEmailQuery,
} from "entities/users";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { User } from "shared/types";
import { getByErrorCode } from "shared/lib";
import { TextInput } from "shared/ui/form";
import * as Yup from "yup";

type Props = {
  handleCancel: () => void;
  agencyId: number;
};

export const CreateUser = (props: Props) => {
  const { errors: i18nErrors, core: i18nCore, agencyUsers: i18n } = useLocale();

  const [createAgencyUserFromExisting, { data, isLoading, isError }] =
    useCreateAgencyUserFromExistingMutation();
  const [
    createAgencyUserFromScratch,
    {
      data: dataFromScratch,
      isLoading: isLoadingFromScratch,
      isError: isErrorFromScratch,
    },
  ] = useCreateAgencyUserFromScratchMutation();

  const [
    readUserByEmail,
    { data: userData, isLoading: isLoadingUser, isError: isErrorUser },
  ] = useLazyReadUserByEmailQuery();

  const initialValues: {
    name: string;
    email: string;
    phone: string;
    role: string;
    submit: null;
  } = {
    name: "",
    email: "",
    phone: "",
    role: "MANAGER",
    submit: null,
  };

  // is used for MUI Select
  const [role, setRole] = useState("MANAGER");

  // is used for fetching user data. has to be debounced
  const [email, setEmail] = useState("");
  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout>();
  const [userByEmail, setUserByEmail] = useState<User | null>(null);

  useEffect(() => {
    apiCallTimeout && clearTimeout(apiCallTimeout);
    setApiCallTimeout(
      setTimeout(() => {
        readUserByEmail({ email })
          .unwrap()
          .then((data) => {
            data.status_code === 0
              ? setUserByEmail(data.result)
              : setUserByEmail(null);
          });
      }, 500)
    );
  }, [email]);

  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const validationSchemaFromScratch = Yup.object().shape({
    name: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
    email: Yup.string()
      .email(i18nErrors.form.emailFormat)
      .required(i18nErrors.form.fieldRequired)
      .max(255),
    phone: Yup.string()
      .required(i18nErrors.form.fieldRequired)
      .matches(/\+7[0-9]{10}/g, i18nErrors.form.phoneFormat)
      .max(12, i18nErrors.form.phoneFormat),
    role: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
  });

  const validationSchemaFromExisting = Yup.object().shape({
    email: Yup.string()
      .email(i18nErrors.form.emailFormat)
      .required(i18nErrors.form.fieldRequired)
      .max(255),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          readUserByEmail({ email: values.email })
            .unwrap()
            .then(
              async (
                data: Omit<ResponseCommon, "result"> & {
                  result: User;
                }
              ) => {
                if (data?.status_code === 0) {
                  data.result.roles.filter((role) =>
                    ["MANAGER", "CLIENT"].includes(role.code)
                  ).length > 0
                    ? await createAgencyUserFromExisting({
                      agency_id: props.agencyId,
                      user_id: data.result.id,
                    })
                    : setErrors({
                      role: i18n.errors.wrongRole
                    });
                } else {
                  await createAgencyUserFromScratch({
                    agency_id: props.agencyId,
                    user: {
                      full_name: values.name,
                      email: values.email,
                      phone: values.phone,
                      role: role as "MANAGER" | "CLIENT",
                    },
                  });
                }
              }
            );
          if (scriptedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
      validationSchema={userByEmail ? validationSchemaFromExisting : validationSchemaFromScratch}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-col gap-8">
            <div className="w-1/2">
              <TextInput
                fullWidth
                disabled={userByEmail !== null}
                id="name"
                label={i18n.fullNameTitle}
                placeholder={i18n.fullNamePlaceHolder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={userByEmail ? userByEmail.full_name : values.name}
              />
              {touched.name && errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="email"
                label={i18n.emailTitle}
                placeholder={i18n.emailPlaceHolder}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email && (
                <FormHelperText error>{errors.email}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              <TextInput
                fullWidth
                disabled={userByEmail !== null}
                id="phone"
                label={i18n.phoneTitle}
                placeholder="+7XXXXXXXXXX"
                onChange={handleChange}
                onBlur={handleBlur}
                value={userByEmail ? userByEmail.phone : values.phone}
              />
              {touched.phone && errors.phone && (
                <FormHelperText error>{errors.phone}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              {/*               <TextInput
                fullWidth
                id="role"
                label={i18n.roles.title}
                placeholder={i18n.roles.manager}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
              /> */}
              <div className="font-open-sans">
                <InputLabel
                  shrink
                  htmlFor="role"
                  className="text-lg font-bold text-black"
                >
                  {i18n.roles.title}
                </InputLabel>
                <Select
                  fullWidth
                  disabled={userByEmail !== null}
                  id="role"
                  name="role"
                  label={i18n.roles.title}
                  onChange={(e: SelectChangeEvent) => {
                    setRole(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={userByEmail ? userByEmail.roles[0].code : role}
                  variant="filled"
                  size="small"
                  sx={{
                    background: "white !important",
                    border: "none",
                    borderRadius: "0.25rem",
                    padding: 0,
                    "& .MuiInputBase-input": {
                      paddingTop: "0.75rem",
                      paddingBottom: "0.75rem"
                    },
                    "&:after, &:before": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem value="MANAGER">{i18n.roles.manager}</MenuItem>
                  <MenuItem value="CLIENT">{i18n.roles.client}</MenuItem>
                </Select>
              </div>
              {errors.role && (
                <FormHelperText error>{errors.role}</FormHelperText>
              )}
              {isSubmitting && (
                <FormHelperText>{i18n.submitting}</FormHelperText>
              )}
            </div>
            <Stack direction="row" spacing="2rem" width="20rem">
              <Button
                disableElevation
                type="submit"
                disabled={isLoading || isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
              >
                {i18nCore.add}
              </Button>

              <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                variant="outlined"
                className="bg-primary text-white normal-case"
                onClick={props.handleCancel}
              >
                {i18nCore.cancel}
              </Button>
            </Stack>
            <span>
              {data &&
                data.status_code !== 0 &&
                i18nErrors[getByErrorCode(data.status_code)]}
              {dataFromScratch &&
                dataFromScratch.status_code !== 0 &&
                i18nErrors[getByErrorCode(dataFromScratch.status_code)]}
              {data &&
                data.status_code === 0 &&
                i18n.createSuccess.fromExisting}
              {dataFromScratch &&
                dataFromScratch.status_code === 0 &&
                i18n.createSuccess.fromScratch}
              {(data &&
                data.status_code === 2002) && i18n.errors.duplicateUser}
              {(dataFromScratch &&
                dataFromScratch.status_code === 2002) &&
                i18n.errors.duplicateUser2}
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};
