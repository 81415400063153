import { Button, Fade, Menu, MenuItem } from "@mui/material";
import { IconUserCircle, IconChevronDown, Icon } from "@tabler/icons-react";
import { useAuthSelector } from "entities/auth";
import { useRef, useState, createElement } from "react";
import { Auth } from "shared/types";

export type ProfileMenuItem = {
  label: string;
  icon: Icon;
  action: string;
};

export type ProfileMenuProps = {
  items: ProfileMenuItem[];
  title: string;
  onAction: (action: string, auth: Auth) => void;
};

export const ProfileMenu = ({ items, title, onAction: handleAction }: ProfileMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef(null);

  const auth = useAuthSelector();

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className="flex flex-row items-center gap-[10px] z-[1301] relative">
        <IconUserCircle size={24} strokeWidth={2} />
        <span className="font-bold">{title}</span>
        <Button
          id="profile-menu-button"
          disableRipple
          disableTouchRipple
          aria-controls={menuOpen ? "profile-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={() => setMenuOpen(!menuOpen)}
          className="p-0 w-6 min-w-0 h-6"
          sx={{
            "& svg": {
              transition: "transform 0.1s ease-in-out",
            },
          }}
        >
          {/* <img src="icons/arrow_open.svg" alt={i18n.openMenuAlt}/> */}
          <IconChevronDown
            size={24}
            strokeWidth={2}
            color="#000"
            className={menuOpen ? "rotate-180" : "rotate-0"}
          />
        </Button>
        {/* К этому элементу привязывается MUI меню. Нужен для позиционирования */}
        <div ref={anchorEl} className="absolute left-[-0.75rem]" />
      </div>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        MenuListProps={{
          "aria-labelledby": "profile-menu-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiMenu-paper[style]": {
            top: "0.3rem !important",
            width: "15.25rem",
            pt: "2rem",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
          },
          "& .MuiMenuItem-root": {
            lineHeight: "1.25rem",
            color: "#000",
            fontWeight: 700,
            fontFamily: "Inter",
            pl: "0.75rem",
            py: "0.5rem",
            "&:hover": {
              backgroundColor: "#F5F5F5",
            },
            "& svg": {
              marginRight: "calc(0.66rem)",
            },
          },
        }}
        TransitionComponent={Fade}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={() => { handleClose(); handleAction(item.action, auth) }}>
            {createElement(item.icon, { size: 24, strokeWidth: 2 })}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileMenu;
