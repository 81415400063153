import { IconSearch } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { TextInput } from "shared/ui/form";
import { PaginatedComplexList } from "shared/ui/list";
import { useTheme } from "@mui/material/styles";
import {
  useGetAgenciesQuery,
  useApproveAgencyMutation,
  useDeleteAgencyMutation,
  useUpdateAgencyStatusMutation,
} from "entities/admin";
import { useLocale } from "entities/i18n";
import { useNavigate } from "react-router-dom";
import { Dialog } from "shared/ui/components";
import { Button } from "@mui/material";
import { compileListData } from "./agencyListData";
import { getByErrorCode } from "shared/lib";

export const AgencyList = () => {
  const { palette } = useTheme();
  const [nameFilter, setNameFilter] = useState<string>();
  const [binFilter, setBinFilter] = useState<string>();
  const [page, setPage] = useState(1);

  const { data, isLoading, isError, refetch, isUninitialized } =
    useGetAgenciesQuery(
      { name: nameFilter, bin: binFilter, page, size: 10 },
      { refetchOnMountOrArgChange: true }
    );

  const [approveAgency, approveAgencyStatus] = useApproveAgencyMutation();
  const [deleteAgency] = useDeleteAgencyMutation();
  const [updateAgencyStatus] = useUpdateAgencyStatusMutation();
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.admin;
  const i18nErrors = i18nGlobal.errors;
  const navigate = useNavigate();
  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout>();

  const [dialogVisible, setDialogVisible] = useState(false);
  const dialogToggler = () => setDialogVisible(!dialogVisible);
  const [selectedAction, setSelectedAction] = useState<DialogAction>("delete");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const dialogActionHandler = (action: DialogAction, index: number) => () => {
    setSelectedAction(action);
    setSelectedIndex(index);
    setDialogVisible(true);
  };

  const handleMutation = () => {
    data &&
      selectedAction == "delete" &&
      deleteAgency({ agency_id: data?.result.items[selectedIndex].id });
    data &&
      selectedAction == "deactivate" &&
      updateAgencyStatus({
        agency_id: data?.result.items[selectedIndex].id,
        is_active: !data?.result.items[selectedIndex].is_active,
      });
    apiCallTimeout && clearTimeout(apiCallTimeout);
    setApiCallTimeout(
      setTimeout(async () => {
        isUninitialized || refetch();
      }, 2000)
    );
  };

  const listProps = useMemo(
    () =>
      data?.result.items &&
      compileListData(
        data.result.items,
        i18n,
        palette,
        navigate,
        dialogActionHandler,
        (id: number) => {
          approveAgency({ agency_id: id });
          apiCallTimeout && clearTimeout(apiCallTimeout);
          setApiCallTimeout(
            setTimeout(async () => {
              isUninitialized || refetch();
            }, 2000)
          );
        }
      ),
    [data, i18n]
  );

  return (
    <>
      <div className="flex flex-row justify-start gap-[2rem]">
        <TextInput
          id="search"
          placeholder={i18n.organizationName}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3 w-[13rem]"
          onChange={(e) => {
            setNameFilter(e.target.value);
            apiCallTimeout && clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                isUninitialized || (await refetch());
              }, 1500)
            );
          }}
        />
        <TextInput
          id="search"
          placeholder={i18n.BIN}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3 w-[12rem]"
          onChange={(e) => {
            setBinFilter(e.target.value);
            apiCallTimeout && clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                isUninitialized || (await refetch());
              }, 1500)
            );
          }}
        />
      </div>
      {isLoading && <div>{i18n.loading}</div>}
      {isError && <div>{i18nErrors.server}</div>}
      {data && data?.status_code !== 0 && (
        <div>{i18nErrors[getByErrorCode(data.status_code)]}</div>
      )}
      {approveAgencyStatus.data?.status_code !== 0 &&
        approveAgencyStatus.data && (
        <div>
          {i18nErrors[getByErrorCode(approveAgencyStatus.data.status_code)]}
        </div>
      )}
      {data?.result && listProps && (
        <PaginatedComplexList.server
          data={listProps}
          pages={data.result.pages}
          rowsPerPage={10}
          totalRows={data.result.total}
          handlePageChange={(page) => setPage(page)}
        />
      )}
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {selectedAction == "deactivate" &&
                (data?.result.items[selectedIndex].confirmed
                  ? i18n.agency.deactivation
                  : i18n.agency.activation)}
              {selectedAction == "delete" && i18n.agency.deletion}
            </div>
            <div className="font-bold">
              {listProps && listProps.rows[selectedIndex].name.content}
            </div>
            <div>
              <span className="font-bold">{i18n.BIN}: </span>
              {listProps ? listProps.rows[selectedIndex].bin.content : ""}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={handleMutation}
              >
                {selectedAction === "deactivate" &&
                  (data?.result.items[selectedIndex].is_active
                    ? i18n.agency.deactivate
                    : i18n.agency.activate)}
                {selectedAction === "delete" && i18n.agency.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18n.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
