import { baseApi } from "shared/baseApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Auth, Token, User } from "shared/types";
import { deleteMemory, getMemory } from "shared/lib";
import AuthApi from "./authApi";

export const logoutWithInvalidation = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(baseApi.util.resetApiState());
    const pageSholdBeReloaded = getMemory("serviseAuth") ? true : false;
    deleteMemory("serviseAuth");
    if (pageSholdBeReloaded) location.reload();
  },
);

const initialAuth: Auth = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  accessToken: "",
};

const initialState = { ...initialAuth, activationToken: "" };

type AuthState = typeof initialState;

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    init: (state: AuthState) => {
      state.isInitialized = true;
      const json = getMemory("serviseAuth");
      if (json) {
        const auth: Token = JSON.parse(json);
        state.isLoggedIn = true;
        state.user = auth.user;
        state.accessToken = auth.access;
      }
      return state;
    },
    loggein: (state: AuthState, action: PayloadAction<Token>) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.access;
    },
    addActivationToken: (state: AuthState, action: PayloadAction<string>) => {
      if (state.activationToken === "") state.activationToken = action.payload;
    },
    deleteActivationToken: (state: AuthState) => {
      state.activationToken = "";
    },
    // logout: (state: AuthState) => {
    //   deleteMemory("serviseAuth");
    //   state.isLoggedIn = false;
    //   state.user = null;
    //   state.accessToken = "";
    // },
    reloadMe: (state: AuthState, action: PayloadAction<User | undefined>) => {
      state.user = action.payload ? action.payload : state.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutWithInvalidation.fulfilled, (state) => {
      deleteMemory("serviseAuth");
      state.isLoggedIn = false;
      state.user = null;
      state.accessToken = "";
    });

    builder.addMatcher(
      AuthApi.endpoints.accessToken.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.result.access;
      },
    );
    builder.addMatcher(
      AuthApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
        if (payload.result.access) {
          state.accessToken = payload.result.access;
        } else {
          state.isLoggedIn = false;
          state.user = null;
          state.accessToken = "";
          deleteMemory("serviseAuth");
        }
      },
    );
  },
});

export default AuthSlice;
