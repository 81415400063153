import { IconEdit, IconTrash } from "@tabler/icons-react";
import { adsSystems } from "shared/constants";

export const iconProps = {
  size: 20,
  strokeWidth: 1.5,
  color: "black",
};

export const actionIconButtons = (
  handleEdit: (apiType: adsSystems) => void,
  handleDelete: (apiType: adsSystems) => void,
  apiType: adsSystems
) => [
  {
    icon: IconEdit,
    onClick: () => handleEdit(apiType),
    // tooltip: i18n.agency.clients
  },
  {
    icon: IconTrash,
    onClick: () => handleDelete(apiType),
    // tooltip: i18n.agency.clients
  },
];
