import { AgencyProfile, NewBadge } from "widgets/admin";
import { CommonLayout } from "shared/ui/layout";
import { useLocale } from "entities/i18n";
import { useAuthSelector } from "entities/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { Button, IconButton } from "@mui/material";
import { backIconProps } from "shared/constants";
import { useMemo, useState } from "react";
const allowedRoles = ["ROOT", "SYSTEM_ADMIN"];

const TABS = ["info", "clients", "users", "api"];
const activeTabIndex = (activeTab: string) =>
  TABS.findIndex((tab) => tab === activeTab);

/**
 * This component expects location.state to be an instance of Agency
 */
const AgencyPage = ({
  activeTab,
}: {
  activeTab?: "info" | "clients" | "users" | "api";
}) => {
  const { admin, errors } = useLocale();
  const allowedIn = useAuthSelector().user?.roles.some((role) =>
    allowedRoles.includes(role.code)
  );
  const location = useLocation();
  const paramsId = useParams().id;
  const agencyId = useMemo(
    () => (location.state ? location.state.id : paramsId),
    [location.state, paramsId]
  );

  const [currentTab, setCurrentTab] = useState(activeTabIndex(activeTab || "info"));

  const navigate = useNavigate();
  const handleCancel = () => navigate("/admin/agencies");
  const handleAddClient = () =>
    navigate(`/admin/agencies/${agencyId}/clients/create`);
  const handleAddUsers = () =>
    navigate(`/admin/agencies/${agencyId}/users/create`);

  const handleAddButton = (tabName: string) => {
    switch (tabName) {
      case "clients":
        return handleAddClient;
      case "users":
        return handleAddUsers;
    }
  };

  return (
    <>
      <CommonLayout
        title={
          <div className="inline-flex flex-row items-center">
            <IconButton
              aria-label="go back"
              edge="start"
              onClick={handleCancel}
            >
              <IconArrowLeft {...backIconProps} />
            </IconButton>
            <h1 className="mr-3">
              {location.state ? location.state.name : "Данные об агентстве"}
            </h1>
            {location.state && !location.state.confirmed && <NewBadge />}
          </div>
        }
        btns={
          ["clients", "users"].includes(
            TABS[currentTab]
          ) && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="bg-primary text-white normal-case"
              onClick={handleAddButton(
                TABS[currentTab]
              )}
            >
              {admin.add}
            </Button>
          )
        }
        className="bg-grey-200 py-8"
      >
        {allowedIn ? (
          <AgencyProfile
            activeTab={activeTab || "info"}
            agencyId={agencyId}
            locationState={location.state}
            handlers={{
              changeTab: setCurrentTab
            }}
          />
        ) : (
          <div className="pt-8">{errors.notEnoughPrivileges}</div>
        )}
      </CommonLayout>
    </>
  );
};

export default AgencyPage;