import { useEffect, useState } from "react";
import { IconArrowLeft } from "@tabler/icons-react";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  CampDashboardTable,
  CampDashboardFilters,
  CampFiltersState,
  initialCampFiltersState,
} from "widgets/dashboard";
import { useAgency } from "entities/admin";
import { usePlanFactAdsQuery } from "entities/mediaplan";
import { CommonLayout } from "shared/ui/layout";
import { formatDate } from "shared/lib/dates";
import { shortenText } from "shared/lib";

const CampDashboard = ({ userRole }: { userRole: "admin" | "director" }) => {
  const location = useLocation();

  const campName = location.state?.campaign?.name ?? "";
  const params = useParams();
  const clientId = params["client"];
  const campId = params["campaign"] || "";

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const [filters, setFilters] = useState<CampFiltersState>(initialCampFiltersState);
  const agency = useAgency();
  const [pageNumber, setPageNumber] = useState(1);

  //TODO: Change this req to get data for Ad Groups and Ads
  const planFactRes = usePlanFactAdsQuery(
    {
      agency_id:
        userRole === "admin" ? Number(params["agency"]) : agency.agencyId || -1,
      client_id: Number(clientId),
      insight_campaign_id: campId,
      size: 20,
      page: pageNumber,
      ad_id: filters.ad || undefined,
      ad_group_name: filters.adGroup || undefined,
      filter_start_date: filters.startDate ? formatDate(filters.startDate, "yyyy-MM-dd") : undefined,
      filter_end_date: filters.endDate ? formatDate(filters.endDate, "yyyy-MM-dd") : undefined,
    },
    { skip: userRole !== "admin" && !agency.agencyId },
  );

  //! Новый эндпоинт (/plan-fact)
  useEffect(() => {
    if (planFactRes.isUninitialized) return;
    // refetch();
  }, [clientId, agency.agencyId]);

  return (
    <>
      <CommonLayout
        className="bg-grey-100"
        title={
          <div className="flex items-center">
            <IconButton className="mr-2" onClick={handleBack}>
              <IconArrowLeft size={18} className="inline" />
            </IconButton>
            <h1 title={campName} className="leading-0 m-0 p-0 inline">
              {shortenText(campName, 55)}
            </h1>
          </div>
        }
      >
        <div>
          <CampDashboardFilters userRole={userRole} onChange={setFilters} />
          {planFactRes.data && (
            <>
              <CampDashboardTable
                res={{
                  data: planFactRes.data,
                  isLoading: planFactRes.isLoading,
                  error: planFactRes.error,
                }}
                fetchDataPage={(page) => {
                  setPageNumber(page + 1);
                }}
                className="flex flex-col flex-auto"
                userRole={userRole}
                clientId={clientId || ""}
                agencyId={userRole === "admin" ? (params["agency"] || "") : (agency.agencyId?.toString() || "")}
              />
            </>
          )}
        </div>
      </CommonLayout>
    </>
  );
};

export default CampDashboard;
